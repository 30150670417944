import { SpacerProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { themeSelector, verticalSpacing } from "@schemas/presets";

const schema: Schema.Module<SpacerProps> = {
	schemaType: "module",
	component: "Spacer",
	category: "spacers",
	displayName: "Spacer",

	configTabs: [
		{
			title: "config",
			fields: [
				{ ...themeSelector },
				{
					type: "VisualUniqueSelection",
					title: "Decoration",
					key: "decoration",
					columns: 2,
					mandatory: true,
					options: [
						{
							img: "/thumbnails/modules/Spacer/decoration/none@2x.png",
							value: "none",
						},
						{
							img: "/thumbnails/modules/Spacer/decoration/line@2x.png",
							value: "line",
						},
					],
				},

				{ ...verticalSpacing, title: "Size", key: "size" },
			],
		},
	],

	default: {
		component: "Spacer",
		subtheme: "default",
		size: "medium",
		decoration: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Spacer/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Spacer/thumbnail@2x.png",
	},
};

export default schema;
