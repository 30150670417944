import { UIFields } from "@griddo/core";

export const additional: UIFields.TextField = {
	key: "additional",
	title: "Additional text",
	type: "TextField",
	placeholder: "Type an additional text",
	hideable: true,
	isMockup: true,
	humanReadable: true,
};
