import { BasicTextProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	getLinkModal,
	heading,
	linkModal,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<BasicTextProps> = {
	schemaType: "module",
	component: "BasicText",
	category: "content",
	displayName: "Basic Text",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Show decoration",
					type: "RadioGroup",
					key: "decoration",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					helptext:
						"This behavior will only affect the decoration line of Layout 01",
					mandatory: true,
				},
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{ ...linkModal, title: "Main link", hideable: true },
				{ ...linkModal, title: "Secondary link", key: "link2", hideable: true },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/BasicText/Layouts/layout001.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/BasicText/Layouts/layout002.png",
						},
						{
							value: "layout03",
							img: "/thumbnails/modules/BasicText/Layouts/layout003.png",
						},
						{
							value: "layout04",
							img: "/thumbnails/modules/BasicText/Layouts/layout004.png",
						},
					],
				},
				{
					...themeSelector,
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "BasicText",
		decoration: true,
		additional: "Lorem ipsum",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		link: getLinkModal("Saber más"),
		link2: {
			component: "LinkModal",
		},
		anchorID: null,
		layout: "layout02",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/modules/BasicText/onexed@1x.png",
			"2x": "/thumbnails/modules/BasicText/onexed@2x.png",
		},
		"1x": "/thumbnails/modules/BasicText/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicText/thumbnail@2x.png",
	},
};

export default schema;
