import { Schema } from "@griddo/core";

export const PEOPLE_TAG: Schema.CategoryContentType = {
	dataPacks: ["LECTURERS"],
	title: "Tag (People)",
	local: false,
	translate: true,
	taxonomy: true,
	fromPage: false,
};
