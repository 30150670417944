import { Theme } from "@griddo/core";

import { themes } from "../schemas/config/themes";
import cihs from "./cihs-theme";
import euef from "./euef-theme";
import global from "./global-theme";
import icade from "./icade-theme";
import icai from "./icai-theme";
import main from "./main-theme";
import onexed from "./onexed-theme";

export const subthemes = [
	"default",
	"default-alt",
	"inverse",
	"inverse-alt",
	"accent",
	"accent-alt",
] as const;
export type Subthemes = typeof subthemes[number];
export type Themes = typeof themes[number]["value"];

const griddoTheme: Theme.GriddoTheme = {
	// ---------------------------------------------------------------------------
	// Global theme definition is designed to be used generically across all the
	// sites and the whole instance design system.
	// ---------------------------------------------------------------------------
	global: global,

	// ---------------------------------------------------------------------------
	// Themes are designed to be used by one or many instance sites. Themes also
	// are bundle with subthemes.
	// ---------------------------------------------------------------------------
	themes: [main, euef, icade, icai, cihs, onexed],
};

export default griddoTheme;
