import { PrinciplesCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { content, heading, loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Component<PrinciplesCardProps> = {
	schemaType: "component",
	component: "PrinciplesCard",
	displayName: "Principles Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					title: "Card title",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					...heading,
					key: "detailTitle",
					hideable: false,
				},
				{ ...content, title: "Description", mandatory: true, hideable: false },
				{
					type: "UniqueCheck",
					title: "Extract",
					key: "showExtract",
					options: [
						{
							title: "Show an extract of the description in the card?",
						},
					],
				},
				{
					type: "ComponentContainer",
					title: "Media",
					key: "media",
					whiteList: ["ImageLite", "Video"],
					helptext: "Recommended minimum image size: 467x350",
					hideable: true,
				},
				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Link"],
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "PrinciplesCard",
		title: "Lorem ipsum",
		detailTitle: { content: "Lorem ipsum", tag: "h4" },
		content: loremIpsumParagraph,
		showExtract: false,
		media: {
			imageLite: { component: "ImageLite" },
			video: { component: "Video" },
		},
		link: { component: "Link" },
	},
};

export default schema;
