import { UIFields } from "@griddo/core";

export const detail: UIFields.Wysiwyg = {
	type: "Wysiwyg",
	full: false,
	title: "Detail",
	key: "detail",
	placeholder: "Type a detail",
	hideable: true,
	isMockup: true,
	humanReadable: true,
};
