import { Schema } from "@griddo/core";

export const LIVE_STREAM: Schema.PageContentType = {
	dataPacks: ["VIDEOS"],
	title: "Live stream",
	local: true,
	fromPage: true,
	translate: false,
	taxonomy: false,
	schema: {
		templates: ["LiveStream"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
			},
			{
				type: "Wysiwyg",
				key: "description",
				title: "Description",
				from: "description",
			},
			{
				type: "TextField",
				title: "Video ID",
				from: "videoId",
				key: "videoId",
			},
			{
				type: "TextField",
				title: "Video URL",
				from: "videoUrl",
				key: "videoUrl",
			},
			{
				type: "TextField",
				title: "Kaltura image",
				key: "kalturaImage",
				from: "kalturaImage",
			},
			{
				title: "Image",
				type: "ImageField",
				key: "image",
				from: "image",
			},
			{
				type: "TextField",
				title: "Search text",
				from: "searchText",
				key: "searchText",
			},
			{
				type: "DateField",
				title: "Date",
				from: "videoDate",
				key: "videoDate",
				indexable: true,
			},
			{
				type: "DateField",
				title: "Date (update)",
				from: "videoUpdateDate",
				key: "videoUpdateDate",
				indexable: true,
			},
			{
				type: "TextField",
				title: "Creator",
				from: "creator",
				key: "creator",
			},
			{
				type: "NumberField",
				title: "Duration",
				from: "duration",
				key: "duration",
			},
			{
				type: "NumberField",
				title: "Plays",
				from: "plays",
				key: "plays",
			},
			{
				type: "TextField",
				title: "Tags",
				from: "tags",
				key: "tags",
			},
			{
				type: "MultiCheckSelect",
				title: "Categories",
				placeholder: "Categories",
				from: "categories.videoCategories",
				key: "videoCategories",
				source: "CATEGORY_VIDEO",
			},
			{
				type: "MultiCheckSelect",
				title: "Tags",
				placeholder: "Tags",
				from: "categories.videoTags",
				key: "videoTags",
				source: "VIDEO_TAG",
			},
			{
				type: "MultiCheckSelect",
				title: "Channel",
				placeholder: "Channel",
				from: "categories.videoChannel",
				key: "videoChannel",
				source: "CHANNEL",
			},
			{
				type: "MultiCheckSelect",
				title: "Playlist",
				placeholder: "Playlist",
				from: "categories.videoPlaylist",
				key: "videoPlaylist",
				source: "PLAYLIST",
			},
			{
				type: "MultiCheckSelect",
				title: "Caption",
				placeholder: "Caption",
				from: "categories.videoCaption",
				key: "videoCaption",
				source: "VIDEO_CAPTION",
			},
			{
				type: "MultiCheckSelect",
				title: "Duration",
				placeholder: "Duration",
				from: "categories.videoDuration",
				key: "videoDuration",
				source: "VIDEO_DURATION",
			},
			{
				type: "MultiCheckSelect",
				title: "Creation",
				placeholder: "Creation",
				from: "categories.videoCreation",
				key: "videoCreation",
				source: "VIDEO_CREATION",
			},
			{
				type: "MultiCheckSelect",
				title: "Last update",
				placeholder: "Last update",
				from: "categories.videoLastUpdate",
				key: "videoLastUpdate",
				source: "VIDEO_UPDATE",
			},
			{
				type: "MultiCheckSelect",
				title: "Creator",
				placeholder: "Creator",
				from: "categories.videoCreator",
				key: "videoCreator",
				source: "VIDEO_CREATOR",
			},
		],
	},
};
