import Accordion from "./Accordion";
import AdmissionForm from "./AdmissionForm";
import BackgroundCard from "./BackgroundCard";
import BackgroundImage from "./BackgroundImage";
import BackgroundVideo from "./BackgroundVideo";
import BasicCard from "./BasicCard";
import BasicForm from "./BasicForm";
import BoxedCard from "./BoxedCard";
import CardCarousel from "./CardCarousel";
import Chart from "./Chart";
import ColorCard from "./ColorCard";
import ContactDirectorForm from "./ContactDirectorForm";
import ContactForm from "./ContactForm";
import CypherCard from "./CypherCard";
import DownloadableIconCard from "./DownloadableIconCard";
import DownloadableLogoCard from "./DownloadableLogoCard";
import DownloadForm from "./DownloadForm";
import EventForm from "./EventForm";
import FAQ from "./FAQ";
import GenderChart from "./GenderChart";
import GenericForm from "./GenericForm";
import HeroCarouselSlide from "./HeroCarouselSlide";
import HighlightedImageCard from "./HighlightedImageCard";
import HighlightedTextCard from "./HighlightedTextCard";
import IconCard from "./IconCard";
import ImageStepCollectionCard from "./ImageStepCollectionCard";
import LandingForm from "./LandingForm";
import LinkModal from "./LinkModal";
import LogoCollectionCard from "./LogoCollectionCard";
import LogoThemeCard from "./LogoThemeCard";
import Map from "./Map";
import MasonryCard from "./MasonryCard";
import MilestoneSlide from "./MilestoneSlide";
import MoreInformationForm from "./MoreInformationForm";
import PostgraduateEventForm from "./PostgraduateEventForm";
import PrinciplesCard from "./PrinciplesCard";
import SimpleStepCollectionCard from "./SimpleStepCollectionCard";
import TableColumn from "./TableColumn";
import TableRow from "./TableRow";
import Video from "./Video";
import VideoGalleryCard from "./VideoGalleryCard";
import VideoPlaylistCard from "./VideoPlaylistCard";

export default {
	Accordion,
	AdmissionForm,
	BackgroundCard,
	BackgroundImage,
	BackgroundVideo,
	BasicForm,
	BasicCard,
	BoxedCard,
	CardCarousel,
	Chart,
	ColorCard,
	ContactDirectorForm,
	ContactForm,
	CypherCard,
	DownloadableIconCard,
	DownloadableLogoCard,
	DownloadForm,
	EventForm,
	FAQ,
	GenderChart,
	GenericForm,
	HeroCarouselSlide,
	HighlightedImageCard,
	HighlightedTextCard,
	IconCard,
	ImageStepCollectionCard,
	SimpleStepCollectionCard,
	LandingForm,
	LinkModal,
	LogoCollectionCard,
	LogoThemeCard,
	Map,
	MasonryCard,
	MilestoneSlide,
	PostgraduateEventForm,
	PrinciplesCard,
	MoreInformationForm,
	TableColumn,
	TableRow,
	Video,
	VideoGalleryCard,
	VideoPlaylistCard,
};
