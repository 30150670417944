import { IntroFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicForm from "@schemas/components/BasicForm";
import {
	anchorID,
	heading,
	verticalSpacing,
	themeSelector,
	getThemeOptions,
	loremIpsumParagraph,
	additional,
	content,
	getLinkModal,
} from "@schemas/presets";

const schema: Schema.Module<IntroFormProps> = {
	schemaType: "module",
	component: "IntroForm",
	category: "content",
	displayName: "Intro + Form",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, hideable: false, isMockup: false },
				{
					...heading,
					mandatory: false,
					isMockup: true,
				},
				{ ...content },
				{
					type: "ComponentContainer",
					title: "Button",
					whiteList: ["LinkModal"],
					key: "button",
					hideable: true,
				},

				{
					title: "Quick Facts",
					type: "FieldGroup",
					key: "quickFacts",
					fields: [
						{
							type: "MultiCheckSelect",
							title: "Centers",
							placeholder: "Centers",
							key: "centers",
							source: "CENTER",
							mandatory: true,
						},

						{
							type: "MultiCheckSelect",
							title: "Languages",
							placeholder: "Languages",
							key: "languages",
							source: "LANGUAGE",
							mandatory: true,
						},

						{
							type: "MultiCheckSelect",
							title: "Certification",
							placeholder: "Certification",
							key: "certifications",
							source: "CERTIFICATION",
							mandatory: true,
						},

						{
							type: "MultiCheckSelect",
							title: "Modality",
							placeholder: "Modality",
							key: "modality",
							source: "MODALITY",
							hideable: true,
						},
						{
							title: "Duration",
							type: "TextField",
							key: "duration",
							mandatory: true,
							humanReadable: true,
						},

						{
							type: "MultiCheckSelect",
							title: "Format",
							placeholder: "Format",
							key: "format",
							source: "FORMAT",
							hideable: true,
						},

						{
							title: "Stage",
							type: "TextField",
							key: "stage",
							hideable: true,
							humanReadable: true,
						},
						{
							title: "Starts On",
							type: "DateField",
							key: "intake",
							mandatory: true,
						},

						{
							title: "Schedule",
							type: "TextField",
							key: "schedule",
							hideable: true,
							humanReadable: true,
						},
						{
							title: "Seats",
							type: "TextField",
							key: "places",
							hideable: true,
							humanReadable: true,
						},
					],
				},

				{
					type: "ComponentContainer",
					title: "Form",
					whiteList: [
						"BasicForm",
						"ContactForm",
						"ContactDirectorForm",
						"DownloadForm",
						"MoreInformationForm",
						"AdmissionForm",
						"GenericForm",
					],
					key: "form",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					...anchorID,
				},
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent-alt",
					]),
				},
				{
					...verticalSpacing,
				},
			],
		},
	],

	default: {
		component: "IntroForm",
		additional: "Lorem ipsum",
		title: { content: "Lorem Ipsum", tag: "h3" },
		content: loremIpsumParagraph,
		button: getLinkModal("Ver más", "button4"),
		centers: [],
		languages: [],
		modality: null,
		certification: [],
		format: null,
		stage: null,
		intake: undefined,
		duration: "",
		schedule: null,
		places: null,
		form: {
			basicForm: { ...BasicForm.default },
			contactForm: { component: "ContactForm" },
			contactDirectorForm: { component: "ContactDirectorForm" },
			downloadForm: { component: "DownloadForm" },
			moreInformationForm: { component: "MoreInformationForm" },
			admissionForm: { component: "AdmissionForm" },
			genericForm: { component: "GenericForm" },
		},
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/IntroForm/thumbnail@1x.png",
		"2x": "/thumbnails/modules/IntroForm/thumbnail@2x.png",
	},
};

export default schema;
