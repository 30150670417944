import { Schema } from "@griddo/core";

const schema: Schema.Menu = [
	{
		key: "mainNav",
		display: "Main navigation",
	},
	{
		key: "topNav",
		display: "Top navigation",
	},
	{
		key: "footerNav",
		display: "Footer navigation",
	},
	{
		key: "legalNav",
		display: "Legal navigation",
	},
];

export default schema;
