import { ColorCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import ColorCard from "@schemas/components/ColorCard";
import {
	additional,
	anchorID,
	animation,
	content,
	detail,
	getThemeOptions,
	heading,
	link,
	loremIpsumParagraph,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ColorCollectionProps> = {
	schemaType: "module",
	component: "ColorCollection",
	category: "collection",
	displayName: "Color Collection",
	dataPacks: ["BRAND_SITE"],
	sectionList: { BasicTemplate: ["mainSection"] },

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, isMockup: true, mandatory: false },
				{ ...detail },
				{ ...content },
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["ColorCard"],
					contentType: "components",
					maxItems: 6,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/ColorCollection/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/ColorCollection/Layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/ColorCollection/Layouts/layout3.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt", "inverse"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "ColorCollection",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: loremIpsumParagraph,
		content: null,
		elements: [
			{ ...ColorCard.default },
			{ ...ColorCard.default },
			{ ...ColorCard.default },
		],
		link: { component: "Link" },
		anchorID: null,
		layout: "layout2",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ColorCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ColorCollection/thumbnail@2x.png",
	},
};

export default schema;
