import { TableColumnProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import TableRow from "@schemas/components/TableRow";

const schema: Schema.Component<TableColumnProps> = {
	schemaType: "component",
	component: "TableColumn",
	displayName: "Table Column",
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Header",
					type: "TextArea",
					key: "header",
					mandatory: true,
					hideable: true,
					humanReadable: true,
				},
				{
					contentType: "components",
					title: "Column Rows",
					type: "ComponentArray",
					whiteList: ["TableRow"],
					key: "rows",
					mandatory: true,
				},
			],
		},
	],
	default: {
		component: "TableColumn",
		header: "Title",
		rows: [
			{ ...TableRow.default },
			{ ...TableRow.default },
			{ ...TableRow.default },
		],
	},
};

export default schema;
