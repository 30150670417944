import { LogoCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import LogoCollectionCard from "@schemas/components/LogoCollectionCard";
import {
	anchorID,
	verticalSpacing,
	themeSelector,
	getThemeOptions,
	animation,
	heading,
	subtitle,
	additional,
	content,
	link,
} from "@schemas/presets";

const schema: Schema.Module<LogoCollectionProps> = {
	schemaType: "module",
	component: "LogoCollection",
	category: "collection",
	displayName: "Logo collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, isMockup: false },
				{
					...heading,
					mandatory: false,
				},

				{
					...subtitle,
					isMockup: false,
				},

				{
					...content,
					isMockup: false,
				},

				{
					type: "ComponentArray",
					title: "Logo cards",
					key: "logos",
					whiteList: ["LogoCollectionCard"],
					mandatory: true,
					contentType: "components",
				},

				{
					...link,
					title: "Main Link",
					key: "mainLink",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					...anchorID,
				},
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"inverse-alt",
						"accent-alt",
					]),
				},
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/LogoCollection/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/LogoCollection/Layouts/layout2.png",
						},

						{
							value: "layout3",
							img: "/thumbnails/modules/LogoCollection/Layouts/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/LogoCollection/Layouts/layout4.png",
						},
						{
							value: "layout5",
							img: "/thumbnails/modules/LogoCollection/Layouts/layout5.png",
						},
					],
				},
				{
					...verticalSpacing,
				},
				{
					...animation,
				},
			],
		},
	],

	default: {
		component: "LogoCollection",
		additional: null,
		title: null,
		subtitle: null,
		logos: [
			{ ...LogoCollectionCard.default },
			{ ...LogoCollectionCard.default },
			{ ...LogoCollectionCard.default },
		],
		mainLink: {
			component: "Link",
		},
		anchorID: null,
		subtheme: "default",
		layout: "layout2",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/LogoCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/LogoCollection/thumbnail@2x.png",
	},
};

export default schema;
