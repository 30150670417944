import { SearchResultsProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import HeroInner from "@schemas/modules/HeroInner";

const schema: Schema.Template<SearchResultsProps> = {
	schemaType: "template",
	displayName: "Search Results",
	component: "SearchResults",
	type: { label: "Static", value: "static" },
	singleInstance: true,

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["HeroInner"],
			contentType: "modules",
			key: "heroSection",
			mandatory: true,
		},
		{
			type: "CheckGroup",
			title: "Sidebar",
			key: "sidebar",
			options: [
				{
					name: "news",
					title: "News",
				},
				{
					name: "events",
					title: "Events",
				},
			],
			hideable: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: [
				"BasicContent",
				"BasicText",
				"ContactInfo",
				"FeaturedFacts",
				"ImageBanner",
				"IntroForm",
				"Quote",
				"Table",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			key: "relatedContent",
		},
	],

	default: {
		type: "template",
		templateType: "SearchResults",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [{ ...HeroInner.default }],
		},
		sidebar: ["news", "events"],
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/SearchResults/thumbnail@1x.png",
		"2x": "/thumbnails/templates/SearchResults/thumbnail@2x.png",
	},
};

export default schema;
