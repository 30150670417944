import { BackgroundCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { getThemeOptions, heading, themeSelector } from "@schemas/presets";

const schema: Schema.Component<BackgroundCardProps> = {
	schemaType: "component",
	component: "BackgroundCard",
	displayName: "Background Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Background",
					key: "background",
					whiteList: ["BackgroundImage", "BackgroundVideo"],
					helptext: "Recommended minimum image size: 467x467",
					hideable: true,
				},
				{
					...heading,
					mandatory: false,
					isMockup: true,
				},
				{
					type: "UrlField",
					title: "Link",
					key: "link",
					placeholder: "https://",
					helptext: "The link will be available through the title",
					hideable: true,
				},
			],
		},

		{
			title: "Config",
			fields: [
				{
					...themeSelector,
					options: getThemeOptions(["default", "inverse", "accent"]),
				},
			],
		},
	],

	default: {
		component: "BackgroundCard",
		background: {
			backgroundImage: { component: "BackgroundImage" },
			backgroundVideo: { component: "BackgroundVideo" },
		},
		title: { content: "Lorem ipsum", tag: "h4" },
		link: undefined,
		subtheme: "inverse",
	},

	thumbnails: {
		"1x": "/thumbnails/components/BackgroundCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/BackgroundCard/thumbnail@2x.png",
	},
};

export default schema;
