import { Schema } from "@griddo/core";

const schema: Schema.Header = {
	schemaType: "module",
	displayName: "Header Brand site",
	component: "HeaderBrand",
	type: "header",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Brand site menu name",
					key: "title",
					type: "TextField",
					placeholder: "Type a title",
					mandatory: true,
					isMockup: true,
				},
				{
					key: "search",
					title: "Search feature",
					type: "ConditionalField",
					options: [
						{ name: "yes", title: "Yes", value: true },
						{ name: "no", title: "No", value: false },
					],
					fields: [
						{
							condition: true,
							title: "Search Result page",
							type: "UrlField",
							key: "searchLink",
						},
					],
				},
				{
					type: "RadioGroup",
					title: "Language selector",
					key: "languageSelector",
					hideable: true,
					options: [
						{ name: "yes", title: "Yes", value: true },
						{ name: "no", title: "No", value: false },
					],
				},
				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Link"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					title: "Set as Headerdefault",
					options: [{ title: "Set as Header default" }],
				},
				{
					title: "Select Main menu",
					key: "menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
					helptext: "Select the site menu. It must first be created.",
				},
				{
					type: "UniqueCheck",
					key: "sticky",
					title: "Keep sticky on scroll",
					options: [{ title: "Keep sticky on scroll" }],
				},
			],
		},
	],

	default: {
		component: "HeaderBrand",
		type: "header",
		title: "New Header",
		search: true,
		searchLink: {
			href: undefined,
			linkToURL: undefined,
			newTab: false,
			noFollow: false,
		},
		languageSelector: false,
		link: { component: "Link" },
		setAsDefault: false,
		menu: undefined,
		sticky: true,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeaderBrand/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeaderBrand/thumbnail@2x.png",
	},
};

export default schema;
