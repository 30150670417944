import { AGREEMENTS } from "./AGREEMENTS";
import { BRAND_SITE } from "./BRAND_SITE";
import { EVENTS } from "./EVENTS";
import { LECTURERS } from "./LECTURERS";
import { NEWS } from "./NEWS";
import { PRESS_RELEASES } from "./PRESS_RELEASES";
import { PROGRAMS } from "./PROGRAMS";
import { PROJECTS } from "./PROJECTS";
import { PUBLICATIONS } from "./PUBLICATIONS";
import { TESTIMONIAL } from "./TESTIMONIAL";
import { VIDEOS } from "./VIDEOS";

export default {
	AGREEMENTS,
	BRAND_SITE,
	EVENTS,
	LECTURERS,
	NEWS,
	PRESS_RELEASES,
	PROGRAMS,
	PROJECTS,
	PUBLICATIONS,
	TESTIMONIAL,
	VIDEOS,
};
