import { DownloadFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	detail,
	themeSelector,
	getThemeOptions,
	loremIpsumParagraph,
} from "@schemas/presets";

const schema: Schema.Component<DownloadFormProps> = {
	schemaType: "component",
	displayName: "Download Form",
	component: "DownloadForm",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					isMockup: true,
					mandatory: false,
				},
				{
					...detail,
				},
				{
					type: "CheckGroup",
					title: "Select extra fields to display",
					key: "fields",
					options: [
						{
							title: "Entry dates",
							name: "entryDates",
						},
					],
				},
				{
					type: "TextField",
					key: "legalConditionPreffix",
					title: "Legal condition text preffix",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "TextField",
					key: "legalConditionSuffix",
					title: "Legal condition link text",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "UrlField",
					title: "Legal condition link",
					key: "legalConditionLink",
					placeholder: "https://",
					advanced: true,
				},
				{
					type: "TextField",
					key: "label",
					title: "Button Label",
					placeholder: "Type a label",
					mandatory: true,
					humanReadable: true,
				},
				{
					key: "document",
					title: "Document",
					type: "FileField",
					placeholder: "Upload a document",
					helptext: "It is not necessary to upload a file in program's forms.",
				},
			],
		},
		{
			title: "Config",
			fields: [
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"accent-alt",
						"inverse",
					]),
				},
				{
					title: "Show decoration",
					key: "decoration",
					type: "RadioGroup",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "DownloadForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		label: "Descargar folleto",
		legalConditionPreffix:
			"He podido leer y entiendo la información sobre el uso de mis datos personales explicada en la",
		legalConditionSuffix: "Política de Privacidad",
		legalConditionLink: undefined,
		document: undefined,
		subtheme: "default",
		decoration: false,
	},
};

export default schema;
