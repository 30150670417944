import { Theme } from "@griddo/core";
import primitives from "@themes/global-theme/colors-onexed";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="default"]',
	id: "default",
	name: "The default theme",
	primitives: [
		...primitives,

		// -------------------------------------------------------------------------
		// MISC
		// -------------------------------------------------------------------------

		{
			id: "brandColors",
			name: "brandColors",
			values: [{ cssVar: "--brand-1", value: "#181818" }],
		},
	],
};

export default subtheme;
