import { UIFields } from "@griddo/core";

export const subtitle: UIFields.Wysiwyg = {
	key: "subtitle",
	title: "Subtitle",
	type: "Wysiwyg",
	full: false,
	hideable: true,
	placeholder: "Type a subtitle",
	isMockup: true,
	humanReadable: true,
};
