import { ProgramIntroFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import DownloadForm from "@schemas/components/DownloadForm";
import {
	anchorID,
	heading,
	verticalSpacing,
	themeSelector,
	getThemeOptions,
	loremIpsumParagraph,
	additional,
	content,
	getLinkModal,
} from "@schemas/presets";

const schema: Schema.Module<ProgramIntroFormProps> = {
	schemaType: "module",
	component: "ProgramIntroForm",
	category: "content",
	displayName: "Intro + Form",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, hideable: false, isMockup: false },
				{
					...heading,
					mandatory: false,
					isMockup: true,
				},
				{ ...content },
				{
					type: "ComponentContainer",
					title: "Button",
					whiteList: ["LinkModal"],
					key: "button",
					hideable: true,
				},

				{
					type: "ComponentContainer",
					title: "Form",
					whiteList: ["DownloadForm"],
					key: "form",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					...anchorID,
				},
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent-alt",
					]),
				},
				{
					...verticalSpacing,
				},
			],
		},
	],

	default: {
		component: "ProgramIntroForm",
		additional: "Lorem ipsum",
		title: { content: "Lorem Ipsum", tag: "h3" },
		content: loremIpsumParagraph,
		button: getLinkModal("Ver más", "button4"),
		form: {
			...DownloadForm.default,
		},
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/IntroForm/thumbnail@1x.png",
		"2x": "/thumbnails/modules/IntroForm/thumbnail@2x.png",
	},
};

export default schema;
