import { MasonryCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	content,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.Component<MasonryCardProps> = {
	schemaType: "component",
	component: "MasonryCard",
	displayName: "Masonry Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Media",
					key: "media",
					whiteList: ["Image"],
					helptext:
						"Recommended minimum image size: 467x350. Remember to adjust the card size so that the image (if added) appears correctly.",
					hideable: true,
				},
				{ ...heading, mandatory: false, isMockup: true },
				{ ...content },
				{
					type: "UrlField",
					title: "Url",
					key: "url",
					advanced: true,
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Card size",
					key: "size",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "small",
							img: "/thumbnails/components/MasonryCard/Size/small.png",
						},
						{
							value: "medium",
							img: "/thumbnails/components/MasonryCard/Size/medium.png",
						},
						{
							value: "large",
							img: "/thumbnails/components/MasonryCard/Size/large.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["inverse", "accent-alt"]),
				},
			],
		},
	],

	default: {
		component: "MasonryCard",
		media: { component: "Image" },
		title: { content: "Lorem ipsum", tag: "h4" },
		content: loremIpsumParagraph,
		url: undefined,
		size: "small",
		subtheme: "accent-alt",
	},
};

export default schema;
