import { HeroLandingProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	anchorID,
	subtitle,
	animation,
	additional,
} from "@schemas/presets";

const schema: Schema.Module<HeroLandingProps> = {
	schemaType: "module",
	component: "HeroLanding",
	category: "landings",
	displayName: "Hero Landing",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Logo",
					type: "ConditionalField",
					key: "logoOption",
					hideable: true,
					options: [
						{
							value: "defaultLogo",
							title: "Default",
							name: "defaultLogo",
						},
						{
							value: "customLogo",
							title: "Custom",
							name: "customLogo",
						},
					],
					fields: [
						{
							condition: "defaultLogo",
							title: "Select Logo",
							type: "Select",
							key: "defaultLogo",
							placeholder: "Select a logo",
							mandatory: true,
							options: [
								{ value: "logoComillasHorizontalBn", label: "Comillas" },
								{
									value: "logoComillasHorizontal",
									label: "Comillas color",
								},
								{
									value: "logoComillasHorizontalInverse",
									label: "Comillas inverse",
								},
								{ value: "logoCihsHorizontalBn", label: "CIHS" },
								{ value: "logoCihsHorizontal", label: "CIHS color" },
								{
									value: "logoCihsHorizontalInverse",
									label: "CIHS inverse",
								},
								{ value: "logoEuefHorizontalBn", label: "EUEF" },
								{ value: "logoEuefHorizontal", label: "EUEF color" },
								{
									value: "logoEuefHorizontalInverse",
									label: "EUEF inverse",
								},
								{ value: "logoIcadeHorizontalBn", label: "ICADE " },
								{ value: "logoIcadeHorizontal", label: "ICADE color" },
								{
									value: "logoIcadeHorizontalInverse",
									label: "ICADE inverse",
								},
								{ value: "logoIcaiHorizontalBn", label: "ICAI" },
								{ value: "logoIcaiHorizontal", label: "ICAI color" },
								{
									value: "logoIcaiHorizontalInverse",
									label: "ICAI inverse",
								},
							],
						},
						{
							condition: "customLogo",
							title: "Image",
							type: "ImageField",
							key: "customLogo",
							mandatory: true,
							helptext: "Size recommendation: 276x90px",
						},
					],
				},
				{
					title: "Show decoration",
					key: "decoration",
					type: "RadioGroup",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
				{ ...additional },
				{
					...heading,
					hideable: false,
					isMockup: true,
				},
				{ ...subtitle },
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					helptext: "Recommended minimum image size: 2508x1030px",
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "RadioGroup",
					key: "titleSize",
					title: "Title Size",
					mandatory: true,
					options: [
						{ name: "s", value: "s", title: "S" },
						{ name: "m", value: "m", title: "M" },
						{ name: "l", value: "l", title: "L" },
						{ name: "xl", value: "xl", title: "XL" },
					],
				},
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/HeroLanding/Layouts/layout001.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/HeroLanding/Layouts/layout002.png",
						},
						{
							value: "layout03",
							img: "/thumbnails/modules/HeroLanding/Layouts/layout003.png",
						},
					],
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "HeroLanding",
		logoOption: "defaultLogo",
		defaultLogo: "logoComillasHorizontal",
		decoration: true,
		additional: null,
		title: {
			content: "Lorem ipsum",
			tag: "h1",
		},
		subtitle: null,
		anchorID: null,
		titleSize: "s",
		layout: "layout01",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeroLanding/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeroLanding/thumbnail@2x.png",
	},
};

export default schema;
