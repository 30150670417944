import { Schema } from "@griddo/core";
import { heading, loremIpsumParagraph } from "@schemas/presets";

export const TESTIMONIAL: Schema.SimpleContentType = {
	dataPacks: ["TESTIMONIAL"],
	title: "Testimonial",
	local: false,
	translate: true,
	clone: null,
	defaultValues: {
		position: "Lorem ipsum",
		quote: loremIpsumParagraph,
	},
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Label",
				placeholder: "Type a label",
				type: "TextField",
				mandatory: true,
				helptext: "Used in the editor.",
			},
			{
				key: "photo",
				title: "Photo",
				type: "ImageField",
				helptext: "Recommended use a vector or minimum image size: 256x256",
			},
			{
				key: "position",
				title: "Position",
				placeholder: "Type a position",
				type: "TextField",
				isMockup: true,
				humanReadable: true,
			},
			{
				...heading,
				key: "authorName",
				title: "Author name",
				placeholder: "Type a name",
				isMockup: true,
				default: { tag: "h2", content: "Godofredo Doe" },
			},
			{
				key: "quote",
				title: "Quote",
				placeholder: "Type a quote",
				type: "Wysiwyg",
				full: false,
				mandatory: true,
				isMockup: true,
				humanReadable: true,
			},
			{
				key: "divider",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Select Categories",
					text: "",
				},
			},
			{
				key: "centers",
				title: "Centers",
				placeholder: "Centers",
				type: "MultiCheckSelect",
				source: "CENTER",
				mandatory: true,
			},
			{
				key: "programs",
				title: "Programs",
				placeholder: "Programs",
				type: "MultiCheckSelect",
				source: "PROGRAM",
			},
			{
				key: "profiles",
				title: "Profiles",
				placeholder: "Profiles",
				type: "MultiCheckSelect",
				source: "PROFILE",
				mandatory: true,
			},
		],
	},
};
