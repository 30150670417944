import { CardsCarouselProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import CardCarousel from "@schemas/components/CardCarousel";
import {
	additional,
	anchorID,
	animation,
	content,
	getThemeOptions,
	heading,
	link,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<CardsCarouselProps> = {
	schemaType: "module",
	component: "CardsCarousel",
	displayName: "Cards Carousel",
	category: "landings",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{
					...heading,
					isMockup: true,
					mandatory: false,
				},
				{ ...subtitle },
				{ ...content },
				{
					type: "ComponentArray",
					title: "Cards",
					key: "cards",
					whiteList: ["CardCarousel"],
					contentType: "components",
					maxItems: 10,
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "CardsCarousel",
		additional: null,
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: null,
		content: null,
		cards: [
			{ ...CardCarousel.default },
			{ ...CardCarousel.default },
			{ ...CardCarousel.default },
		],
		link: { component: "Link" },
		anchorID: null,
		subtheme: "accent",
		verticalSpacing: "large",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CardsCarousel/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CardsCarousel/thumbnail@2x.png",
	},
};

export default schema;
