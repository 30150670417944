//
// Griddo Templates
//
// Every Template must be imported and reexported.
// Yes, a barrel index :)

import * as React from "react";

// Templates
const BasicTemplate = React.lazy(() => import("./BasicTemplate"));
const BasicTvTemplate = React.lazy(() => import("./BasicTvTemplate"));
const ChannelDetail = React.lazy(() => import("./ChannelDetail"));
const ChannelsList = React.lazy(() => import("./ChannelsList"));
const Error404 = React.lazy(() => import("./Error404"));
const EventDetail = React.lazy(() => import("./EventDetail"));
const EventsList = React.lazy(() => import("./EventsList"));
const Landing = React.lazy(() => import("./Landing"));
const LiveStream = React.lazy(() => import("./LiveStream"));
const NewsDetail = React.lazy(() => import("./NewsDetail"));
const NewsList = React.lazy(() => import("./NewsList"));
const PressReleasesDetail = React.lazy(() => import("./PressReleasesDetail"));
const PressReleasesList = React.lazy(() => import("./PressReleasesList"));
const ProgramDetail = React.lazy(() => import("./ProgramDetail"));
const ProgramLanding = React.lazy(() => import("./ProgramLanding"));
const ProgramsList = React.lazy(() => import("./ProgramsList"));
const ProjectDetail = React.lazy(() => import("./ProjectDetail"));
const ProjectsList = React.lazy(() => import("./ProjectsList"));
const QualityTemplate = React.lazy(() => import("./QualityTemplate"));
const SearchResults = React.lazy(() => import("./SearchResults"));
const SearchResultsGalleries = React.lazy(
	() => import("./SearchResultsGalleries")
);
const SearchResultsTv = React.lazy(() => import("./SearchResultsTv"));
const SearchVideoCreator = React.lazy(() => import("./SearchVideoCreator"));
const SearchVideoTags = React.lazy(() => import("./SearchVideoTags"));
const VideoDetail = React.lazy(() => import("./VideoDetail"));
const VideosList = React.lazy(() => import("./VideosList"));

// Griddo bundle export
export default {
	BasicTemplate,
	BasicTvTemplate,
	ChannelDetail,
	ChannelsList,
	Error404,
	EventDetail,
	EventsList,
	Landing,
	LiveStream,
	NewsDetail,
	NewsList,
	PressReleasesDetail,
	PressReleasesList,
	ProgramDetail,
	ProgramLanding,
	ProgramsList,
	ProjectDetail,
	ProjectsList,
	QualityTemplate,
	SearchResults,
	SearchResultsGalleries,
	SearchResultsTv,
	SearchVideoCreator,
	SearchVideoTags,
	VideoDetail,
	VideosList,
};
