import { DownloadCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import DownloadComponent from "@schemas/objects/DownloadComponent";
import {
	additional,
	anchorID,
	subtitle,
	content,
	heading,
	loremIpsumParagraph,
	themeSelector,
	verticalSpacing,
	animation,
} from "@schemas/presets";
const schema: Schema.Module<DownloadCollectionProps> = {
	schemaType: "module",
	component: "DownloadCollection",
	category: "collection",
	displayName: "Download Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{
					title: "Links",
					type: "ComponentArray",
					key: "links",
					mandatory: true,
					whiteList: ["DownloadComponent"],
					contentType: "components",
					maxItems: 8,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{ ...themeSelector },
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "DownloadCollection",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h3",
		},
		subtitle: loremIpsumParagraph,
		content: null,
		showIcon: "yes",
		links: [{ ...DownloadComponent.default }, { ...DownloadComponent.default }],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/DownloadCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/DownloadCollection/thumbnail@2x.png",
	},
};

export default schema;
