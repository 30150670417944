import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Header = {
	schemaType: "module",
	displayName: "Header TV",
	component: "HeaderTV",
	type: "header",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Main menu name",
					key: "title",
					type: "TextField",
					placeholder: "Type a title",
					mandatory: true,
					isMockup: true,
				},
				{
					key: "search",
					title: "Search feature",
					type: "ConditionalField",
					options: [
						{ name: "yes", title: "Yes", value: true },
						{ name: "no", title: "No", value: false },
					],
					fields: [
						{
							condition: true,
							...heading,
							key: "searchTitle",
							mandatory: false,
						},
						{
							condition: true,
							title: "Subtitle",
							key: "subtitle",
							type: "TextField",
							placeholder: "Type a subtitle",
							hideable: true,
						},
						{
							condition: true,
							title: "Search Result page",
							type: "UrlField",
							key: "searchLink",
						},
					],
				},
				{
					title: "Tags result page",
					type: "UrlField",
					key: "tagLink",
				},
				{
					title: "Creator result page",
					type: "UrlField",
					key: "creatorLink",
				},
				{
					type: "RadioGroup",
					title: "Language selector",
					key: "languageSelector",
					hideable: true,
					options: [
						{ name: "yes", title: "Yes", value: true },
						{ name: "no", title: "No", value: false },
					],
				},
				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Link"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					title: "Set as Headerdefault",
					options: [{ title: "Set as Header default" }],
				},
				{
					title: "Select Main menu",
					key: "menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
					helptext: "Select the site menu. It must first be created.",
				},
				{
					title: "Select Top menu",
					key: "topMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
					helptext: "Select the site menu. It must first be created.",
				},
				{
					type: "UniqueCheck",
					key: "sticky",
					title: "Keep sticky on scroll",
					options: [{ title: "Keep sticky on scroll" }],
				},
			],
		},
	],

	default: {
		component: "HeaderTV",
		type: "header",
		title: "New Header",
		search: true,
		searchTitle: { content: "¿Qué buscas?", tag: "h2" },
		subtitle: "Encuéntralo en Comillas",
		searchLink: {
			href: undefined,
			linkToURL: undefined,
			newTab: false,
			noFollow: false,
		},
		tagLink: {
			href: undefined,
			linkToURL: undefined,
			newTab: false,
			noFollow: false,
		},
		creatorLink: {
			href: undefined,
			linkToURL: undefined,
			newTab: false,
			noFollow: false,
		},
		languageSelector: true,
		link: { component: "Link" },
		setAsDefault: false,
		menu: undefined,
		topMenu: undefined,
		sticky: true,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeaderTV/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeaderTV/thumbnail@2x.png",
	},
};

export default schema;
