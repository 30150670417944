import { DownloadComponentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { defaultLink, link } from "@schemas/presets";

const schema: Schema.Component<DownloadComponentProps> = {
	schemaType: "component",
	component: "DownloadComponent",
	displayName: "Download",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					placeholder: "Type a title",
					isMockup: true,
					humanReadable: true,
				},
				{
					title: "File",
					type: "ConditionalField",
					key: "download",
					mandatory: true,
					options: [
						{
							name: "file",
							value: "file",
							title: "File",
						},

						{
							name: "link",
							value: "link",
							title: "Link",
						},
					],
					fields: [
						{
							condition: "file",
							type: "FileField",
							title: "File",
							key: "file",
							mandatory: true,
						},
						{
							condition: "link",
							...link,
						},
					],
				},
			],
		},
	],

	default: {
		component: "DownloadComponent",
		title: "Lorem ipsum",
		download: "file",
		link: defaultLink,
		file: undefined,
	},
};

export default schema;
