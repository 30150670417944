import { Schema } from "@griddo/core";

export const REGISTRATION: Schema.CategoryContentType = {
	dataPacks: ["PROGRAMS", "LECTURERS", "TESTIMONIAL"],
	title: "Calls for registrations (auto)",
	local: false,
	translate: false,
	taxonomy: true,
	fromPage: false,
	editable: false,
};
