import { AnimatedImageGalleryProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	content,
	getThemeOptions,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<AnimatedImageGalleryProps> = {
	schemaType: "module",
	component: "AnimatedImageGallery",
	displayName: "Animated Image Gallery",
	category: "landings",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					title: "Title 01",
					key: "title1",
					isMockup: true,
					mandatory: false,
				},
				{
					...heading,
					title: "Title 02",
					key: "title2",
					isMockup: true,
					mandatory: false,
				},
				{ ...content },
				{
					type: "ComponentArray",
					title: "Images",
					key: "images",
					whiteList: ["Image"],
					contentType: "components",
					maxItems: 16,
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "AnimatedImageGallery",
		title1: { content: "Lorem ipsum", tag: "h2" },
		title2: { content: "Lorem ipsum", tag: "h2" },
		content: null,
		images: [
			{ component: "Image" },
			{ component: "Image" },
			{ component: "Image" },
		],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/AnimatedImageGallery/thumbnail@1x.png",
		"2x": "/thumbnails/modules/AnimatedImageGallery/thumbnail@2x.png",
	},
};

export default schema;
