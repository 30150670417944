import { Error404Props } from "@autoTypes";
import { Schema } from "@griddo/core";
import HighlightedCardCollection from "@schemas/modules/HighlightedCardCollection";
import { heading, subtitle } from "@schemas/presets";

const schema: Schema.Template<Error404Props> = {
	schemaType: "template",
	displayName: "Error 404",
	component: "Error404",
	type: { label: "Static", value: "static", special: "404" },
	singleInstance: true,

	content: [
		{ ...heading },
		{
			...subtitle,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: ["HighlightedCardCollection"],
			contentType: "modules",
			key: "relatedContent",
		},
	],

	default: {
		type: "template",
		templateType: "Error404",
		title: { content: "Página no encontrada", tag: "h2" },
		subtitle:
			"Esta dirección no existe, pero esperamos poder ayudarte a encontrar lo que buscas:",
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [{ ...HighlightedCardCollection.default }],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/Error404/thumbnail@1x.png",
		"2x": "/thumbnails/templates/Error404/thumbnail@2x.png",
	},
};

export default schema;
