import { VideoDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<VideoDetailProps> = {
	schemaType: "template",
	component: "VideoDetail",
	displayName: "Video (Kaltura)",
	dataPacks: ["VIDEOS"],

	type: {
		label: "Video",
		value: "video",
		mode: "detail",
	},

	content: [
		{
			type: "UniqueCheck",
			key: "automaticSubtitles",
			title: "Subtitles",
			options: [{ title: "Add automatic subtitles" }],
		},
		{
			type: "FieldGroup",
			key: "videoData",
			title: "Video Data",

			fields: [
				{
					type: "Wysiwyg",
					key: "description",
					title: "Description",
					hideable: true,
					disabled: true,
				},
				{
					type: "TextField",
					title: "Video ID",
					key: "videoId",
					mandatory: true,
					readonly: true,
				},
				{
					type: "TextField",
					title: "Video URL",
					key: "videoUrl",
					mandatory: true,
					readonly: true,
				},
				{
					type: "TextField",
					title: "Kaltura image",
					key: "kalturaImage",
					readonly: true,
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					disabled: true,
				},
				{
					type: "TextField",
					title: "Search text",
					key: "searchText",
					readonly: true,
				},
				{
					type: "DateField",
					title: "Date",
					key: "videoDate",
					disabled: true,
				},
				{
					type: "DateField",
					title: "Date (update)",
					key: "videoUpdateDate",
					disabled: true,
				},
				{
					type: "TextField",
					title: "Creator",
					key: "creator",
					readonly: true,
				},
				{
					type: "NumberField",
					title: "Duration",
					key: "duration",
					disabled: true,
				},
				{
					type: "NumberField",
					title: "Plays",
					key: "plays",
					disabled: true,
				},
				{
					type: "TextField",
					title: "Tags",
					key: "tags",
					readonly: true,
				},
				{
					type: "MultiCheckSelectGroup",
					title: "Select Categories",
					key: "categories",
					filled: true,
					disabled: true,
					elements: [
						{
							placeholder: "Categories",
							key: "videoCategories",
							source: "CATEGORY_VIDEO",
						},
						{
							placeholder: "Tags",
							key: "videoTags",
							source: "VIDEO_TAG",
						},
						{
							placeholder: "Channel",
							key: "videoChannel",
							source: "CHANNEL",
						},
						{
							placeholder: "Playlist",
							key: "videoPlaylist",
							source: "PLAYLIST",
						},
						{
							placeholder: "Caption",
							key: "videoCaption",
							source: "VIDEO_CAPTION",
						},
						{
							placeholder: "Duration",
							key: "videoDuration",
							source: "VIDEO_DURATION",
						},
						{
							placeholder: "Creation",
							key: "videoCreation",
							source: "VIDEO_CREATION",
						},
						{
							placeholder: "Last update",
							key: "videoLastUpdate",
							source: "VIDEO_UPDATE",
						},
						{
							placeholder: "Creator",
							key: "videoCreator",
							source: "VIDEO_CREATOR",
						},
					],
				},
				{
					type: "ReferenceField",
					title: "Related Videos",
					key: "data",
					sources: [{ structuredData: "VIDEO" }],
					maxItems: 8,
				},
			],
		},
	],

	config: [
		{
			type: "UniqueCheck",
			key: "breadcrumbsDesktop",
			title: "Breadcrumbs Desktop/Tablet",
			options: [{ title: "Display in tablet and desktop" }],
		},
		{
			type: "UniqueCheck",
			key: "breadcrumbs",
			title: "Breadcrumbs Mobile",
			options: [{ title: "Display in mobile" }],
		},
	],

	default: {
		type: "template",
		templateType: "VideoDetail",
		automaticSubtitles: true,
		data: {
			mode: "auto",
			order: "videoDate-DESC",
			sources: [{ structuredData: "VIDEO" }],
			quantity: 5,
		},
		breadcrumbsDesktop: false,
		breadcrumbs: false,
	},

	thumbnails: {
		"1x": "/thumbnails/templates/VideoDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/VideoDetail/thumbnail@2x.png",
	},
};

export default schema;
