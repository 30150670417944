import { UIFields } from "@griddo/core";

export const animation: UIFields.RadioGroup = {
	type: "RadioGroup",
	title: "Animation",
	key: "animation",
	mandatory: true,
	options: [
		{ name: "none", value: "none", title: "No animation" },
		{ name: "fadeIn", value: "fadeIn", title: "Fade In" },
		{ name: "fadeInDown", value: "fadeInDown", title: "Fade In Down" },
		{ name: "fadeInLeft", value: "fadeInLeft", title: "Fade In Left" },
		{ name: "fadeInRight", value: "fadeInRight", title: "Fade In Right" },
	],
};
