import { HighlightedImageCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { content, heading, loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Component<HighlightedImageCardProps> = {
	schemaType: "component",
	component: "HighlightedImageCard",
	displayName: "Highlighted Image Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false, isMockup: true },
				{ ...content },
				{
					type: "ComponentContainer",
					title: "Media",
					key: "media",
					whiteList: ["Image"],
					helptext: "Recommended minimum image size: 467x350",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "HighlightedImageCard",
		title: { content: "Lorem ipsum", tag: "h4" },
		content: loremIpsumParagraph,
		media: { component: "Image" },
	},
};

export default schema;
