import { DownloadableLogoCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import DownloadableLogoCard from "@schemas/components/DownloadableLogoCard";
import {
	additional,
	anchorID,
	animation,
	content,
	detail,
	getThemeOptions,
	heading,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<DownloadableLogoCollectionProps> = {
	schemaType: "module",
	component: "DownloadableLogoCollection",
	category: "collection",
	displayName: "Downloadable Logo Collection",
	dataPacks: ["BRAND_SITE"],
	sectionList: { BasicTemplate: ["mainSection"] },

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, isMockup: true, mandatory: false },
				{ ...detail },
				{ ...content },
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["DownloadableLogoCard"],
					contentType: "components",
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"inverse-alt",
					]),
				},
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/DownloadableLogoCollection/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/DownloadableLogoCollection/Layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/DownloadableLogoCollection/Layouts/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/DownloadableLogoCollection/Layouts/layout4.png",
						},
						{
							value: "layout5",
							img: "/thumbnails/modules/DownloadableLogoCollection/Layouts/layout5.png",
						},
					],
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "DownloadableLogoCollection",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: null,
		content: null,
		elements: new Array(2)
			.fill(0)
			.map(() => ({ ...DownloadableLogoCard.default })),
		link: { component: "Link" },
		anchorID: null,
		subtheme: "default",
		layout: "layout2",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/DownloadableLogoCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/DownloadableLogoCollection/thumbnail@2x.png",
	},
};

export default schema;
