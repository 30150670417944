import { MainHeroProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	anchorID,
	themeSelector,
	loremIpsumParagraph,
	animation,
	additional,
	content,
	defaultLink,
	getThemeOptions,
	subtitle,
} from "@schemas/presets";

const schema: Schema.Module<MainHeroProps> = {
	schemaType: "module",
	component: "MainHero",
	category: "heros",
	displayName: "Main Hero",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{
					...heading,
					hideable: false,
					isMockup: true,
					mandatory: true,
				},
				{ ...subtitle },
				{
					...content,
				},
				{
					type: "ComponentContainer",
					title: "Visual Content 1",
					key: "visualContent1",
					whiteList: ["ImageLite", "Video"],
					hideable: true,
				},
				{
					type: "ComponentContainer",
					title: "Visual Content 2",
					key: "visualContent2",
					whiteList: ["ImageLite", "Video"],
					hideable: true,
				},
				{
					type: "ComponentContainer",
					title: "Link 1",
					key: "link1",
					whiteList: ["Link"],
					hideable: true,
				},
				{
					type: "ComponentContainer",
					title: "Link 2",
					key: "link2",
					whiteList: ["Link"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{ ...themeSelector, options: getThemeOptions(["default"]) },
				{ ...animation },
				{
					type: "RadioGroup",
					key: "titleSize",
					title: "Title Size",
					mandatory: true,
					options: [
						{ name: "s", value: "s", title: "s" },
						{ name: "m", value: "m", title: "m" },
						{ name: "l", value: "l", title: "l" },
						{ name: "xl", value: "xl", title: "xl" },
					],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbsDesktop",
					title: "Breadcrumbs Desktop/Tablet",
					options: [{ title: "Display in tablet and desktop" }],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbs",
					title: "Breadcrumbs Mobile",
					options: [{ title: "Display in mobile" }],
				},
			],
		},
	],

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/modules/MainHero/onexed@1x.png",
			"2x": "/thumbnails/modules/MainHero/onexed@2x.png",
		},
		"1x": "/thumbnails/modules/MainHero/thumbnail@1x.png",
		"2x": "/thumbnails/modules/MainHero/thumbnail@2x.png",
	},

	default: {
		component: "MainHero",
		additional: "",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		visualContent1: {
			imageLite: {
				component: "ImageLite",
				loading: "eager",
				fetchpriority: "high",
				quality: 80,
			},
			video: { component: "Video" },
		},
		visualContent2: {
			imageLite: {
				component: "ImageLite",
				loading: "eager",
				fetchpriority: "high",
				quality: 80,
			},
			video: { component: "Video" },
		},
		link1: {
			...defaultLink,
			variant: "button2",
			text: "Ver más",
		},
		link2: {
			...defaultLink,
			variant: "button3",
			text: "Ver más",
		},
		anchorID: null,
		subtheme: "default",
		animation: "fadeIn",
		titleSize: "l",
		breadcrumbsDesktop: true,
		breadcrumbs: false,
	},
};

export default schema;
