import { VideoDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	getThemeOptions,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<VideoDistributorProps> = {
	schemaType: "module",
	component: "VideoDistributor",
	category: "distributors",
	displayName: "Video Distributor",
	dataPacks: ["VIDEOS"],
	sectionList: { BasicTvTemplate: ["mainSection"] },

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false, isMockup: true },
				{
					title: "",
					type: "ReferenceField",
					sources: [{ structuredData: "VIDEO" }],
					key: "data",
					mandatory: true,
				},
				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["SimpleLink"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["inverse", "inverse-alt"]),
				},
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/VideoDistributor/Layouts/layout001.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/VideoDistributor/Layouts/layout002.png",
						},
					],
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "VideoDistributor",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "VIDEO", globalOperator: "OR" }],
			quantity: 8,
		},
		link: {
			component: "SimpleLink",
		},
		anchorID: null,
		subtheme: "inverse",
		layout: "layout01",
		animation: "none",
		verticalSpacing: "large",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/VideoDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/VideoDistributor/thumbnail@2x.png",
	},
};

export default schema;
