import { WhatsAppProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<WhatsAppProps> = {
	schemaType: "component",
	component: "WhatsApp",
	displayName: "WhatsApp",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "WhatsApp",
					key: "value",
					type: "TextField",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "WhatsApp",
		value: "",
		icon: "whatsapp",
	},
};

export default schema;
