import { ImageLiteProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<ImageLiteProps> = {
	schemaType: "component",
	component: "ImageLite",
	displayName: "Image",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "imageField",
				},
			],
		},

		{
			title: "Config",
			fields: [
				{
					title: "Quality",
					type: "SliderField",
					key: "quality",
					step: 10,
					min: 0,
					max: 100,
					helptext:
						"Set the image quality. The higher the quality, the bigger the file.",
				},
				{
					title: "Loading",
					key: "loading",
					type: "RadioGroup",
					helptext:
						"Use this option to control the image loading behavior by the browser. It is recommended to use 'eager' value in hero modules.",
					options: [
						{ name: "lazy", value: "lazy", title: "Lazy" },
						{ name: "eager", value: "eager", title: "Eager" },
					],
				},
				{
					title: "Decoding",
					key: "decoding",
					type: "RadioGroup",
					helptext:
						"Represents a hint given to the browser on how it should decode the image.",
					options: [
						{ name: "auto", value: "auto", title: "Auto" },
						{ name: "async", value: "async", title: "async" },
						{ name: "sync", value: "sync", title: "sync" },
					],
				},
				{
					title: "Fetch priority",
					key: "fetchpriority",
					type: "RadioGroup",
					helptext:
						"Represents a hint given to the browser on how it should prioritize the fetch of the image relative to other images. It is recommended to use 'high' value in hero and top modules.",
					options: [
						{ name: "auto", value: "auto", title: "Auto" },
						{ name: "low", value: "low", title: "low" },
						{ name: "high", value: "high", title: "high" },
					],
				},
			],
		},
	],

	default: {
		component: "ImageLite",
		imageField: undefined,
		quality: 70,
		loading: "lazy",
		decoding: "async",
		fetchpriority: "auto",
	},

	thumbnails: {
		"1x": "/thumbnails/components/Image/thumbnail@1x.png",
		"2x": "/thumbnails/components/Image/thumbnail@2x.png",
	},
};

export default schema;
