import { Schema } from "@griddo/core";

export const FUNDING_ORGANIZATION: Schema.SimpleContentType = {
	dataPacks: ["PROJECTS"],
	title: "Funding organization (projects)",
	local: true,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
				mandatory: true,
				readonly: true,
			},
			{
				key: "image",
				title: "Image",
				type: "ImageField",
			},
		],
	},
};
