import { PressReleasesListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import HeroInner from "@schemas/modules/HeroInner";

const schema: Schema.Template<PressReleasesListProps> = {
	schemaType: "template",
	component: "PressReleasesList",
	displayName: "Press Releases List",
	dataPacks: ["PRESS_RELEASES"],

	type: { label: "Press Releases", value: "Press Releases" },

	content: [
		{
			type: "ComponentArray",
			title: "Hero",
			maxItems: 1,
			whiteList: ["HeroInner"],
			contentType: "modules",
			key: "heroSection",
			mandatory: true,
		},
		{
			type: "ReferenceField",
			title: "",
			key: "data",
			sources: [{ structuredData: "PRESS_RELEASES" }],
			selectionType: ["auto"],
			mandatory: true,
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			min: 6,
			max: 40,
			mandatory: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: [
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ContactInfo",
				"FeaturedBlock",
				"FeaturedFacts",
				"ImageBanner",
				"IntroForm",
				"Quote",
				"Table",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			key: "relatedContent",
		},
	],

	config: [
		{
			type: "RadioGroup",
			title: "Pagination type",
			key: "paginationType",
			options: [
				{ name: "normal", value: "normal", title: "Normal pagination" },
				{ name: "infinite", value: "infinite", title: "Infinite scroll" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "PressReleasesList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [{ ...HeroInner.default }],
		},
		itemsPerPage: 6,
		activePage: 1,
		data: {
			sources: [{ structuredData: "PRESS_RELEASES" }],
			mode: "auto",
			order: "recent-DESC",
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
		paginationType: "normal",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/PressReleasesList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/PressReleasesList/thumbnail@2x.png",
	},
};

export default schema;
