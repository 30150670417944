import { Schema } from "@griddo/core";

export const CAMPUS: Schema.CategoryContentType = {
	dataPacks: ["PROGRAMS", "LECTURERS", "TESTIMONIAL"],
	title: "Campus (auto)",
	local: false,
	translate: false,
	taxonomy: true,
	fromPage: false,
	editable: false,
};
