import { ARTICLES_EVENTS } from "./ARTICLES_EVENTS";
import { BRAND_SITE } from "./BRAND_SITE";
import { PEOPLE } from "./PEOPLE";
import { PROJECTS } from "./PROJECTS";
import { STUDIES } from "./STUDIES";
import { VIDEOS } from "./VIDEOS";

export default {
	ARTICLES_EVENTS,
	BRAND_SITE,
	PEOPLE,
	PROJECTS,
	STUDIES,
	VIDEOS,
};
