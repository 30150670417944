import { ProjectsListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import HeroInner from "@schemas/modules/HeroInner";
import { heading, loremIpsumParagraph, subtitle } from "@schemas/presets";

const schema: Schema.Template<ProjectsListProps> = {
	schemaType: "template",
	component: "ProjectsList",
	displayName: "Projects List",
	dataPacks: ["PROJECTS"],

	type: { label: "Project", value: "project" },

	content: [
		{
			type: "ComponentArray",
			title: "Hero",
			maxItems: 1,
			whiteList: ["HeroInner"],
			contentType: "modules",
			key: "heroSection",
			mandatory: true,
		},
		{ ...heading, key: "templateTitle", hideable: false, isMockup: true },
		{ ...subtitle },
		{
			type: "CheckGroup",
			title: "Select template options",
			key: "templateOptions",
			options: [
				{
					title: "Search feature",
					name: "search",
				},
				{
					title: "Filter (Keywords)",
					name: "filterKeywords",
				},
				{
					title: "Filter (Funding organization)",
					name: "filterFundingOrganization",
				},
				{
					title: "Filter (Partner organization)",
					name: "filterPartnerOrganization",
				},
			],
		},
		{
			type: "TextField",
			title: "Search placeholder",
			key: "searchPlaceholder",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "TextField",
			title: "No Results text",
			key: "noResultsText",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "ReferenceField",
			title: "",
			key: "data",
			sources: [{ structuredData: "PROJECT" }],
			selectionType: ["auto"],
			mandatory: true,
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			min: 8,
			max: 40,
			mandatory: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: [
				"BasicContent",
				"BasicText",
				"ContactInfo",
				"ImageBanner",
				"IntroForm",
				"Quote",
				"Table",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			key: "relatedContent",
		},
	],

	config: [
		{
			type: "RadioGroup",
			title: "Pagination type",
			key: "paginationType",
			options: [
				{ name: "normal", value: "normal", title: "Normal pagination" },
				{ name: "infinite", value: "infinite", title: "Infinite scroll" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "ProjectsList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [{ ...HeroInner.default }],
		},
		templateTitle: {
			content: "Lorem ipsum",
			tag: "h1",
		},
		subtitle: loremIpsumParagraph,
		searchPlaceholder: "Buscar un proyecto",
		noResultsText:
			"Sorry, no results were found. Please try a different search.",
		itemsPerPage: 6,
		activePage: 1,
		data: {
			sources: [{ structuredData: "PROJECT" }],
			mode: "auto",
			order: "alpha-ASC",
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
		paginationType: "normal",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProjectsList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProjectsList/thumbnail@2x.png",
	},
};

export default schema;
