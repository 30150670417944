import { MailProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<MailProps> = {
	schemaType: "component",
	component: "Mail",
	displayName: "Mail",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Mail",
					key: "value",
					type: "TextField",
					mandatory: true,
					placeholder: "Type an e-mail",
				},
			],
		},
	],

	default: {
		component: "Mail",
		value: "",
		icon: "mail",
	},
};

export default schema;
