import { Schema } from "@griddo/core";

export const NEWS_TYPE: Schema.CategoryContentType = {
	dataPacks: ["NEWS"],
	title: "Type",
	local: true,
	translate: true,
	taxonomy: true,
	fromPage: false,
};
