import { Schema } from "@griddo/core";

export const PROGRAM_TAG: Schema.SimpleContentType = {
	dataPacks: ["PROGRAMS"],
	title: "Program Tag",
	local: false,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
				mandatory: true,
				validators: {
					maxChar: 14,
				},
			},
			{
				key: "type",
				title: "Type",
				type: "RadioGroup",
				options: [
					{
						name: "featured",
						value: "featured",
						title: "Featured",
					},
					{
						name: "lastSeats",
						value: "lastSeats",
						title: "Last seats",
					},
					{
						name: "new",
						value: "new",
						title: "New",
					},
				],
				mandatory: true,
			},
		],
	},
};
