import { Schema } from "@griddo/core";

export const PROJECT: Schema.PageContentType = {
	dataPacks: ["PROJECTS"],
	title: "Project",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["ProjectDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				title: "Image",
				type: "ImageField",
				from: "image",
				key: "image",
			},
			{
				type: "TextField",
				title: "Id API (identificador)",
				from: "idAPI",
				key: "idAPI",
			},
			{
				type: "TextField",
				title: "Id Project (idProyecto)",
				from: "idProject",
				key: "idProject",
			},
			{
				type: "TextField",
				title: "Grant",
				from: "grant",
				key: "grant",
			},
			{
				type: "DateField",
				title: "Start date",
				from: "startDate",
				key: "startDate",
				indexable: true,
			},
			{
				type: "DateField",
				title: "End date",
				from: "endDate",
				key: "endDate",
				indexable: true,
			},
			{
				type: "DateField",
				title: "Extension date",
				from: "extensionDate",
				key: "extensionDate",
				indexable: true,
			},
			{
				type: "Wysiwyg",
				title: "Summary",
				from: "summary",
				key: "summary",
				full: false,
			},
			{
				type: "TextArea",
				title: "Members (list)",
				from: "membersList",
				key: "membersList",
			},
			{
				type: "TextArea",
				title: "Calls for proposal (list)",
				from: "callsList",
				key: "callsList",
			},
			{
				type: "MultiCheckSelect",
				title: "Keywords",
				placeholder: "Keywords",
				from: "categories.keywords",
				key: "keywords",
				source: "PROJECT_KEYWORD",
			},
			{
				type: "MultiCheckSelect",
				title: "Funding organization",
				placeholder: "Funding organization",
				from: "categories.fundingOrganization",
				key: "fundingOrganization",
				source: "FUNDING_ORGANIZATION",
			},
			{
				type: "MultiCheckSelect",
				title: "Partner organization",
				placeholder: "Partner organization",
				from: "categories.partnerOrganization",
				key: "partnerOrganization",
				source: "PARTNER_ORGANIZATION",
			},
		],
	},
};
