import { Schema } from "@griddo/core";

export const FORMAT: Schema.CategoryContentType = {
	dataPacks: ["PROGRAMS", "LECTURERS", "TESTIMONIAL"],
	title: "Format",
	local: false,
	translate: true,
	taxonomy: true,
	fromPage: false,
};
