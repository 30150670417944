import { MasonryCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import MasonryCard from "@schemas/components/MasonryCard";
import {
	anchorID,
	animation,
	heading,
	themeSelector,
	verticalSpacing,
	content,
	loremIpsumParagraph,
	subtitle,
	additional,
	getThemeOptions,
	link,
} from "@schemas/presets";

const schema: Schema.Module<MasonryCollectionProps> = {
	schemaType: "module",
	component: "MasonryCollection",
	category: "collection",
	displayName: "Masonry",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["MasonryCard"],
					contentType: "components",
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "MasonryCollection",
		additional: "Lorem Ipsum",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		elements: [
			{ ...MasonryCard.default },
			{ ...MasonryCard.default, size: "medium" },
			{ ...MasonryCard.default, size: "medium" },
			{ ...MasonryCard.default },
			{ ...MasonryCard.default, size: "medium" },
			{ ...MasonryCard.default, size: "large" },
			{ ...MasonryCard.default },
			{ ...MasonryCard.default },
			{ ...MasonryCard.default },
		],
		link: { component: "Link" },
		anchorID: null,
		layout: "layout01",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/MasonryCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/MasonryCollection/thumbnail@2x.png",
	},
};

export default schema;
