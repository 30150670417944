import { TextHeroCountdownProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Module<TextHeroCountdownProps> = {
	schemaType: "module",
	component: "TextHeroCountdown",
	displayName: "Text Hero Countdown",
	category: "featured",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					title: "Text",
					key: "text",
					mandatory: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					key: "counterDate",
					title: "Counter date",
					type: "DateField",
					mandatory: true,
				},
				{
					key: "counterHour",
					title: "Counter hour",
					type: "TimeField",
					mandatory: true,
				},
				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["SimpleLink"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "UniqueCheck",
					key: "sticky",
					title: "Keep sticky on scroll",
					options: [{ title: "Keep sticky on scroll" }],
					helptext: "It will only apply to desktop and big screen versions.",
				},
			],
		},
	],

	default: {
		component: "TextHeroCountdown",
		text: loremIpsumParagraph,
		link: { component: "SimpleLink" },
		anchorID: null,
		sticky: false,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/TextHeroCountdown/thumbnail@1x.png",
		"2x": "/thumbnails/modules/TextHeroCountdown/thumbnail@2x.png",
	},
};

export default schema;
