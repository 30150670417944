import { InnerCardCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicCard from "@schemas/components/BasicCard";
import { animation, linkModal } from "@schemas/presets";

const schema: Schema.Module<InnerCardCollectionProps> = {
	schemaType: "module",
	component: "InnerCardCollection",
	category: "collection",
	displayName: "Card Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					elementUniqueSelection: true,
					whiteList: ["BasicCard", "BoxedCard", "BackgroundCard", "IconCard"],
					contentType: "components",
					reference: "kind",
				},
				{ ...linkModal, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/CardCollection/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/CardCollection/Layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/CardCollection/Layouts/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/CardCollection/Layouts/layout4.png",
						},
					],
				},
				{
					title: "Card Style",
					key: "kind",
					type: "VisualUniqueSelection",
					elementUniqueSelection: true,
					columns: 3,
					options: [
						{
							value: "BasicCard",
							img: "/thumbnails/modules/CardCollection/CardStyle/basicCard.png",
						},
						{
							value: "BoxedCard",
							img: "/thumbnails/modules/CardCollection/CardStyle/boxedCard.png",
						},
						{
							value: "BackgroundCard",
							img: "/thumbnails/modules/CardCollection/CardStyle/backgroundCard.png",
						},
						{
							value: "IconCard",
							img: "/thumbnails/modules/CardCollection/CardStyle/iconCard.png",
						},
					],
					reference: "elements",
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "InnerCardCollection",
		elements: [
			{ ...BasicCard.default },
			{ ...BasicCard.default },
			{ ...BasicCard.default },
		],
		link: { component: "LinkModal" },
		layout: "layout2",
		kind: "BasicCard",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CardCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CardCollection/thumbnail@2x.png",
	},
};

export default schema;
