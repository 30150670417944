import { NewsDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import ImageGallery from "@schemas/modules/ImageGallery";
import NewsDistributor from "@schemas/modules/NewsDistributor";
import { loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Template<NewsDetailProps> = {
	schemaType: "template",
	component: "NewsDetail",
	displayName: "News",
	dataPacks: ["NEWS"],

	type: {
		label: "News",
		value: "news",
		mode: "detail",
	},

	content: [
		{
			type: "UniqueCheck",
			key: "breadcrumbsDesktop",
			title: "Breadcrumbs Desktop/Tablet",
			options: [{ title: "Display in tablet and desktop" }],
		},
		{
			type: "UniqueCheck",
			key: "breadcrumbs",
			title: "Breadcrumbs Mobile",
			options: [{ title: "Display in mobile" }],
		},
		{
			type: "DateField",
			title: "Date",
			key: "newsDate",
			hideable: true,
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "Abstract",
			key: "abstract",
			hideable: true,
			isMockup: true,
			placeholder: "Type an abstract text",
			humanReadable: true,
		},
		{
			type: "ComponentContainer",
			title: "Image",
			key: "contentImage",
			whiteList: ["Image"],
			helptext: "This image will be shown in the content",
			hideable: true,
		},
		{
			title: "Image Caption",
			type: "TextField",
			key: "imageCaption",
			hideable: true,
			humanReadable: true,
		},
		{
			type: "ImageField",
			title: "Image",
			key: "image",
			helptext: "Show a thumbnail in distributors and lists",
			mandatory: true,
		},
		{
			type: "MultiCheckSelectGroup",
			title: "Select Categories",
			key: "categories",
			filled: true,
			mandatory: true,
			elements: [
				{
					placeholder: "Centers",
					key: "centers",
					source: "CENTER",
				},
				{
					placeholder: "Schools",
					key: "schools",
					source: "SCHOOL",
				},
				{
					placeholder: "Services",
					key: "services",
					source: "SERVICE",
				},
				{
					placeholder: "Topics",
					key: "topics",
					source: "TOPIC",
				},
				{
					placeholder: "Type",
					key: "types",
					source: "NEWS_TYPE",
				},
			],
		},
		{
			type: "Wysiwyg",
			title: "Detail",
			key: "detail",
			mandatory: true,
			isMockup: true,
			placeholder: "Type a news detail",
			humanReadable: true,
		},
		{
			title: "Image Gallery",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["ImageGallery"],
			contentType: "modules",
			key: "gallery",
		},
		{
			type: "Wysiwyg",
			title: "Content",
			key: "content",
			hideable: true,
			placeholder: "Type a news content",
			humanReadable: true,
		},
		{
			type: "ComponentArray",
			title: "Video",
			key: "videoContent",
			whiteList: ["VideoPlaylist", "FullVideo"],
			contentType: "modules",
		},
		{
			title: "Social Media",
			type: "CheckGroup",
			key: "socialMedia",
			options: [
				{ name: "facebook", title: "Facebook" },
				{ name: "linkedin", title: "LinkedIn" },
				{ name: "twitter", title: "X (Twitter)" },
				{ name: "paperPlane", title: "Email" },
			],
			mandatory: true,
		},
		{
			type: "ReferenceField",
			title: "Related News",
			key: "data",
			sources: [{ structuredData: "NEWS" }],
			maxItems: 8,
			helptext: "Select up to 8 news articles.",
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"ChartCollection",
				"ContactInfo",
				"DownloadCollection",
				"IntroForm",
				"Principles",
				"TypographicVideo",
				"VideoGallery",
				"VideoPlaylist",
				"FullVideo",
			],
			contentType: "modules",
			mandatory: true,
		},
		{
			type: "TextField",
			title: "appCrue ID",
			key: "appcrueId",
			readonly: true,
		},
		{
			type: "TextField",
			key: "lastModified",
			title: "Last modified",
			computed: () => new Date().toJSON(),
			readonly: true,
		},
	],

	default: {
		type: "template",
		templateType: "NewsDetail",
		abstract: loremIpsumParagraph,
		contentImage: { component: "Image" },
		imageCaption: "",
		image: undefined,
		detail: loremIpsumParagraph,
		gallery: {
			component: "Section",
			name: "Gallery",
			modules: [{ ...ImageGallery.default }],
		},
		content: null,
		videoContent: {
			component: "Section",
			name: "Video",
			modules: [],
		},
		socialMedia: ["linkedin", "twitter", "facebook"],
		data: {
			mode: "auto",
			order: "newsDate-DESC",
			sources: [{ structuredData: "NEWS", globalOperator: "OR" }],
			quantity: 6,
			fullRelations: true,
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [{ ...NewsDistributor.default, subtheme: "inverse" }],
		},
		breadcrumbsDesktop: true,
		breadcrumbs: false,
	},

	thumbnails: {
		"1x": "/thumbnails/templates/NewsDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/NewsDetail/thumbnail@2x.png",
	},
};

export default schema;
