import { Schema } from "@griddo/core";

export const NEWS: Schema.PageContentType = {
	dataPacks: ["NEWS"],
	title: "News",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["NewsDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "DateField",
				title: "Date",
				from: "newsDate",
				key: "newsDate",
				indexable: true,
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Abstract",
				from: "abstract",
				key: "abstract",
			},
			{
				type: "ImageField",
				title: "Image",
				from: "image",
				key: "image",
				mandatory: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Centers",
				placeholder: "Centers",
				from: "categories.centers",
				key: "centers",
				source: "CENTER",
				mandatory: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Schools",
				placeholder: "Schools",
				from: "categories.schools",
				key: "schools",
				source: "SCHOOL",
				mandatory: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Services",
				placeholder: "Services",
				from: "categories.services",
				key: "services",
				source: "SERVICE",
				mandatory: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Topics",
				placeholder: "Topics",
				from: "categories.topics",
				key: "topics",
				source: "TOPIC",
				mandatory: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Type",
				placeholder: "Type",
				from: "categories.types",
				key: "types",
				source: "NEWS_TYPE",
				mandatory: true,
			},
		],
	},
};
