import { VerticalTabProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	content,
	loremIpsumParagraph,
	link,
	defaultLink,
} from "@schemas/presets";

const schema: Schema.Component<VerticalTabProps> = {
	schemaType: "component",
	component: "VerticalTab",
	displayName: "Vertical Tab",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					title: "Tab title",
					key: "title",
					placeholder: "Type a title",
					isMockup: true,
					mandatory: true,
					humanReadable: true,
				},

				{
					...heading,
					key: "contentTitle",
					title: "Content title",
					mandatory: false,
				},

				{
					...content,
					mandatory: true,
				},

				{
					...link,
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "VerticalTab",
		title: "Lorem ipsum",
		contentTitle: { content: "Lorem ipsum", tag: "h2" },
		content: loremIpsumParagraph,
		link: {
			...defaultLink,
			url: {
				...defaultLink.url,
				title: "Lorem Ipsum",
			},
			variant: "button4",
			text: "Find more",
		},
	},
};

export default schema;
