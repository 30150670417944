import { Schema } from "@griddo/core";

const schema: Schema.Header = {
	schemaType: "module",
	displayName: "Landing navigation",
	component: "LandingHeader",
	type: "header",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Landing menu name",
					key: "title",
					type: "TextField",
					placeholder: "Type a title",
					mandatory: true,
				},
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					title: "Set as Header default",
					options: [{ title: "Set as Header default" }],
				},
			],
		},
	],

	default: {
		component: "LandingHeader",
		type: "header",
		title: "New Header",
		setAsDefault: false,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/LandingHeader/thumbnail@1x.png",
		"2x": "/thumbnails/modules/LandingHeader/thumbnail@2x.png",
	},
};

export default schema;
