import { LandingProgramsDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	emptyState,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<LandingProgramsDistributorProps> = {
	schemaType: "module",
	component: "LandingProgramsDistributor",
	category: "landings",
	displayName: "Programs Distributor Landing",
	dataPacks: ["PROGRAMS"],
	sectionList: {
		ProgramLanding: ["mainSection"],
		Landing: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, isMockup: false },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{ ...emptyState },
				{
					title: "",
					type: "ReferenceField",
					sources: [
						{ structuredData: "PROGRAM" },
						{ structuredData: "PROGRAM_EXTERNAL" },
					],
					key: "data",
					mandatory: true,
					maxItems: 6,
				},
				{
					title: "Show Calls for registration",
					type: "RadioGroup",
					key: "registrations",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
				{
					title: "Show image",
					type: "RadioGroup",
					key: "image",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent-alt",
					]),
				},
				{ ...animation },
				{ ...verticalSpacing },
			],
		},
	],

	default: {
		component: "LandingProgramsDistributor",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h3",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		emptyState:
			"Parece que por el momento no hay programas... ¡Pero seguro que pronto los habrá!",
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [
				{
					structuredData: "PROGRAM",
					globalOperator: "AND",
					filterOperator: "OR",
				},
				{
					structuredData: "PROGRAM_EXTERNAL",
					globalOperator: "AND",
					filterOperator: "OR",
				},
			],
			quantity: 6,
			fullRelations: true,
		},
		registrations: true,
		image: false,
		anchorID: null,
		subtheme: "default",
		animation: "none",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProgramsDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProgramsDistributor/thumbnail@2x.png",
	},
};

export default schema;
