import { ChartProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Component<ChartProps> = {
	schemaType: "component",
	displayName: "Chart",
	component: "Chart",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, hideable: false },
				{
					title: "Chart Type",
					key: "kind",
					type: "VisualUniqueSelection",
					columns: 8,
					mandatory: true,
					options: [
						{
							value: "circle",
							img: "/thumbnails/components/Chart/Layouts/circle.png",
						},
						{
							value: "semicircle",
							img: "/thumbnails/components/Chart/Layouts/semicircle.png",
						},
						{
							value: "horizontal-bars",
							img: "/thumbnails/components/Chart/Layouts/horizontal-bars.png",
						},
						{
							value: "vertical-bars",
							img: "/thumbnails/components/Chart/Layouts/vertical-bars.png",
						},
						{
							value: "radial",
							img: "/thumbnails/components/Chart/Layouts/radial.png",
						},
					],
				},
				{
					title: "Unit",
					type: "TextField",
					key: "unit",
					mandatory: true,
				},
				{
					type: "ArrayFieldGroup",
					arrayType: "dropDown",
					title: "Data",
					key: "chartData",
					name: "Data",
					mandatory: true,
					fields: [
						{
							title: "Label",
							type: "TextField",
							key: "label",
							mandatory: true,
							isTitle: true,
							humanReadable: true,
						},
						{
							title: "Value",
							type: "NumberField",
							key: "value",
							mandatory: true,
						},
						{
							title: "Color",
							type: "ColorPicker",
							key: "color",
							mandatory: true,
							colors: [
								"#FFB81C",
								"#DB50FE",
								"#181818",
								"#707070",
								"#999",
								"#588200",
								"#A4123F",
								"#0E3E7A",
								"#7BA4DB",
								"#64B6AE",
							],
						},
					],
				},
				{
					title: "Alternative text",
					type: "TextField",
					key: "alt",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "Chart",
		title: { content: "Lorem ipsum", tag: "h3" },
		kind: "circle",
		unit: "%",
		chartData: [
			{
				label: "Item 1",
				value: 40,
				color: "#FFB81C",
			},
			{
				label: "Item 2",
				value: 40,
				color: "#DB50FE",
			},
			{
				label: "Item 3",
				value: 20,
				color: "#181818",
			},
		],
		alt: "Chart description",
	},

	thumbnails: {
		"1x": "/thumbnails/components/Chart/thumbnail@1x.png",
		"2x": "/thumbnails/components/Chart/thumbnail@2x.png",
	},
};

export default schema;
