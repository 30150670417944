import { Schema } from "@griddo/core";

export const PRESS_RELEASES: Schema.PageContentType = {
	dataPacks: ["PRESS_RELEASES"],
	title: "Press Releases",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["PressReleasesDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "DateField",
				title: "Date",
				from: "itemDate",
				key: "itemDate",
				mandatory: true,
				indexable: true,
			},
		],
	},
};
