import { Schema } from "@griddo/core";

const schema: Schema.Header = {
	schemaType: "module",
	displayName: "Header Onexed",
	component: "HeaderOnexed",
	type: "header",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Main menu name",
					key: "title",
					type: "TextField",
					placeholder: "Type a title",
					mandatory: true,
					isMockup: true,
				},
				{
					type: "RadioGroup",
					title: "Language selector",
					key: "languageSelector",
					hideable: true,
					options: [
						{ name: "yes", title: "Yes", value: true },
						{ name: "no", title: "No", value: false },
					],
				},
				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["LinkModal"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					title: "Set as Headerdefault",
					options: [{ title: "Set as Header default" }],
				},
				{
					title: "Select Main menu",
					key: "menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
					helptext: "Select the site menu. It must first be created.",
				},
				{
					title: "Select Top menu",
					key: "topMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
					helptext: "Select the site menu. It must first be created.",
				},
				{
					type: "UniqueCheck",
					key: "sticky",
					title: "Keep sticky on scroll",
					options: [{ title: "Keep sticky on scroll" }],
				},
			],
		},
	],

	default: {
		component: "HeaderOnexed",
		type: "header",
		title: "New Header",
		languageSelector: true,
		link: { component: "LinkModal" },
		setAsDefault: false,
		menu: undefined,
		topMenu: undefined,
		sticky: true,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeaderOnexed/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeaderOnexed/thumbnail@2x.png",
	},
};

export default schema;
