import { AddressProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<AddressProps> = {
	schemaType: "component",
	component: "Address",
	displayName: "Address",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Address",
					key: "value",
					type: "TextField",
					mandatory: true,
					placeholder: "Type an address",
				},
			],
		},
	],

	default: {
		component: "Address",
		value: "",
		icon: "location",
	},
};

export default schema;
