import { ChannelsListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicHeroTV from "@schemas/modules/BasicHeroTV";

const schema: Schema.Template<ChannelsListProps> = {
	dataPacks: ["VIDEOS"],
	schemaType: "template",
	displayName: "Channels List",
	component: "ChannelsList",
	type: { label: "Channels", value: "channels" },

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["BasicHeroTV"],
			contentType: "modules",
			key: "heroSection",
			mandatory: true,
		},
		{
			type: "CheckGroup",
			title: "Select template options",
			key: "templateOptions",
			options: [
				{
					title: "Search feature",
					name: "search",
				},
				{
					title: "Sort feature",
					name: "sort",
				},
			],
		},
		{
			type: "TextField",
			title: "No Results text",
			key: "noResultsText",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "ReferenceField",
			title: "",
			key: "data",
			sources: [{ structuredData: "CHANNEL" }],
			mandatory: true,
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			min: 6,
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "ChannelsList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [{ ...BasicHeroTV.default, breadcrumbsDesktop: false }],
		},
		noResultsText:
			"Sorry, no results were found. Please try a different search.",
		itemsPerPage: 8,
		activePage: 1,
		data: {
			mode: "auto",
			order: "membersCount-DESC",
			sources: [{ structuredData: "CHANNEL" }],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ChannelsList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ChannelsList/thumbnail@2x.png",
	},
};

export default schema;
