import { HighlightedCardCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import HighlightedImageCard from "@schemas/components/HighlightedImageCard";
import HighlightedTextCard from "@schemas/components/HighlightedTextCard";
import {
	anchorID,
	animation,
	defaultLink,
	getThemeOptions,
	heading,
	link,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<HighlightedCardCollectionProps> = {
	schemaType: "module",
	component: "HighlightedCardCollection",
	category: "collection",
	displayName: "Highlighted Card Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false },
				{ ...subtitle },
				{ ...link, hideable: true },
				{
					title: "Card type",
					type: "ConditionalField",
					key: "cardType",
					mandatory: true,
					options: [
						{
							name: "text",
							value: "text",
							title: "Text Card",
						},
						{
							name: "image",
							value: "image",
							title: "Image Card",
						},
					],
					fields: [
						{
							condition: "text",
							title: "Card disposition",
							type: "RadioGroup",
							key: "disposition",
							mandatory: true,
							options: [
								{
									name: "horizontal",
									value: "horizontal",
									title: "Horizontal",
								},
								{
									name: "vertical",
									value: "vertical",
									title: "Vertical",
								},
							],
						},
						{
							condition: "text",
							type: "ComponentArray",
							title: "Cards",
							key: "textCards",
							whiteList: ["HighlightedTextCard"],
							contentType: "components",
							maxItems: 3,
							mandatory: true,
						},
						{
							condition: "image",
							type: "ComponentArray",
							title: "Cards",
							key: "imageCards",
							whiteList: ["HighlightedImageCard"],
							contentType: "components",
							maxItems: 2,
							mandatory: true,
						},
					],
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt", "inverse"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "HighlightedCardCollection",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: "Lorem ipsum subtitle",
		link: {
			...defaultLink,
			text: "Ver más",
			variant: "button3",
		},
		cardType: "text",
		disposition: "horizontal",
		textCards: [
			{ ...HighlightedTextCard.default },
			{ ...HighlightedTextCard.default },
			{ ...HighlightedTextCard.default },
		],
		imageCards: [
			{ ...HighlightedImageCard.default },
			{ ...HighlightedImageCard.default },
		],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HighlightedCardCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HighlightedCardCollection/thumbnail@2x.png",
	},
};

export default schema;
