import { Schema } from "@griddo/core";

export const DOCUMENT: Schema.SimpleContentType = {
	dataPacks: ["PROGRAMS", "LECTURERS", "TESTIMONIAL"],
	title: "Brochure",
	local: false,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				type: "TextField",
				placeholder: "Type a title",
				helptext:
					"Name the brochure in order to easily identify it when associated to a program",
				mandatory: true,
			},
			{
				key: "document",
				title: "Brochure",
				type: "FileField",
				placeholder: "Upload a program brochure",
				mandatory: true,
			},
		],
	},
};
