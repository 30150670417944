import { MilestoneSlideProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	content,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.Component<MilestoneSlideProps> = {
	schemaType: "component",
	component: "MilestoneSlide",
	displayName: "Milestone Slide",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "milestoneTitle",
					title: "Milestone title",
					hideable: true,
					placeholder: "Type a milestone title",
					helptext: "Recommended to write a year",
				},
				{
					...heading,
					title: "Content title",
					mandatory: false,
					isMockup: true,
				},
				{ ...content, title: "Content body" },
				{
					type: "ComponentContainer",
					title: "Media",
					key: "media",
					whiteList: ["Image", "Video"],
					hideable: true,
					helptext:
						"Recommended minimum image size: width 950px. We recommend that the video does not weigh more than 300kb",
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/components/MilestoneSlide/Layouts/layout001.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/components/MilestoneSlide/Layouts/layout002.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"inverse-alt",
					]),
				},
			],
		},
	],

	default: {
		component: "MilestoneSlide",
		milestoneTitle: "1908",
		title: {
			content: "Lorem ipsum",
			tag: "h3",
		},
		content: loremIpsumParagraph,
		media: {
			image: { component: "Image" },
			video: { component: "Video" },
		},
		layout: "layout01",
		subtheme: "default",
	},
};

export default schema;
