//
// Griddo Modules
//
// Every Module and their types must be imported and reexported.
// Yes, a barrel index :)

import { Component } from "@griddo/core";
import * as React from "react";

// Types
import type { AccordionCollectionProps } from "./AccordionCollection";
import type { AgreementsMapProps } from "./AgreementsMap";
import type { AnimatedImageGalleryProps } from "./AnimatedImageGallery";
import type { BasicContentProps } from "./BasicContent";
import type { BasicHeroBrandSiteProps } from "./BasicHeroBrandSite";
import type { BasicHeroTVProps } from "./BasicHeroTV";
import type { BasicTextProps } from "./BasicText";
import type { CardCollectionProps } from "./CardCollection";
import type { CardsCarouselProps } from "./CardsCarousel";
import type { ChartCollectionProps } from "./ChartCollection";
import type { ColorCollectionProps } from "./ColorCollection";
import type { ContactInfoProps } from "./ContactInfo";
import type { CountdownProps } from "./Countdown";
import type { CypherCollectionProps } from "./CypherCollection";
import type { DownloadableIconCollectionProps } from "./DownloadableIconCollection";
import type { DownloadableLogoCollectionProps } from "./DownloadableLogoCollection";
import type { DownloadCollectionProps } from "./DownloadCollection";
import type { EventsDistributorProps } from "./EventsDistributor";
import type { FAQCollectionProps } from "./FAQCollection";
import type { FeaturedAudiovisualProps } from "./FeaturedAudiovisual";
import type { FeaturedBlockProps } from "./FeaturedBlock";
import type { FeaturedContentProps } from "./FeaturedContent";
import type { FeaturedFactsProps } from "./FeaturedFacts";
import type { FooterProps } from "./Footer";
import type { FooterLandingProps } from "./FooterLanding";
import type { FullVideoProps } from "./FullVideo";
import type { HeaderProps } from "./Header";
import type { HeaderBrandProps } from "./HeaderBrand";
import type { HeaderOnexedProps } from "./HeaderOnexed";
import type { HeaderTVProps } from "./HeaderTV";
import type { HeroCarouselProps } from "./HeroCarousel";
import type { HeroInnerProps } from "./HeroInner";
import type { HeroLandingProps } from "./HeroLanding";
import type { HeroSideProps } from "./HeroSide";
import type { HeroVideoProps } from "./HeroVideo";
import type { HighlightedCardCollectionProps } from "./HighlightedCardCollection";
import type { HighlightedContentProps } from "./HighlightedContent";
import type { HighlightedHeaderProps } from "./HighlightedHeader";
import type { HorizontalTabsProps } from "./HorizontalTabs";
import type { ImageBannerProps } from "./ImageBanner";
import type { ImageGalleryProps } from "./ImageGallery";
import type { InnerBasicContentProps } from "./InnerBasicContent";
import type { InnerBasicTextProps } from "./InnerBasicText";
import type { InnerCardCollectionProps } from "./InnerCardCollection";
import type { InnerColorCollectionProps } from "./InnerColorCollection";
import type { InnerDownloadableIconCollectionProps } from "./InnerDownloadableIconCollection";
import type { InnerDownloadableLogoCollectionProps } from "./InnerDownloadableLogoCollection";
import type { InnerFeaturedAudiovisualProps } from "./InnerFeaturedAudiovisual";
import type { InnerFullVideoProps } from "./InnerFullVideo";
import type { InnerLogoThemerProps } from "./InnerLogoThemer";
import type { InnerPeopleDistributorProps } from "./InnerPeopleDistributor";
import type { InnerProgramsDistributorProps } from "./InnerProgramsDistributor";
import type { InnerPublicationsDistributorProps } from "./InnerPublicationsDistributor";
import type { InnerStepCollectionProps } from "./InnerStepCollection";
import type { InnerTypographyPreviewerProps } from "./InnerTypographyPreviewer";
import type { InnerVerticalTabsProps } from "./InnerVerticalTabs";
import type { InnerWidgetProps } from "./InnerWidget";
import type { IntroFormProps } from "./IntroForm";
import type { LandingHeaderProps } from "./LandingHeader";
import type { LandingProgramsDistributorProps } from "./LandingProgramsDistributor";
import type { LandingQuickFactsProps } from "./LandingQuickFacts";
import type { LogoCollectionProps } from "./LogoCollection";
import type { LogoThemerProps } from "./LogoThemer";
import type { MainHeroProps } from "./MainHero";
import type { MainHeroTvProps } from "./MainHeroTv";
import type { MasonryCollectionProps } from "./MasonryCollection";
import type { MilestonesProps } from "./Milestones";
import type { ModalFormProps } from "./ModalForm";
import type { NewsDistributorProps } from "./NewsDistributor";
import type { NewsPinnedDistributorProps } from "./NewsPinnedDistributor";
import type { PeopleDistributorProps } from "./PeopleDistributor";
import type { PrinciplesProps } from "./Principles";
import type { ProgramIntroFormProps } from "./ProgramIntroForm";
import type { ProgramNavigationProps } from "./ProgramNavigation";
import type { ProgramsDistributorProps } from "./ProgramsDistributor";
import type { ProjectsDistributorProps } from "./ProjectsDistributor";
import type { PublicationsDistributorProps } from "./PublicationsDistributor";
import type { QuoteProps } from "./Quote";
import type { SocialShareProps } from "./SocialShare";
import type { SpacerProps } from "./Spacer";
import type { StepCollectionProps } from "./StepCollection";
import type { StickyTabsProps } from "./StickyTabs";
import type { StudyPlanProps } from "./StudyPlan";
import type { StudyPlanAutoProps } from "./StudyPlanAuto";
import type { TableProps } from "./Table";
import type { TestimonialDistributorProps } from "./TestimonialDistributor";
import type { TextCarouselProps } from "./TextCarousel";
import type { TextHeroBannerProps } from "./TextHeroBanner";
import type { TextHeroCountdownProps } from "./TextHeroCountdown";
import type { TypographicVideoProps } from "./TypographicVideo";
import type { TypographyPreviewerProps } from "./TypographyPreviewer";
import type { VerticalTabsProps } from "./VerticalTabs";
import type { VideoDistributorProps } from "./VideoDistributor";
import type { VideoGalleryProps } from "./VideoGallery";
import type { VideoPlaylistProps } from "./VideoPlaylist";
import type { WidgetProps } from "./Widget";
import type { WysiwygProps } from "./Wysiwyg";

// Modules
const AccordionCollection = React.lazy(() => import("./AccordionCollection"));
const AgreementsMap = React.lazy(() => import("./AgreementsMap"));
const AnimatedImageGallery = React.lazy(() => import("./AnimatedImageGallery"));
const BasicContent = React.lazy(() => import("./BasicContent"));
const BasicHeroBrandSite = React.lazy(() => import("./BasicHeroBrandSite"));
const BasicHeroTV = React.lazy(() => import("./BasicHeroTV"));
const BasicText = React.lazy(() => import("./BasicText"));
const CardCollection = React.lazy(() => import("./CardCollection"));
const CardsCarousel = React.lazy(() => import("./CardsCarousel"));
const ChartCollection = React.lazy(() => import("./ChartCollection"));
const ColorCollection = React.lazy(() => import("./ColorCollection"));
const ContactInfo = React.lazy(() => import("./ContactInfo"));
const Countdown = React.lazy(() => import("./Countdown"));
const CypherCollection = React.lazy(() => import("./CypherCollection"));
const DownloadableIconCollection = React.lazy(
	() => import("./DownloadableIconCollection")
);
const DownloadableLogoCollection = React.lazy(
	() => import("./DownloadableLogoCollection")
);
const DownloadCollection = React.lazy(() => import("./DownloadCollection"));
const EventsDistributor = React.lazy(() => import("./EventsDistributor"));
const FAQCollection = React.lazy(() => import("./FAQCollection"));
const FeaturedAudiovisual = React.lazy(() => import("./FeaturedAudiovisual"));
const FeaturedBlock = React.lazy(() => import("./FeaturedBlock"));
const FeaturedContent = React.lazy(() => import("./FeaturedContent"));
const FeaturedFacts = React.lazy(() => import("./FeaturedFacts"));
const Footer = React.lazy(() => import("./Footer"));
const FooterLanding = React.lazy(() => import("./FooterLanding"));
const FullVideo = React.lazy(() => import("./FullVideo"));
const Header = React.lazy(() => import("./Header"));
const HeaderBrand = React.lazy(() => import("./HeaderBrand"));
const HeaderOnexed = React.lazy(() => import("./HeaderOnexed"));
const HeaderTV = React.lazy(() => import("./HeaderTV"));
const HeroCarousel = React.lazy(() => import("./HeroCarousel"));
const HeroInner = React.lazy(() => import("./HeroInner"));
const HeroLanding = React.lazy(() => import("./HeroLanding"));
const HeroSide = React.lazy(() => import("./HeroSide"));
const HeroVideo = React.lazy(() => import("./HeroVideo"));
const HighlightedContent = React.lazy(() => import("./HighlightedContent"));
const HighlightedHeader = React.lazy(() => import("./HighlightedHeader"));
const HighlightedCardCollection = React.lazy(
	() => import("./HighlightedCardCollection")
);
const HorizontalTabs = React.lazy(() => import("./HorizontalTabs"));
const ImageBanner = React.lazy(() => import("./ImageBanner"));
const ImageGallery = React.lazy(() => import("./ImageGallery"));
const IntroForm = React.lazy(() => import("./IntroForm"));
const LandingHeader = React.lazy(() => import("./LandingHeader"));
const LandingProgramsDistributor = React.lazy(
	() => import("./LandingProgramsDistributor")
);
const LandingQuickFacts = React.lazy(() => import("./LandingQuickFacts"));
const LogoCollection = React.lazy(() => import("./LogoCollection"));
const LogoThemer = React.lazy(() => import("./LogoThemer"));
const InnerBasicText = React.lazy(() => import("./InnerBasicText"));
const InnerBasicContent = React.lazy(() => import("./InnerBasicContent"));
const InnerCardCollection = React.lazy(() => import("./InnerCardCollection"));
const InnerColorCollection = React.lazy(() => import("./InnerColorCollection"));
const InnerDownloadableIconCollection = React.lazy(
	() => import("./InnerDownloadableIconCollection")
);
const InnerDownloadableLogoCollection = React.lazy(
	() => import("./InnerDownloadableLogoCollection")
);
const InnerLogoThemer = React.lazy(() => import("./InnerLogoThemer"));
const InnerTypographyPreviewer = React.lazy(
	() => import("./InnerTypographyPreviewer")
);
const InnerWidget = React.lazy(() => import("./InnerWidget"));
const InnerPublicationsDistributor = React.lazy(
	() => import("./InnerPublicationsDistributor")
);
const InnerStepCollection = React.lazy(() => import("./InnerStepCollection"));
const InnerProgramsDistributor = React.lazy(
	() => import("./InnerProgramsDistributor")
);
const InnerVerticalTabs = React.lazy(() => import("./InnerVerticalTabs"));
const InnerFeaturedAudiovisual = React.lazy(
	() => import("./InnerFeaturedAudiovisual")
);
const InnerPeopleDistributor = React.lazy(
	() => import("./InnerPeopleDistributor")
);
const InnerFullVideo = React.lazy(() => import("./InnerFullVideo"));
const Quote = React.lazy(() => import("./Quote"));
const MainHero = React.lazy(() => import("./MainHero"));
const MainHeroTv = React.lazy(() => import("./MainHeroTv"));
const MasonryCollection = React.lazy(() => import("./MasonryCollection"));
const Milestones = React.lazy(() => import("./Milestones"));
const ModalForm = React.lazy(() => import("./ModalForm"));
const NewsDistributor = React.lazy(() => import("./NewsDistributor"));
const NewsPinnedDistributor = React.lazy(
	() => import("./NewsPinnedDistributor")
);
const PeopleDistributor = React.lazy(() => import("./PeopleDistributor"));
const Principles = React.lazy(() => import("./Principles"));
const ProgramIntroForm = React.lazy(() => import("./ProgramIntroForm"));
const ProgramNavigation = React.lazy(() => import("./ProgramNavigation"));
const ProgramsDistributor = React.lazy(() => import("./ProgramsDistributor"));
const ProjectsDistributor = React.lazy(() => import("./ProjectsDistributor"));
const PublicationsDistributor = React.lazy(
	() => import("./PublicationsDistributor")
);
const SocialShare = React.lazy(() => import("./SocialShare"));
const Spacer = React.lazy(() => import("./Spacer"));
const StepCollection = React.lazy(() => import("./StepCollection"));
const StickyTabs = React.lazy(() => import("./StickyTabs"));
const StudyPlan = React.lazy(() => import("./StudyPlan"));
const StudyPlanAuto = React.lazy(() => import("./StudyPlanAuto"));
const Table = React.lazy(() => import("./Table"));
const TestimonialDistributor = React.lazy(
	() => import("./TestimonialDistributor")
);
const VerticalTabs = React.lazy(() => import("./VerticalTabs"));
const TextCarousel = React.lazy(() => import("./TextCarousel"));
const TextHeroBanner = React.lazy(() => import("./TextHeroBanner"));
const TextHeroCountdown = React.lazy(() => import("./TextHeroCountdown"));
const TypographicVideo = React.lazy(() => import("./TypographicVideo"));
const TypographyPreviewer = React.lazy(() => import("./TypographyPreviewer"));
const VideoDistributor = React.lazy(() => import("./VideoDistributor"));
const VideoGallery = React.lazy(() => import("./VideoGallery"));
const VideoPlaylist = React.lazy(() => import("./VideoPlaylist"));
const Widget = React.lazy(() => import("./Widget"));
const Wysiwyg = React.lazy(() => import("./Wysiwyg"));

// Modules for <GriddoModule>
const modules = {
	AccordionCollection,
	AgreementsMap,
	AnimatedImageGallery,
	BasicContent,
	BasicHeroBrandSite,
	BasicHeroTV,
	BasicText,
	CardCollection,
	CardsCarousel,
	ChartCollection,
	ColorCollection,
	ContactInfo,
	Countdown,
	CypherCollection,
	DownloadableIconCollection,
	DownloadableLogoCollection,
	EventsDistributor,
	DownloadCollection,
	FAQCollection,
	FeaturedAudiovisual,
	FeaturedBlock,
	FeaturedContent,
	FeaturedFacts,
	Footer,
	FooterLanding,
	FullVideo,
	Header,
	HeaderBrand,
	HeaderOnexed,
	HeaderTV,
	HeroCarousel,
	HeroInner,
	HeroLanding,
	HeroSide,
	HeroVideo,
	HighlightedContent,
	HighlightedHeader,
	HighlightedCardCollection,
	HorizontalTabs,
	ImageBanner,
	ImageGallery,
	IntroForm,
	LandingHeader,
	LandingProgramsDistributor,
	LandingQuickFacts,
	PeopleDistributor,
	Principles,
	LogoCollection,
	LogoThemer,
	InnerBasicText,
	InnerBasicContent,
	InnerCardCollection,
	InnerColorCollection,
	InnerDownloadableIconCollection,
	InnerDownloadableLogoCollection,
	InnerLogoThemer,
	InnerTypographyPreviewer,
	InnerWidget,
	InnerPublicationsDistributor,
	InnerStepCollection,
	InnerProgramsDistributor,
	InnerVerticalTabs,
	InnerFeaturedAudiovisual,
	InnerPeopleDistributor,
	InnerFullVideo,
	Quote,
	MainHero,
	MainHeroTv,
	MasonryCollection,
	Milestones,
	ModalForm,
	NewsDistributor,
	NewsPinnedDistributor,
	ProgramIntroForm,
	ProgramNavigation,
	ProgramsDistributor,
	ProjectsDistributor,
	PublicationsDistributor,
	SocialShare,
	Spacer,
	StepCollection,
	StickyTabs,
	StudyPlan,
	StudyPlanAuto,
	Table,
	TestimonialDistributor,
	VerticalTabs,
	TextCarousel,
	TextHeroBanner,
	TextHeroCountdown,
	TypographicVideo,
	TypographyPreviewer,
	VideoDistributor,
	VideoGallery,
	VideoPlaylist,
	Widget,
	Wysiwyg,
};

// Types for <GriddoModule>
export type ModulesProps =
	| AccordionCollectionProps
	| AgreementsMapProps
	| AnimatedImageGalleryProps
	| BasicContentProps
	| BasicHeroBrandSiteProps
	| BasicHeroTVProps
	| BasicTextProps
	| CardCollectionProps
	| CardsCarouselProps
	| ChartCollectionProps
	| CypherCollectionProps
	| DownloadableIconCollectionProps
	| DownloadableLogoCollectionProps
	| DownloadCollectionProps
	| EventsDistributorProps
	| FAQCollectionProps
	| FeaturedAudiovisualProps
	| FeaturedBlockProps
	| FeaturedContentProps
	| FeaturedFactsProps
	| FooterProps
	| FooterLandingProps
	| FullVideoProps
	| HeaderProps
	| HeaderBrandProps
	| HeaderOnexedProps
	| HeaderTVProps
	| ColorCollectionProps
	| ContactInfoProps
	| CountdownProps
	| HeroCarouselProps
	| HeroInnerProps
	| HeroLandingProps
	| HeroSideProps
	| HeroVideoProps
	| HighlightedContentProps
	| HighlightedHeaderProps
	| HighlightedCardCollectionProps
	| HorizontalTabsProps
	| ImageBannerProps
	| ImageGalleryProps
	| IntroFormProps
	| LandingHeaderProps
	| LandingProgramsDistributorProps
	| LandingQuickFactsProps
	| PeopleDistributorProps
	| PrinciplesProps
	| LogoCollectionProps
	| LogoThemerProps
	| InnerBasicTextProps
	| InnerBasicContentProps
	| InnerCardCollectionProps
	| InnerColorCollectionProps
	| InnerDownloadableIconCollectionProps
	| InnerDownloadableLogoCollectionProps
	| InnerWidgetProps
	| InnerPublicationsDistributorProps
	| InnerStepCollectionProps
	| InnerTypographyPreviewerProps
	| InnerProgramsDistributorProps
	| InnerVerticalTabsProps
	| InnerFeaturedAudiovisualProps
	| InnerPeopleDistributorProps
	| InnerFullVideoProps
	| InnerLogoThemerProps
	| QuoteProps
	| MainHeroProps
	| MainHeroTvProps
	| MasonryCollectionProps
	| MilestonesProps
	| ModalFormProps
	| NewsDistributorProps
	| NewsPinnedDistributorProps
	| ProgramIntroFormProps
	| ProgramNavigationProps
	| ProgramsDistributorProps
	| ProjectsDistributorProps
	| PublicationsDistributorProps
	| SocialShareProps
	| SpacerProps
	| StepCollectionProps
	| StickyTabsProps
	| StudyPlanProps
	| StudyPlanAutoProps
	| TableProps
	| TestimonialDistributorProps
	| TextCarouselProps
	| TextHeroBannerProps
	| TextHeroCountdownProps
	| TypographicVideoProps
	| TypographyPreviewerProps
	| VerticalTabsProps
	| VideoDistributorProps
	| VideoGalleryProps
	| VideoPlaylistProps
	| WidgetProps
	| WysiwygProps;

// Module named export
export {
	AccordionCollection,
	AgreementsMap,
	AnimatedImageGallery,
	BasicContent,
	BasicHeroBrandSite,
	BasicHeroTV,
	BasicText,
	CardCollection,
	CardsCarousel,
	ChartCollection,
	ColorCollection,
	ContactInfo,
	Countdown,
	CypherCollection,
	DownloadableIconCollection,
	DownloadableLogoCollection,
	DownloadCollection,
	EventsDistributor,
	FAQCollection,
	FeaturedAudiovisual,
	FeaturedContent,
	FeaturedFacts,
	Footer,
	FooterLanding,
	FullVideo,
	Header,
	HeaderBrand,
	HeaderOnexed,
	HeaderTV,
	HeroCarousel,
	HeroInner,
	HeroLanding,
	HeroSide,
	HeroVideo,
	HighlightedContent,
	HighlightedHeader,
	HighlightedCardCollection,
	HorizontalTabs,
	ImageBanner,
	ImageGallery,
	IntroForm,
	LandingHeader,
	LandingProgramsDistributor,
	LandingQuickFacts,
	InnerBasicText,
	InnerBasicContent,
	InnerCardCollection,
	InnerColorCollection,
	InnerDownloadableIconCollection,
	InnerDownloadableLogoCollection,
	InnerLogoThemer,
	InnerTypographyPreviewer,
	InnerWidget,
	InnerPublicationsDistributor,
	InnerStepCollection,
	InnerProgramsDistributor,
	InnerFeaturedAudiovisual,
	InnerVerticalTabs,
	InnerPeopleDistributor,
	InnerFullVideo,
	PeopleDistributor,
	Principles,
	LogoCollection,
	LogoThemer,
	Quote,
	MainHero,
	MainHeroTv,
	MasonryCollection,
	Milestones,
	ModalForm,
	NewsDistributor,
	NewsPinnedDistributor,
	ProgramIntroForm,
	ProgramNavigation,
	ProgramsDistributor,
	ProjectsDistributor,
	PublicationsDistributor,
	SocialShare,
	Spacer,
	StepCollection,
	StickyTabs,
	StudyPlan,
	StudyPlanAuto,
	Table,
	TestimonialDistributor,
	TextCarousel,
	TextHeroBanner,
	TextHeroCountdown,
	TypographicVideo,
	TypographyPreviewer,
	VideoDistributor,
	VideoGallery,
	VideoPlaylist,
	Widget,
	Wysiwyg,
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------- You shall not pass! ----------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------------------------------------------- Gandalf ----
// -----------------------------------------------------------------------------

// Griddo bundle export
export default modules;

/**
 * `<GriddoModule>`
 *
 * Render modules with their Types dinamically and wrapped them with Griddo
 * functionalities.
 *
 * @example
 * <GriddoModule component="BasicModule" {...basicModuleProps} />
 *
 * Check out the [documentation](https://griddoio.notion.site/GriddoComponent-y-GriddoModule-en-la-instancia-bad16303bb4b49e0b696b62d1f6c40e9)
 */
export const GriddoModule = (props: ModulesProps) => (
	<Component libComponents={modules} {...props} />
);
