import { ColorCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading, subtitle } from "@schemas/presets";

const schema: Schema.Component<ColorCardProps> = {
	schemaType: "component",
	component: "ColorCard",
	displayName: "Color Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Color",
					type: "ColorPicker",
					key: "color",
					colors: [],
					mandatory: true,
				},
				{ ...heading, isMockup: true, mandatory: false },
				{ ...subtitle },
				{
					title: "Hexadecimal code",
					type: "TextField",
					key: "hexadecimal",
					hideable: true,
				},
				{
					title: "RGB code",
					type: "TextField",
					key: "rgb",
					hideable: true,
				},
				{
					title: "CMYK code",
					type: "TextField",
					key: "cmyk",
					hideable: true,
				},
				{
					title: "PANTONE",
					type: "TextField",
					key: "pantone",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "ColorCard",
		color: "#000000",
		title: { content: "Lorem ipsum", tag: "h4" },
		subtitle: null,
		hexadecimal: "#000000",
		rgb: "(36,28,21)",
		cmyk: "C0 M31 Y98 K0",
		pantone: null,
	},
};

export default schema;
