import { LinkModalProps } from "@autoTypes";
import { UIFields, Fields } from "@griddo/core";

export const link: UIFields.ComponentContainer = {
	type: "ComponentContainer",
	title: "Link",
	key: "link",
	whiteList: ["Link"],
};

export const linkModal: UIFields.ComponentContainer = {
	type: "ComponentContainer",
	title: "Link",
	key: "link",
	whiteList: ["LinkModal"],
};

type DefaultLinkValues = {
	component: "Link";
	url: Fields.Url;
};

export const defaultLink: DefaultLinkValues = {
	component: "Link",
	url: {
		href: undefined,
		linkToURL: undefined,
		newTab: false,
		noFollow: false,
	},
};

export const getLinkModal = (
	text: string,
	variant = "button2",
	linkType: "url" | "modal" | undefined = "url"
) => {
	return {
		component: "LinkModal",
		link: {
			text,
			linkType,
			modal: {
				modalForm: { component: "ModalForm" },
				innerWidget: { component: "InnerWidget" },
			},
		},
		variant,
	} as LinkModalProps;
};
