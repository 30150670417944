import { HighlightedTextCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { content, heading, loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Component<HighlightedTextCardProps> = {
	schemaType: "component",
	component: "HighlightedTextCard",
	displayName: "Highlighted Text Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false, isMockup: true },
				{ ...content },
			],
		},
	],

	default: {
		component: "HighlightedTextCard",
		title: { content: "Lorem ipsum", tag: "h4" },
		content: loremIpsumParagraph,
	},
};

export default schema;
