import { TextHeroBannerProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, link, loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Module<TextHeroBannerProps> = {
	schemaType: "module",
	component: "TextHeroBanner",
	displayName: "Text Hero Banner",
	category: "featured",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "NoteField",
					key: "note",
					title: "",
					helptext: "",
					value: {
						title: "Important",
						text: "This module will only be displayed on the home page",
					},
				},
				{
					type: "TextField",
					title: "Text",
					key: "text",
					mandatory: true,
					humanReadable: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "UniqueCheck",
					key: "sticky",
					title: "Keep sticky on scroll",
					options: [{ title: "Keep sticky on scroll" }],
				},
			],
		},
	],

	default: {
		component: "TextHeroBanner",
		text: loremIpsumParagraph,
		link: { component: "Link" },
		anchorID: null,
		sticky: false,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/TextHeroBanner/thumbnail@1x.png",
		"2x": "/thumbnails/modules/TextHeroBanner/thumbnail@2x.png",
	},
};

export default schema;
