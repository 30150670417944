import { LogoThemeCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Component<LogoThemeCardProps> = {
	schemaType: "component",
	component: "LogoThemeCard",
	displayName: "Logo Theme Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Bg Color",
					type: "ColorPicker",
					key: "bgColor",
					colors: [
						"#F6F5EE",
						"#FFFFFF",
						"#181818",
						"#000000",
						"#FFB81C",
						"#7BA4DB",
						"#A4123F",
						"#64B6AE",
						"#0E3E7A",
					],
					mandatory: true,
				},
				{
					title: "Logo type",
					type: "ConditionalField",
					key: "type",
					mandatory: true,
					options: [
						{
							name: "default",
							value: "default",
							title: "Default (project logos)",
						},

						{
							name: "custom",
							value: "custom",
							title: "Custom (upload)",
						},
					],
					fields: [
						{
							condition: "default",
							title: "Select logo",
							type: "Select",
							key: "logo",
							placeholder: "Select an logo",
							options: [
								{ value: "logoComillasHorizontalBn", label: "Comillas" },
								{
									value: "logoComillasHorizontal",
									label: "Comillas color",
								},
								{
									value: "logoComillasHorizontalInverse",
									label: "Comillas inverse",
								},
								{ value: "logoCihsHorizontalBn", label: "CIHS" },
								{ value: "logoCihsHorizontal", label: "CIHS color" },
								{
									value: "logoCihsHorizontalInverse",
									label: "CIHS inverse",
								},
								{ value: "logoEuefHorizontalBn", label: "EUEF" },
								{ value: "logoEuefHorizontal", label: "EUEF color" },
								{
									value: "logoEuefHorizontalInverse",
									label: "EUEF inverse",
								},
								{ value: "logoIcadeHorizontalBn", label: "ICADE " },
								{ value: "logoIcadeHorizontal", label: "ICADE color" },
								{
									value: "logoIcadeHorizontalInverse",
									label: "ICADE inverse",
								},
								{ value: "logoIcaiHorizontalBn", label: "ICAI" },
								{ value: "logoIcaiHorizontal", label: "ICAI color" },
								{
									value: "logoIcaiHorizontalInverse",
									label: "ICAI inverse",
								},
							],
						},
						{
							condition: "custom",
							title: "Logo",
							type: "ImageField",
							key: "logoImg",
						},
					],
				},
				{ ...heading, isMockup: true, mandatory: false },
				{
					title: "Hexadecimal code",
					type: "TextField",
					key: "hexadecimal",
					hideable: true,
				},
				{
					title: "RGB code",
					type: "TextField",
					key: "rgb",
					hideable: true,
				},
				{
					title: "CMYK code",
					type: "TextField",
					key: "cmyk",
					hideable: true,
				},
				{
					title: "PANTONE",
					type: "TextField",
					key: "pantone",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "LogoThemeCard",
		bgColor: "#000000",
		type: "default",
		logo: "logoComillasHorizontalInverse",
		title: { content: "Lorem ipsum", tag: "h4" },
		hexadecimal: "#000000",
		rgb: "(36,28,21)",
		cmyk: "C0 M31 Y98 K0",
		pantone: null,
	},
};

export default schema;
