import { GenericFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	detail,
	themeSelector,
	getThemeOptions,
	loremIpsumParagraph,
} from "@schemas/presets";

const schema: Schema.Component<GenericFormProps> = {
	schemaType: "component",
	displayName: "Generic Form (Mailbox)",
	component: "GenericForm",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					isMockup: true,
					mandatory: false,
				},
				{ ...detail },
				{
					type: "CheckGroup",
					title: "Select extra fields to display",
					key: "fields",
					options: [
						{
							title: "Entry dates",
							name: "entryDates",
						},
					],
				},
				{
					type: "ArrayFieldGroup",
					key: "options",
					title: "Select options",
					name: "Option",
					arrayType: "dropDown",
					fields: [
						{
							type: "TextField",
							key: "label",
							title: "Label",
							mandatory: true,
							humanReadable: true,
						},
						{
							type: "TextField",
							key: "to",
							title: "Mail to",
							mandatory: true,
						},
						{
							type: "TextField",
							key: "subject",
							title: "Subject",
						},
					],
				},
				{
					type: "TextField",
					key: "legalConditionPreffix",
					title: "Legal condition text preffix",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "TextField",
					key: "legalConditionSuffix",
					title: "Legal condition link text",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "UrlField",
					title: "Legal condition link",
					key: "legalConditionLink",
					placeholder: "https://",
					advanced: true,
				},
				{
					type: "TextField",
					key: "label",
					title: "Button Label",
					placeholder: "Type a label",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
		{
			title: "Config",
			fields: [
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"accent-alt",
						"inverse",
					]),
				},
				{
					title: "Show decoration",
					key: "decoration",
					type: "RadioGroup",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "GenericForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		options: [
			{
				label: "Busco Empleo",
				to: "ajfresneda@comillas.edu",
				subject: "Trabaja con nosotros",
			},
			{
				label: "Quiero publicar una oferta de empleo",
				to: "ope21@comillas.edu",
				subject: "Buzón web OPE",
			},
			{
				label: "Soy antiguo alumno",
				to: "alumni@comillas.edu",
				subject: "Contacto Comillas",
			},
			{
				label: "Soy alumno de intercambio",
				to: "oia@comillas.edu",
				subject: "Contacto web  intercambio",
			},
			{
				label: "Necesito contactar con el área de prensa",
				to: "comunicacion@comillas.edu",
				subject: "Contacto prensa",
			},
			{
				label: "Necesito contactar con publicidad",
				to: "fviscasillas@comillas.edu",
				subject: "Contacto publicidad",
			},
			{
				label: "Tengo una incidencia técnica",
				to: "incidencias.stic@comillas.edu",
				subject: "Incidencia técnica",
			},
			{
				label: "Soy proveedor de servicios tecnológicos",
				to: "incidencias.stic@comillas.edu",
				subject: "Provedor servicios",
			},
			{
				label:
					"Quiero contactar con un profesor o servicio/proponer una actividad/ofrecer un servicio",
				to: "oia@comillas.edu",
				subject: "Contacto profesor o servicio",
			},
			{
				label: "Otros",
				to: "oia@comillas.edu",
				subject: "Contacto web otros",
			},
		],
		label: "Enviar",
		legalConditionPreffix:
			"He podido leer y entiendo la información sobre el uso de mis datos personales explicada en la",
		legalConditionSuffix: "Política de Privacidad",
		legalConditionLink: undefined,
		subtheme: "default",
		decoration: false,
	},
};

export default schema;
