import { BasicContentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	heading,
	themeSelector,
	verticalSpacing,
	content,
	loremIpsumParagraph,
	subtitle,
	additional,
	getThemeOptions,
	getLinkModal,
} from "@schemas/presets";

const schema: Schema.Module<BasicContentProps> = {
	schemaType: "module",
	component: "BasicContent",
	category: "content",
	displayName: "Basic Content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Visual Content 1",
					key: "visualContent1",
					whiteList: ["ImageExp", "Video", "Map"],
					mandatory: true,
					helptext: "Recommended minimum image size: 792x514px",
				},

				{
					type: "ComponentContainer",
					title: "Visual Content 2",
					key: "visualContent2",
					whiteList: ["ImageExp", "Video"],
					hideable: true,
					helptext: "Recommended minimum image size: 792x514px",
				},

				{
					title: "Show decoration",
					key: "decoration",
					type: "RadioGroup",
					options: [
						{ name: "yes", value: "yes", title: "Yes" },
						{ name: "no", value: "no", title: "No" },
					],
					mandatory: true,
				},
				{ ...additional },
				{
					...heading,
					mandatory: false,
					hideable: true,
					isMockup: true,
				},

				{
					...subtitle,
				},

				{
					...content,
				},

				{
					type: "ComponentContainer",
					title: "Main link",
					key: "mainLink",
					whiteList: ["LinkModal"],
					hideable: true,
				},

				{
					type: "ComponentContainer",
					title: "Secondary link",
					key: "secondaryLink",
					whiteList: ["LinkModal"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/BasicContent/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/BasicContent/Layouts/layout02.png",
						},
						{
							value: "layout03",
							img: "/thumbnails/modules/BasicContent/Layouts/layout03.png",
						},
						{
							value: "layout04",
							img: "/thumbnails/modules/BasicContent/Layouts/layout04.png",
						},
						{
							value: "layout05",
							img: "/thumbnails/modules/BasicContent/Layouts/layout05.png",
						},
						{
							value: "layout06",
							img: "/thumbnails/modules/BasicContent/Layouts/layout06.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
						"accent-alt",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "BasicContent",
		visualContent1: {
			imageExp: { component: "ImageExp" },
			video: { component: "Video" },
			map: { component: "Map" },
		},
		visualContent2: {
			imageExp: { component: "ImageExp" },
			video: { component: "Video" },
		},
		decoration: "yes",
		additional: "Info",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		mainLink: getLinkModal("¿Te llamamos?"),
		secondaryLink: { component: "LinkModal" },
		anchorID: null,
		layout: "layout01",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/modules/BasicContent/onexed@1x.png",
			"2x": "/thumbnails/modules/BasicContent/onexed@2x.png",
		},
		"1x": "/thumbnails/modules/BasicContent/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicContent/thumbnail@2x.png",
	},
};

export default schema;
