import { SimpleLinkProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<SimpleLinkProps> = {
	schemaType: "component",
	displayName: "Link",
	component: "SimpleLink",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					_autoType: {
						doc: "/** Url field type */",
					},
					type: "UrlField",
					title: "Url",
					key: "url",
					advanced: true,
				},
				{
					_autoType: {
						doc: "/** Text for the button */",
					},
					type: "TextField",
					title: "Text",
					key: "text",
					placeholder: "Link text",
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "SimpleLink",
		url: {
			href: undefined,
			linkToURL: undefined,
			newTab: false,
			noFollow: false,
		},
		text: "",
	},
};

export default schema;
