import { Schema } from "@griddo/core";

export const RESEARCH_AREA: Schema.CategoryContentType = {
	dataPacks: ["LECTURERS"],
	title: "Research area",
	local: true,
	translate: true,
	taxonomy: true,
	fromPage: false,
};
