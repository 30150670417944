import { SearchVideoCreatorProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<SearchVideoCreatorProps> = {
	dataPacks: ["VIDEOS"],
	schemaType: "template",
	displayName: "Search Video Creator",
	component: "SearchVideoCreator",
	type: { label: "Videos", value: "videos" },
	singleInstance: true,

	content: [],

	default: {
		type: "template",
		templateType: "SearchVideoCreator",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/SearchVideoCreator/thumbnail@1x.png",
		"2x": "/thumbnails/templates/SearchVideoCreator/thumbnail@2x.png",
	},
};

export default schema;
