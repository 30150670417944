import { Theme } from "@griddo/core";
import primitives from "@themes/global-theme/colors";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="default"]',
	id: "default",
	name: "The default theme",
	primitives: [
		...primitives,

		// -------------------------------------------------------------------------
		// MISC
		// -------------------------------------------------------------------------

		{
			id: "brandColors",
			name: "brandColors",
			values: [{ cssVar: "--brand-1", value: "#7BA4DB" }],
		},

		// -------------------------------------------------------------------------
		// BUTTONS
		// -------------------------------------------------------------------------

		{
			id: "backgroundButton",
			name: "backgroundButton",
			values: [
				// 4
				{ cssVar: "--bg-link-button-4", value: "#7BA4DB" },
				{ cssVar: "--bg-link-button-4-hover", value: "#7BA4DB" },
				{ cssVar: "--bg-link-button-4-active", value: "#7BA4DB" },
				{ cssVar: "--bg-link-button-4-focus", value: "#7BA4DB" },
			],
		},
	],
};

export default subtheme;
