import { IconCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading, link, subtitle } from "@schemas/presets";

const schema: Schema.Component<IconCardProps> = {
	schemaType: "component",
	component: "IconCard",
	displayName: "Icon Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ImageField",
					title: "Icon",
					key: "icon",
					helptext: "Recommended use a vector or minimum image size: 120x120",
					hideable: true,
				},
				{
					...heading,
					hideable: false,
					isMockup: true,
				},
				{
					...subtitle,
					isMockup: true,
				},
				{
					...link,
					hideable: true,
					key: "iconCardLink",
				},
			],
		},
	],

	default: {
		component: "IconCard",
		icon: undefined,
		title: { content: "Lorem ipsum", tag: "h4" },
		subtitle: null,
		iconCardLink: {
			component: "Link",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/components/IconCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/IconCard/thumbnail@2x.png",
	},
};

export default schema;
