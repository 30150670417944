import { GenderChartProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading, loremIpsumParagraph, subtitle } from "@schemas/presets";

const schema: Schema.Component<GenderChartProps> = {
	schemaType: "component",
	displayName: "GenderChart",
	component: "GenderChart",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Value",
					type: "TextField",
					key: "value",
					mandatory: true,
				},
				{ ...heading, hideable: false },
				{ ...subtitle },
				{
					title: "Icon",
					type: "RadioGroup",
					key: "icon",
					options: [
						{ name: "female", value: "female", title: "Female" },
						{ name: "male", value: "male", title: "Male" },
					],
					mandatory: true,
				},
				{
					title: "Color",
					type: "ColorPicker",
					key: "color",
					mandatory: true,
					colors: [
						"#FFB81C",
						"#DB50FE",
						"#181818",
						"#707070",
						"#999",
						"#588200",
						"#A4123F",
						"#0E3E7A",
						"#7BA4DB",
						"#64B6AE",
					],
				},
			],
		},
	],

	default: {
		component: "GenderChart",
		value: "79%",
		title: { content: "Lorem ipsum", tag: "h3" },
		subtitle: loremIpsumParagraph,
		icon: "female",
		color: "#FFB81C",
	},

	thumbnails: {
		"1x": "/thumbnails/components/GenderChart/thumbnail@1x.png",
		"2x": "/thumbnails/components/GenderChart/thumbnail@2x.png",
	},
};

export default schema;
