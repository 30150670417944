import { Schema } from "@griddo/core";

const schema: Schema.Languages = {
	es_ES: {
		name: "Spanish",
		label: "ES",
	},
	en_GB: {
		name: "English",
		label: "EN",
	},
};

export default schema;
