import { FacebookProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<FacebookProps> = {
	schemaType: "component",
	component: "Facebook",
	displayName: "Facebook",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Facebook",
					key: "value",
					type: "TextField",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "Facebook",
		value: "",
		icon: "facebook",
	},
};

export default schema;
