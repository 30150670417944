import { VideoGalleryCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<VideoGalleryCardProps> = {
	schemaType: "component",
	component: "VideoGalleryCard",
	displayName: "Video Gallery Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Source",
					type: "ConditionalField",
					key: "source",
					mandatory: true,
					options: [
						{
							name: "url",
							value: "url",
							title: "Video (from URL)",
						},

						{
							name: "kaltura",
							value: "kaltura",
							title: "Video (Kaltura)",
						},
					],
					fields: [
						{
							condition: "kaltura",
							title: "",
							type: "ReferenceField",
							sources: [{ structuredData: "VIDEO" }],
							key: "data",
							selectionType: ["manual"],
							maxItems: 1,
							mandatory: true,
						},
						{
							condition: "url",
							type: "TextField",
							key: "url",
							title: "URL video",
							validators: { format: "URL" },
							mandatory: true,
						},
						{
							condition: "url",
							type: "ImageField",
							key: "backgroundImage",
							title: "Thumbnail",
							helptext: "Recommended minimum image size: 2508x1030px",
							mandatory: true,
						},
					],
				},
				{
					type: "UniqueCheck",
					key: "automaticSubtitles",
					title: "Subtitles",
					options: [{ title: "Add automatic subtitles" }],
				},
			],
		},
	],

	default: {
		component: "VideoGalleryCard",
		source: "url",
		url: "",
		backgroundImage: undefined,
		automaticSubtitles: true,
		data: {
			mode: "manual",
			fixed: [],
		},
	},
};

export default schema;
