import { FeaturedFactProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Component<FeaturedFactProps> = {
	schemaType: "component",
	component: "FeaturedFact",
	displayName: "Featured Fact",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
				},

				{
					type: "TextField",
					key: "content",
					title: "Content",
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "FeaturedFact",
		title: { content: "Lorem ipsum", tag: "h4" },
		content: null,
	},
};

export default schema;
