import Address from "./Address";
import DownloadComponent from "./DownloadComponent";
import Facebook from "./Facebook";
import Fax from "./Fax";
import FeaturedFact from "./FeaturedFact";
import Flickr from "./Flickr";
import HorizontalTab from "./HorizontalTab";
import Image from "./Image";
import ImageExp from "./ImageExp";
import ImageLite from "./ImageLite";
import Instagram from "./Instagram";
import Link from "./Link";
import Linkedin from "./Linkedin";
import Mail from "./Mail";
import Other from "./Other";
import Phone from "./Phone";
import QuickFact from "./QuickFact";
import SimpleLink from "./SimpleLink";
import Snapchat from "./Snapchat";
import TikTok from "./TikTok";
import Twitter from "./Twitter";
import VerticalTab from "./VerticalTab";
import WhatsApp from "./WhatsApp";
import Youtube from "./Youtube";

export default {
	Address,
	DownloadComponent,
	Facebook,
	Fax,
	Flickr,
	FeaturedFact,
	HorizontalTab,
	Image,
	ImageExp,
	ImageLite,
	Instagram,
	Link,
	Linkedin,
	Mail,
	Other,
	Phone,
	QuickFact,
	SimpleLink,
	Snapchat,
	TikTok,
	Twitter,
	VerticalTab,
	WhatsApp,
	Youtube,
};
