import { BasicCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	content,
	heading,
	link,
	defaultLink,
	loremIpsumParagraph,
	subtitle,
} from "@schemas/presets";

const schema: Schema.Component<BasicCardProps> = {
	schemaType: "component",
	component: "BasicCard",
	displayName: "Basic Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Media",
					key: "basicMedia",
					whiteList: ["BackgroundImage", "BackgroundVideo"],
					helptext: "Recommended minimum image size: 467x467",
					hideable: true,
				},
				{
					...heading,
					isMockup: true,
				},
				{
					title: "Label",
					type: "TextField",
					key: "label",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					...subtitle,
				},
				{
					...content,
				},

				{
					...link,
					hideable: true,
				},
				{
					...link,
					key: "secondaryLink",
					title: "Secondary link",
					mandatory: false,
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Title size",
					type: "RadioGroup",
					key: "size",
					options: [
						{ name: "Large", value: "large", title: "Large" },
						{ name: "Medium", value: "medium", title: "Medium" },
						{ name: "Small", value: "small", title: "Small" },
					],
					mandatory: false,
					hideable: true,
				},
				{
					type: "RadioGroup",
					key: "color",
					title: "Title color",
					options: [
						{ name: "default", title: "Default", value: "default" },
						{ name: "accent", title: "Accent", value: "#FFB81C" },
						{ name: "ICADE", title: "ICADE", value: "#A4123F" },
						{ name: "ICAI", title: "ICAI", value: "#0E3E7A" },
						{ name: "EUEF", title: "EUEF", value: "#64B6AE" },
						{ name: "CIHS", title: "CIHS", value: "#7BA4DB" },
					],
				},
			],
		},
	],

	default: {
		component: "BasicCard",
		basicMedia: {
			backgroundImage: { component: "BackgroundImage" },
			backgroundVideo: { component: "BackgroundVideo" },
		},
		title: { content: "title", tag: "h4" },
		label: null,
		subtitle: null,
		content: loremIpsumParagraph,
		link: {
			...defaultLink,
			text: "Leer más",
			variant: "button4",
		},
		secondaryLink: {
			component: "Link",
		},
		size: "medium",
		color: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/components/BasicCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/BasicCard/thumbnail@2x.png",
	},
};

export default schema;
