import { CardCarouselProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { content, heading, loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Component<CardCarouselProps> = {
	schemaType: "component",
	component: "CardCarousel",
	displayName: "Card Carousel",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Image",
					key: "image",
					whiteList: ["BackgroundImage"],
					helptext: "Recommended minimum image size: 467x350",
					mandatory: true,
				},
				{ ...heading, hideable: false, isMockup: true },
				{ ...content, hideable: false, mandatory: true },
			],
		},
	],

	default: {
		component: "CardCarousel",
		image: { component: "BackgroundImage" },
		title: {
			tag: "h4",
			content: "Lorem ipsum",
		},
		content: loremIpsumParagraph,
	},
};

export default schema;
