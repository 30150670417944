import { UIFields } from "@griddo/core";

export const emptyState: UIFields.Wysiwyg = {
	key: "emptyState",
	title: "Empty state",
	type: "Wysiwyg",
	full: false,
	hideable: true,
	placeholder: "Type content for empty state",
	isMockup: false,
	humanReadable: true,
};
