import { Theme } from "@griddo/core";

import defaultTheme from "./default";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="default-alt"]',
	id: "default-alt",
	name: "The defaultr alternative theme",
	primitives: [
		...(defaultTheme.primitives as Array<Theme.Primitive>),

		// -------------------------------------------------------------------------
		// BACKGROUNDS
		// -------------------------------------------------------------------------

		{
			id: "background",
			name: "background",
			values: [
				{ cssVar: "--bg-ui", value: "#FCFBF6" },
				{ cssVar: "--bg-1", value: "#FCFBF6" },
				{ cssVar: "--bg-2", value: "#F6F5EE" },
			],
		},
	],
};

export default subtheme;
