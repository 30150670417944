import { CountdownProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	getThemeOptions,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.Module<CountdownProps> = {
	schemaType: "module",
	component: "Countdown",
	displayName: "Countdown",
	category: "featured",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					title: "Title",
					key: "title",
					mandatory: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					type: "TextField",
					title: "Subtitle",
					key: "subtitle",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					key: "counterDate",
					title: "Counter date",
					type: "DateField",
					mandatory: true,
				},
				{
					key: "counterHour",
					title: "Counter hour",
					type: "TimeField",
					mandatory: true,
				},
				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["SimpleLink"],
					hideable: true,
				},
				{
					type: "TextField",
					title: "Expiration title",
					key: "expirationTitle",
					mandatory: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					type: "TextField",
					title: "Expiration subtitle",
					key: "expirationSubtitle",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["accent", "inverse"]),
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "Countdown",
		title: "Lorem ipsum",
		subtitle: loremIpsumParagraph,
		expirationTitle: "Expiration title",
		expirationSubtitle: loremIpsumParagraph,
		link: { component: "SimpleLink" },
		anchorID: null,
		subtheme: "accent",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Countdown/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Countdown/thumbnail@2x.png",
	},
};

export default schema;
