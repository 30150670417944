import { WysiwygProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	defaultLink,
	heading,
	link,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<WysiwygProps> = {
	schemaType: "module",
	component: "Wysiwyg",
	category: "content",
	displayName: "Wysiwyg",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, mandatory: false },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{
					type: "Wysiwyg",
					title: "Wysiwyg 01",
					key: "wysiwyg",
					mandatory: true,
					placeholder: "Type or add visual content",
					humanReadable: true,
				},
				{
					type: "Wysiwyg",
					title: "Wysiwyg 02",
					key: "wysiwyg2",
					hideable: true,
					placeholder: "Type or add visual content",
					humanReadable: true,
				},
				{ ...link, title: "Main link", hideable: true },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{ ...themeSelector },
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "Wysiwyg",
		additional: "Lorem ipsum",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		wysiwyg: loremIpsumParagraph,
		wysiwyg2: null,
		link: {
			...defaultLink,
			text: "¿Te llamamos?",
			variant: "button2",
		},
		anchorID: null,
		layout: "layout02",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Wysiwyg/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Wysiwyg/thumbnail@2x.png",
	},
};

export default schema;
