import { Schema } from "@griddo/core";

export const CHANNEL: Schema.PageContentType = {
	dataPacks: ["VIDEOS"],
	title: "Channel",
	local: true,
	fromPage: true,
	translate: false,
	taxonomy: false,
	schema: {
		templates: ["ChannelDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
			},
			{
				type: "Wysiwyg",
				key: "description",
				title: "Description",
				from: "description",
			},
			{
				type: "TextField",
				title: "Channel ID",
				from: "channelId",
				key: "channelId",
			},
			{
				type: "TextField",
				title: "Kaltura image",
				key: "kalturaImage",
				from: "kalturaImage",
			},
			{
				title: "Image",
				type: "ImageField",
				key: "image",
				from: "image",
			},
			{
				type: "NumberField",
				title: "Media files",
				from: "mediaFiles",
				key: "mediaFiles",
				indexable: true,
			},
			{
				type: "NumberField",
				title: "Members count",
				from: "membersCount",
				key: "membersCount",
				indexable: true,
			},
			{
				type: "DateField",
				title: "Date",
				from: "channelDate",
				key: "channelDate",
				indexable: true,
			},
			{
				type: "DateField",
				title: "Date (update)",
				from: "channelUpdateDate",
				key: "channelUpdateDate",
				indexable: true,
			},
			{
				type: "TextField",
				title: "Tags",
				from: "tags",
				key: "tags",
			},
			{
				type: "UniqueCheck",
				key: "publicChannel",
				from: "publicChannel",
				title: "Public",
				options: [{ title: "Public" }],
			},
			{
				type: "UniqueCheck",
				key: "userJoinNotAllowed",
				from: "userJoinNotAllowed",
				title: "User join not allowed",
				options: [{ title: "User join not allowed" }],
			},
			{
				type: "UniqueCheck",
				key: "moderation",
				from: "moderation",
				title: "Moderation",
				options: [{ title: "Moderation" }],
			},
			{
				type: "TextArea",
				key: "managers",
				from: "managers",
				title: "Managers",
			},
			{
				type: "TextArea",
				key: "videos",
				from: "videos",
				title: "Videos",
			},
			{
				type: "MultiCheckSelect",
				title: "Categories",
				placeholder: "Categories",
				from: "categories.channelCategories",
				key: "channelCategories",
				source: "CATEGORY_VIDEO",
			},
			{
				type: "MultiCheckSelect",
				title: "Tags",
				placeholder: "Tags",
				from: "categories.channelTags",
				key: "channelTags",
				source: "VIDEO_TAG",
			},
		],
	},
};
