import { Schema } from "@griddo/core";

export const EVENT: Schema.PageContentType = {
	dataPacks: ["EVENTS"],
	title: "Events",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["EventDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "DateField",
				title: "Date",
				from: "eventDate",
				key: "eventDate",
				mandatory: true,
				indexable: true,
			},
			{
				type: "TimeField",
				title: "Time",
				from: "eventTime",
				key: "eventTime",
				mandatory: true,
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Abstract",
				from: "abstract",
				key: "abstract",
			},
			{
				type: "MultiCheckSelect",
				title: "Centers",
				placeholder: "Centers",
				from: "categories.centers",
				key: "centers",
				source: "CENTER",
				mandatory: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Schools",
				placeholder: "Schools",
				from: "categories.schools",
				key: "schools",
				source: "SCHOOL",
				mandatory: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Services",
				placeholder: "Services",
				from: "categories.services",
				key: "services",
				source: "SERVICE",
				mandatory: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Locations",
				placeholder: "Locations",
				from: "categories.locations",
				key: "locations",
				source: "LOCATION",
				mandatory: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Topics",
				placeholder: "Topics",
				from: "categories.topics",
				key: "topics",
				source: "TOPIC",
				mandatory: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Event type",
				placeholder: "Event type",
				from: "categories.type",
				key: "type",
				source: "EVENT_TYPE",
				mandatory: true,
			},
		],
	},
};
