import { Schema } from "@griddo/core";

export const AGREEMENTS: Schema.SimpleContentType = {
	dataPacks: ["AGREEMENTS"],
	title: "Agreements",
	local: true,
	translate: false,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "university",
				title: "University",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "school",
				title: "School",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "academicYear",
				title: "Academic year",
				type: "TextField",
			},
			{
				key: "incoming",
				title: "Incoming",
				type: "NumberField",
				min: 1,
			},
			{
				key: "outgoing",
				title: "Outgoing",
				type: "NumberField",
				min: 1,
			},
			{
				key: "city",
				title: "City",
				type: "TextField",
			},
			{
				key: "country",
				title: "Country",
				type: "TextField",
			},
			{
				key: "latitude",
				title: "Location: Latitude",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "longitude",
				title: "Location: Longitude",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "divider",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Select Categories",
					text: "",
				},
			},
			{
				key: "region",
				title: "Region",
				placeholder: "Region",
				type: "MultiCheckSelect",
				source: "REGION",
			},
			{
				key: "type",
				title: "Type",
				placeholder: "Type",
				type: "MultiCheckSelect",
				source: "AGREEMENTS_TYPE",
			},
		],
	},
};
