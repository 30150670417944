import { BackgroundImageProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<BackgroundImageProps> = {
	schemaType: "component",
	component: "BackgroundImage",
	displayName: "Image",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "File",
					type: "ImageField",
					key: "imageField",
					helptext: "The background image file.",
				},
				{
					title: "Crop",
					type: "RadioGroup",
					key: "crop",
					options: [
						{ name: "cover", value: "cover", title: "Cover" },
						{ name: "contain", value: "contain", title: "Contain" },
					],
				},
				{
					type: "SliderField",
					step: 1,
					min: 0,
					max: 100,
					key: "positionX",
					title: "Horizontal Alignment",
				},
				{
					type: "SliderField",
					step: 1,
					min: 0,
					max: 100,
					key: "positionY",
					title: "Vertical Alignment",
				},
			],
		},

		{
			title: "Config",
			fields: [
				{
					type: "UniqueCheck",
					title: "Use veil",
					key: "useVeil",
					options: [
						{
							title: "Use veil",
						},
					],
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					step: 10,
					min: 0,
					max: 100,
				},
				{
					title: "Quality",
					type: "SliderField",
					key: "quality",
					step: 10,
					min: 10,
					max: 100,
					helptext:
						"Set the quality of the image. The higher the quality, the bigger the file size.",
				},
			],
		},
	],

	default: {
		component: "BackgroundImage",
		quality: 70,
		crop: "cover",
		positionX: 50,
		positionY: 50,
		useVeil: true,
		veil: 20,
	},

	thumbnails: {
		"1x": "/thumbnails/components/BackgroundImage/thumbnail@1x.png",
		"2x": "/thumbnails/components/BackgroundImage/thumbnail@2x.png",
	},
};

export default schema;
