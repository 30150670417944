import { ProgramNavigationProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import SimpleLink from "@schemas/objects/SimpleLink";

const schema: Schema.Module<ProgramNavigationProps> = {
	schemaType: "module",
	component: "ProgramNavigation",
	displayName: "Program Navigation",
	singleInstance: true,
	dataPacks: ["PROGRAMS"],

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Program tabs",
					key: "elements",
					whiteList: ["SimpleLink"],
					contentType: "components",
					mandatory: true,
					maxItems: 6,
				},
			],
		},
	],

	default: {
		component: "ProgramNavigation",
		elements: [
			{ ...SimpleLink.default, text: "Link 1" },
			{ ...SimpleLink.default, text: "Link 2" },
			{ ...SimpleLink.default, text: "Link 3" },
		],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProgramNavigation/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProgramNavigation/thumbnail@2x.png",
	},
};

export default schema;
