import { ProgramsDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	emptyState,
	getThemeOptions,
	heading,
	link,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ProgramsDistributorProps> = {
	schemaType: "module",
	component: "ProgramsDistributor",
	category: "distributors",
	displayName: "Programs Distributor",
	dataPacks: ["PROGRAMS"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		QualityTemplate: ["mainSection"],
		ProgramDetail: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, isMockup: false },
				{ ...heading, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{
					...emptyState,
				},
				{
					title: "",
					type: "ReferenceField",
					sources: [
						{ structuredData: "PROGRAM" },
						{ structuredData: "PROGRAM_EXTERNAL" },
					],
					key: "data",
					mandatory: true,
				},
				{
					title: "Which page should open?",
					type: "RadioGroup",
					key: "linkDirection",
					mandatory: false,
					options: [
						{
							value: "canonical",
							title: "Canonical site page",
							name: "pageOfCanonical",
						},
						{
							value: "site",
							title: "Current site page",
							name: "pageOfSite",
						},
					],
				},
				{
					title: "Show Calls for registration",
					type: "RadioGroup",
					key: "registrations",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
				{
					title: "Show image",
					type: "RadioGroup",
					key: "image",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
				{ ...link, title: "Button", hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent-alt",
					]),
				},
				{ ...animation },
				{ ...verticalSpacing },
			],
		},
	],

	default: {
		component: "ProgramsDistributor",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h3",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		emptyState:
			"Parece que por el momento no hay programas... ¡Pero seguro que pronto los habrá!",
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [
				{
					structuredData: "PROGRAM",
					globalOperator: "AND",
					filterOperator: "OR",
				},
				{
					structuredData: "PROGRAM_EXTERNAL",
					globalOperator: "AND",
					filterOperator: "OR",
				},
			],
			quantity: 6,
			fullRelations: true,
		},
		linkDirection: "site",
		registrations: true,
		image: false,
		link: {
			component: "Link",
		},
		anchorID: null,
		subtheme: "default",
		animation: "none",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/modules/ProgramsDistributor/onexed@1x.png",
			"2x": "/thumbnails/modules/ProgramsDistributor/onexed@2x.png",
		},
		"1x": "/thumbnails/modules/ProgramsDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProgramsDistributor/thumbnail@2x.png",
	},
};

export default schema;
