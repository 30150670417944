import { MapProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<MapProps> = {
	schemaType: "component",
	component: "Map",
	displayName: "Map Component",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "latitude",
					title: "Latitude",
					placeholder: "40.43010660646391",
					isMockup: true,
					mandatory: true,
				},
				{
					type: "TextField",
					key: "longitude",
					title: "Longitude",
					placeholder: "-3.711409670669836",
					mandatory: true,
				},
				{
					type: "SliderField",
					key: "zoom",
					title: "Zoom",
					min: 0,
					max: 22,
					step: 1,
				},
			],
		},
	],
	thumbnails: {
		"1x": "/thumbnails/components/Map/thumbnail@1x.png",
		"2x": "/thumbnails/components/Map/thumbnail@2x.png",
	},
	default: {
		component: "Map",
		zoom: 16,
	},
};

export default schema;
