import { UIFields } from "@griddo/core";

export const heading: UIFields.HeadingField = {
	type: "HeadingField",
	title: "Title",
	default: { content: "", tag: "h2" },
	key: "title",
	placeholder: "Type a title",
	advanced: true,
	helptext:
		"Write in plain text and select the type of heading. Use underscores at the beginning and end of a word to display it in italics. For example:  Lorem _ipsum_.  For typewriting animation, add double square brackets. For example:  Lorem [[ipsum]]. To mix both, you need to write underscores first: Lorem _[[ipsum]]_.",
	hideable: true,
	isMockup: false,
	mandatory: true,
	humanReadable: true,
	options: [
		{ label: "h1", value: "h1" },
		{ label: "h2", value: "h2" },
		{ label: "h3", value: "h3" },
		{ label: "h4", value: "h4" },
		{ label: "h5", value: "h5" },
		{ label: "h6", value: "h6" },
	],
};
