import { InnerVerticalTabsProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import VerticalTab from "@schemas/objects/VerticalTab";
import {
	additional,
	heading,
	verticalSpacing,
	loremIpsumParagraph,
	subtitle,
	content,
	animation,
} from "@schemas/presets";

const schema: Schema.Module<InnerVerticalTabsProps> = {
	schemaType: "module",
	displayName: "Vertical Tabs",
	category: "interactive",
	component: "InnerVerticalTabs",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{
					...heading,
					isMockup: true,
					mandatory: false,
				},

				{
					...subtitle,
				},

				{
					...content,
				},

				{
					title: "Tabs",
					type: "ComponentArray",
					key: "tabs",
					whiteList: ["VerticalTab"],
					contentType: "components",
					maxItems: 6,
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					...verticalSpacing,
				},

				{
					...animation,
				},
			],
		},
	],

	default: {
		component: "InnerVerticalTabs",
		additional: null,
		title: { content: "Lorem Ipsum", tag: "h3" },
		subtitle: loremIpsumParagraph,
		content: null,
		tabs: [
			{ ...VerticalTab.default },
			{ ...VerticalTab.default },
			{ ...VerticalTab.default },
		],
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/VerticalTabs/thumbnail@1x.png",
		"2x": "/thumbnails/modules/VerticalTabs/thumbnail@2x.png",
	},
};

export default schema;
