import { BasicTemplateProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<BasicTemplateProps> = {
	schemaType: "template",
	displayName: "Basic Template",
	component: "BasicTemplate",
	type: { label: "Static", value: "static" },

	dimensions: [],

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: [
				"HeroCarousel",
				"HeroInner",
				"HeroSide",
				"HeroVideo",
				"MainHero",
			],
			contentType: "modules",
			key: "heroSection",
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			whiteList: [
				"AccordionCollection",
				"AgreementsMap",
				"AnimatedImageGallery",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"CardsCarousel",
				"ChartCollection",
				"ContactInfo",
				"Countdown",
				"CypherCollection",
				"DownloadCollection",
				"FAQCollection",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"FeaturedFacts",
				"FullVideo",
				"HighlightedCardCollection",
				"HighlightedContent",
				"HorizontalTabs",
				"ImageBanner",
				"ImageGallery",
				"IntroForm",
				"LogoCollection",
				"MasonryCollection",
				"Milestones",
				"Principles",
				"Quote",
				"SocialShare",
				"Spacer",
				"StepCollection",
				"StickyTabs",
				"Table",
				"VerticalTabs",
				"TextCarousel",
				"TextHeroBanner",
				"TextHeroCountdown",
				"TypographicVideo",
				"VideoGallery",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "BasicTemplate",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/BasicTemplate/thumbnail@1x.png",
		"2x": "/thumbnails/templates/BasicTemplate/thumbnail@2x.png",
	},
};

export default schema;
