import { SimpleStepCollectionCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { link, heading, content, defaultLink } from "@schemas/presets";

const schema: Schema.Component<SimpleStepCollectionCardProps> = {
	schemaType: "component",
	displayName: "Simple Step Collection Card",
	component: "SimpleStepCollectionCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Step number",
					type: "TextField",
					key: "stepNumber",
					mandatory: true,
				},
				{
					...heading,
					hideable: false,
				},
				{
					...content,
					// validators: { maxChar: 300 }, // TODO add when finished https://app.shortcut.com/secuoyas/story/38413/error-en-validaci%C3%B3n-de-caracteres-m%C3%A1ximos
				},
				{ ...link, hideable: true },
			],
		},
	],

	default: {
		component: "SimpleStepCollectionCard",
		title: { content: "Lorem ipsum", tag: "h4" },
		stepNumber: "01",
		content: null,
		link: {
			...defaultLink,
			text: "Ver más",
			variant: "button4",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/components/SimpleStepCollectionCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/SimpleStepCollectionCard/thumbnail@2x.png",
	},
};

export default schema;
