import { VideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<VideoProps> = {
	schemaType: "component",
	component: "Video",
	displayName: "Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Source",
					type: "ConditionalField",
					key: "source",
					mandatory: true,
					options: [
						{
							name: "url",
							value: "url",
							title: "Video (from URL)",
						},

						{
							name: "kaltura",
							value: "kaltura",
							title: "Video (Kaltura)",
						},
					],
					fields: [
						{
							condition: "kaltura",
							title: "",
							type: "ReferenceField",
							sources: [{ structuredData: "VIDEO" }],
							key: "data",
							selectionType: ["manual"],
							maxItems: 1,
							mandatory: true,
						},
						{
							condition: "url",
							type: "TextField",
							key: "url",
							title: "URL video",
							validators: { format: "URL" },
							mandatory: true,
						},
						{
							condition: "url",
							title: "Thumbnail",
							type: "ConditionalField",
							key: "backgroundType",
							mandatory: true,
							options: [
								{
									name: "video",
									value: "video",
									title: "Video",
								},

								{
									name: "image",
									value: "image",
									title: "Image",
								},
							],
							fields: [
								{
									condition: "video",
									type: "TextField",
									key: "backgroundVideo",
									title: "URL video",
									validators: { format: "URL" },
								},
								{
									condition: "image",
									type: "ImageField",
									key: "backgroundImage",
									title: "Thumbnail",
									helptext: "Recommended minimum image size: 2508x1030px",
								},
							],
						},
					],
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					step: 10,
					min: 0,
					max: 100,
				},
				{
					type: "UniqueCheck",
					key: "automaticSubtitles",
					title: "Subtitles",
					options: [{ title: "Add automatic subtitles" }],
				},
				{
					type: "UniqueCheck",
					title: "Open in modal",
					key: "openInModal",
					options: [
						{
							title: "Open in modal",
						},
					],
				},
			],
		},
	],

	default: {
		component: "Video",
		source: "url",
		url: "",
		backgroundType: "image",
		backgroundVideo: "",
		backgroundImage: undefined,
		veil: 50,
		automaticSubtitles: true,
		openInModal: false,
		hasDistributorData: true,
		data: {
			mode: "manual",
			fixed: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/components/Video/thumbnail@1x.png",
		"2x": "/thumbnails/components/Video/thumbnail@2x.png",
	},
};

export default schema;
