import { CHANNEL } from "./CHANNEL";
import { EVENT } from "./EVENT";
import { LIVE_STREAM } from "./LIVE_STREAM";
import { NEWS } from "./NEWS";
import { PRESS_RELEASES } from "./PRESS_RELEASES";
import { PROGRAM } from "./PROGRAM";
import { PROJECT } from "./PROJECT";
import { VIDEO } from "./VIDEO";

export default {
	CHANNEL,
	EVENT,
	LIVE_STREAM,
	NEWS,
	PRESS_RELEASES,
	PROGRAM,
	PROJECT,
	VIDEO,
};
