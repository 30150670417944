import { Schema } from "@griddo/core";

export const PLAYLIST: Schema.SimpleContentType = {
	dataPacks: ["VIDEOS"],
	title: "Playlist",
	local: true,
	translate: false,
	clone: null,
	defaultValues: null,
	fromPage: false,
	editable: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
			},
			{
				key: "idAPI",
				title: "API ID",
				type: "TextField",
			},
			{
				type: "TextArea",
				key: "videos",
				title: "Videos",
			},
			{
				type: "ReferenceField",
				title: "Related Videos",
				key: "data",
				sources: [{ structuredData: "VIDEO" }],
				selectionType: ["manual"],
			},
		],
	},
};
