import { HeroCarouselSlideProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	themeSelector,
	loremIpsumParagraph,
	content,
	defaultLink,
	getThemeOptions,
	subtitle,
} from "@schemas/presets";

const schema: Schema.Component<HeroCarouselSlideProps> = {
	schemaType: "component",
	component: "HeroCarouselSlide",
	category: "heros",
	displayName: "Hero Slide Carousel",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					hideable: false,
					isMockup: true,
					mandatory: true,
				},
				{ ...subtitle },
				{
					...content,
				},
				{
					title: "Type",
					type: "ConditionalField",
					key: "media",
					options: [
						{
							name: "backgroundMedia",
							value: "background",
							title: "Background media",
						},

						{
							name: "visualContent",
							value: "visualContent",
							title: "Visual Content",
						},
					],
					fields: [
						{
							condition: "background",
							title: "Image/Video Background",
							type: "ComponentContainer",
							whiteList: ["BackgroundImage", "BackgroundVideo"],
							key: "backgroundMedia",
							hideable: true,
						},
						{
							condition: "visualContent",
							type: "ComponentContainer",
							title: "Visual Content 1",
							key: "visualContent1",
							whiteList: ["ImageLite", "Video"],
							hideable: true,
						},
						{
							condition: "visualContent",
							type: "ComponentContainer",
							title: "Visual Content 2",
							key: "visualContent2",
							whiteList: ["ImageLite", "Video"],
							hideable: true,
						},
					],
				},

				{
					type: "ComponentContainer",
					title: "Link 1",
					key: "link1",
					whiteList: ["Link"],
					hideable: true,
				},
				{
					type: "ComponentContainer",
					title: "Link 2",
					key: "link2",
					whiteList: ["Link"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...themeSelector, options: getThemeOptions(["default", "inverse"]) },
				{
					type: "RadioGroup",
					key: "titleSize",
					title: "Title Size",
					mandatory: true,
					options: [
						{ name: "s", value: "s", title: "S" },
						{ name: "m", value: "m", title: "M" },
						{ name: "l", value: "l", title: "L" },
						{ name: "xl", value: "xl", title: "XL" },
					],
				},
			],
		},
	],

	default: {
		component: "HeroCarouselSlide",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		media: "background",
		backgroundMedia: {
			backgroundImage: { component: "BackgroundImage" },
			backgroundVideo: { component: "BackgroundVideo" },
		},
		visualContent1: {
			imageLite: { component: "ImageLite" },
			video: { component: "Video" },
		},
		visualContent2: {
			imageLite: { component: "ImageLite" },
			video: { component: "Video" },
		},
		link1: {
			...defaultLink,
			variant: "button2",
			text: "Ver más",
		},
		link2: {
			...defaultLink,
			variant: "button3",
			text: "Ver más",
		},
		subtheme: "default",
		titleSize: "l",
	},

	thumbnails: {
		"1x": "/thumbnails/components/HeroCarouselSlide/thumbnail@1x.png",
		"2x": "/thumbnails/components/HeroCarouselSlide/thumbnail@2x.png",
	},
};

export default schema;
