import { VideosListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicHeroTV from "@schemas/modules/BasicHeroTV";

const schema: Schema.Template<VideosListProps> = {
	dataPacks: ["VIDEOS"],
	schemaType: "template",
	displayName: "Videos List",
	component: "VideosList",
	type: { label: "Videos", value: "videos" },

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["BasicHeroTV"],
			contentType: "modules",
			key: "heroSection",
			mandatory: true,
		},
		{
			type: "CheckGroup",
			title: "Select template options",
			key: "templateOptions",
			options: [
				{
					title: "Search feature",
					name: "search",
				},
				{
					title: "Filter (Caption)",
					name: "filterCaption",
				},
				{
					title: "Filter (Duration)",
					name: "filterDuration",
				},
				{
					title: "Filter (Creation date)",
					name: "filterCreation",
				},
				{
					title: "Filter (Update date)",
					name: "filterUpdate",
				},
				{
					title: "Sort feature (channels)",
					name: "sort",
				},
			],
		},
		{
			type: "TextField",
			title: "No Results text",
			key: "noResultsText",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "ReferenceField",
			title: "Videos",
			key: "data",
			sources: [{ structuredData: "VIDEO" }],
			mandatory: true,
		},
		{
			type: "ReferenceField",
			title: "Channels",
			key: "dataChannels",
			sources: [{ structuredData: "CHANNEL" }],
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			min: 6,
			mandatory: true,
		},
	],

	config: [
		{
			type: "RadioGroup",
			title: "Pagination type",
			key: "paginationType",
			options: [
				{ name: "normal", value: "normal", title: "Normal pagination" },
				{ name: "infinite", value: "infinite", title: "Infinite scroll" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "VideosList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [{ ...BasicHeroTV.default, breadcrumbsDesktop: false }],
		},
		noResultsText:
			"Sorry, no results were found. Please try a different search.",
		itemsPerPage: 8,
		activePage: 1,
		data: {
			mode: "auto",
			order: "videoDate-DESC",
			sources: [{ structuredData: "VIDEO" }],
		},
		dataChannels: {
			mode: "auto",
			order: "membersCount-DESC",
			sources: [{ structuredData: "CHANNEL" }],
		},
		paginationType: "normal",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/VideosList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/VideosList/thumbnail@2x.png",
	},
};

export default schema;
