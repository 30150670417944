import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

export const PEOPLE: Schema.SimpleContentType = {
	dataPacks: ["LECTURERS"],
	title: "People",
	local: false,
	translate: true,
	clone: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Label",
				placeholder: "Type a label",
				type: "TextField",
				mandatory: true,
				helptext: "Used in the editor.",
			},
			{
				key: "image",
				title: "Image",
				type: "ImageField",
				helptext: "Shown on the distributor card",
			},
			{
				...heading,
				key: "name",
				title: "Name",
				isMockup: true,
				default: { tag: "h4", content: "‘Content type name’ title" },
			},
			{
				key: "position",
				title: "Position",
				type: "TextField",
				helptext: "Shown on the distributor card",
				humanReadable: true,
			},
			{
				key: "content",
				title: "Content",
				type: "Wysiwyg",
				full: false,
				helptext: "Shown on the distributor card",
				humanReadable: true,
			},
			{
				key: "peopleUrl",
				title: "URL",
				type: "UrlField",
				helptext: "Shown on the distributor card",
				advanced: true,
			},
			{
				type: "TextField",
				title: "Email",
				key: "email",
				validators: { format: "email" },
			},
			{
				key: "linkedin",
				title: "LinkedIn",
				type: "TextField",
				helptext: "Shown on the distributor card",
			},
			{
				key: "divider",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Select Categories",
					text: "",
				},
			},
			{
				key: "centers",
				title: "Centers",
				placeholder: "Centers",
				type: "MultiCheckSelect",
				source: "CENTER",
			},
			{
				key: "tags",
				title: "Tags",
				placeholder: "Tags",
				type: "MultiCheckSelect",
				source: "PEOPLE_TAG",
			},
			{
				key: "researchGroup",
				title: "Research group",
				placeholder: "Research group",
				type: "MultiCheckSelect",
				source: "RESEARCH_GROUP",
			},
		],
	},
};
