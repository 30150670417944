import { EventsDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	emptyState,
	getThemeOptions,
	heading,
	link,
	loremIpsumParagraph,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<EventsDistributorProps> = {
	schemaType: "module",
	component: "EventsDistributor",
	category: "distributors",
	displayName: "Events Distributor",
	dataPacks: ["EVENTS"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		QualityTemplate: ["mainSection"],
		ProgramLanding: ["mainSection"],
		Landing: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false },
				{ ...content },
				{
					...emptyState,
				},
				{
					title: "Events",
					type: "ReferenceField",
					sources: [
						{ structuredData: "EVENT" },
						{ structuredData: "EVENT_AUTO" },
					],
					key: "data",
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent-alt",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "EventsDistributor",
		additional: null,
		title: {
			content: "Eventos",
			tag: "h3",
		},
		content: loremIpsumParagraph,
		emptyState:
			"Parece que no hay ningún evento próximamente, pero seguro que los habrá pronto. No dudes en visitar nuestro portal de comunicación y prensa para no perderte nada.",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "EVENT", globalOperator: "OR" }],
		},
		link: {
			component: "Link",
			variant: "button2",
			text: "Ver todos los eventos",
		},
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/EventsDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/EventsDistributor/thumbnail@2x.png",
	},
};

export default schema;
