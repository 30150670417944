import { TypographicVideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	themeSelector,
	loremIpsumParagraph,
	getThemeOptions,
	link,
} from "@schemas/presets";

const schema: Schema.Module<TypographicVideoProps> = {
	schemaType: "module",
	component: "TypographicVideo",
	category: "featured",
	displayName: "Typographic Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					title: "Quote",
					key: "title",
					mandatory: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					type: "TextField",
					title: "Subtitle",
					key: "subtitle",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					type: "UniqueCheck",
					title: "Decoration",
					key: "decoration",
					options: [{ title: "Show decoration" }],
				},
				{
					title: "Background type",
					type: "ConditionalField",
					key: "backgroundType",
					mandatory: true,
					options: [
						{
							name: "image",
							value: "image",
							title: "Image",
						},
						{
							name: "video",
							value: "video",
							title: "Video",
						},
					],
					fields: [
						{
							condition: "image",
							title: "Background image",
							type: "ImageField",
							key: "image",
							mandatory: true,
						},
						{
							condition: "video",
							title: "Video source",
							type: "ConditionalField",
							key: "source",
							mandatory: true,
							options: [
								{
									name: "url",
									value: "url",
									title: "Video (from URL)",
								},

								{
									name: "kaltura",
									value: "kaltura",
									title: "Video (Kaltura)",
								},
							],
							fields: [
								{
									condition: "url",
									title: "Background Video URL",
									type: "TextField",
									key: "videoUrl",
									mandatory: true,
									helptext:
										"This video will be used as a background video. It will be muted and looped. Playlist URL are not allowed.",
								},
								{
									condition: "kaltura",
									title: "",
									type: "ReferenceField",
									sources: [{ structuredData: "VIDEO" }],
									key: "data",
									selectionType: ["manual"],
									maxItems: 1,
									mandatory: true,
								},
							],
						},
					],
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["accent", "inverse", "inverse-alt"]),
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "TypographicVideo",
		title: "Lorem ipsum",
		subtitle: loremIpsumParagraph,
		decoration: false,
		backgroundType: "video",
		source: "url",
		hasDistributorData: true,
		data: {
			mode: "manual",
			fixed: [],
		},
		link: { component: "Link" },
		anchorID: null,
		subtheme: "accent",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/TypographicVideo/thumbnail@1x.png",
		"2x": "/thumbnails/modules/TypographicVideo/thumbnail@2x.png",
	},
};

export default schema;
