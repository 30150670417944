import { SearchResultsTvProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<SearchResultsTvProps> = {
	dataPacks: ["VIDEOS"],
	schemaType: "template",
	displayName: "Search Results TV",
	component: "SearchResultsTv",
	type: { label: "Videos", value: "videos" },
	singleInstance: true,

	content: [
		{
			type: "UrlField",
			title: "Link Canales",
			key: "linkChannels",
			advanced: true,
			hideable: true,
		},
		{
			type: "UrlField",
			title: "Link Galerías",
			key: "linkGalleries",
			advanced: true,
			hideable: true,
		},
	],

	default: {
		type: "template",
		templateType: "SearchResultsTv",
		linkChannels: {
			href: undefined,
			linkToURL: undefined,
			newTab: false,
			noFollow: false,
		},
		linkGalleries: {
			href: undefined,
			linkToURL: undefined,
			newTab: false,
			noFollow: false,
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/SearchResultsTv/thumbnail@1x.png",
		"2x": "/thumbnails/templates/SearchResultsTv/thumbnail@2x.png",
	},
};

export default schema;
