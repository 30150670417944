import { InnerBasicContentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	animation,
	content,
	getLinkModal,
	loremIpsumParagraph,
} from "@schemas/presets";

const schema: Schema.Module<InnerBasicContentProps> = {
	schemaType: "module",
	component: "InnerBasicContent",
	category: "content",
	displayName: "Basic Content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Visual Content 1",
					key: "visualContent1",
					whiteList: ["ImageExp", "Video"],
					mandatory: true,
					helptext: "Recommended minimum image size: 792x514px",
				},

				{
					type: "ComponentContainer",
					title: "Visual Content 2",
					key: "visualContent2",
					whiteList: ["ImageExp", "Video"],
					hideable: true,
					helptext: "Recommended minimum image size: 792x514px",
				},
				{ ...content },
				{
					type: "ComponentContainer",
					title: "Main link",
					key: "mainLink",
					whiteList: ["LinkModal"],
					hideable: true,
				},
				{
					type: "ComponentContainer",
					title: "Secondary link",
					key: "secondaryLink",
					whiteList: ["LinkModal"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/BasicContent/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/BasicContent/Layouts/layout02.png",
						},
						{
							value: "layout03",
							img: "/thumbnails/modules/BasicContent/Layouts/layout03.png",
						},
						{
							value: "layout04",
							img: "/thumbnails/modules/BasicContent/Layouts/layout04.png",
						},
						{
							value: "layout05",
							img: "/thumbnails/modules/BasicContent/Layouts/layout05.png",
						},
						{
							value: "layout06",
							img: "/thumbnails/modules/BasicContent/Layouts/layout06.png",
						},
					],
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "InnerBasicContent",
		visualContent1: {
			imageExp: { component: "ImageExp" },
			video: { component: "Video" },
		},
		visualContent2: {
			imageExp: { component: "ImageExp" },
			video: { component: "Video" },
		},
		content: loremIpsumParagraph,
		mainLink: getLinkModal("¿Te llamamos?"),
		secondaryLink: getLinkModal("Saber más", "button3"),
		layout: "layout01",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicContent/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicContent/thumbnail@2x.png",
	},
};

export default schema;
