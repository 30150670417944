import { StudyPlanAutoProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	content,
	detail,
	getThemeOptions,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<StudyPlanAutoProps> = {
	component: "StudyPlanAuto",
	schemaType: "module",
	category: "content",
	displayName: "Study Plan (Auto)",
	dataPacks: ["PROGRAMS"],
	sectionList: { ProgramDetail: ["mainSection"] },

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, hideable: false, isMockup: true },
				{
					key: "items",
					title: "Items",
					name: "Items",
					type: "ArrayFieldGroup",
					arrayType: "dropDown",
					fields: [
						{
							key: "year",
							title: "Year",
							type: "TextField",
						},
						{
							key: "main",
							title: "Main",
							type: "UniqueCheck",
							options: [
								{
									title: "Main",
								},
							],
						},
						{
							key: "secId",
							title: "secId (API)",
							type: "TextField",
						},
						{
							key: "planEstudios",
							title: "planEstudios (API)",
							type: "TextField",
						},
						{
							key: "content",
							title: "Content",
							name: "Content",
							type: "ArrayFieldGroup",
							arrayType: "dropDown",
							fields: [
								{ ...content },
								{
									key: "years",
									title: "Years",
									name: "Year",
									type: "ArrayFieldGroup",
									arrayType: "dropDown",
									fields: [
										{
											...heading,
											default: { tag: "h3", content: "Lorem ipsum" },
											mandatory: false,
										},
										{ ...detail },
										{
											key: "semesters",
											title: "Semesters",
											name: "Semester",
											type: "ArrayFieldGroup",
											arrayType: "dropDown",
											fields: [
												{
													key: "semesterTitle",
													title: "Semester title",
													type: "TextField",
												},
												{
													key: "creditsTitle",
													title: "Credits title",
													type: "TextField",
												},
												{
													key: "subjects",
													title: "Subjects",
													name: "Subject",
													type: "ArrayFieldGroup",
													arrayType: "dropDown",
													fields: [
														{
															...heading,
															default: { tag: "h4", content: "Lorem ipsum" },
															mandatory: false,
														},
														{
															key: "className",
															title: "idEstilo (API)",
															type: "TextField",
														},
														{
															key: "credits",
															title: "Credits",
															type: "TextField",
														},
														{
															key: "document",
															title: "Document",
															type: "TextField",
															hideable: true,
														},
													],
												},
											],
										},
									],
								},
							],
						},
					],
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "StudyPlanAuto",
		title: { tag: "h2", content: "Lorem ipsum" },
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/modules/StudyPlan/onexed@1x.png",
			"2x": "/thumbnails/modules/StudyPlan/onexed@2x.png",
		},
		"1x": "/thumbnails/modules/StudyPlan/thumbnail@1x.png",
		"2x": "/thumbnails/modules/StudyPlan/thumbnail@2x.png",
	},
};

export default schema;
