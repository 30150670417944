import { BasicHeroTVProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	anchorID,
	subtitle,
	verticalSpacing,
	animation,
} from "@schemas/presets";

const schema: Schema.Module<BasicHeroTVProps> = {
	schemaType: "module",
	component: "BasicHeroTV",
	category: "heros",
	displayName: "Basic Hero",
	dataPacks: ["VIDEOS"],

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					isMockup: true,
					mandatory: false,
				},
				{
					...subtitle,
					mandatory: false,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "UniqueCheck",
					key: "breadcrumbsDesktop",
					title: "Breadcrumbs Desktop/Tablet",
					options: [{ title: "Display in tablet and desktop" }],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbs",
					title: "Breadcrumbs Mobile",
					options: [{ title: "Display in mobile" }],
				},
				{
					type: "RadioGroup",
					key: "titleSize",
					title: "Title Size",
					mandatory: true,
					options: [
						{ name: "s", value: "s", title: "S" },
						{ name: "m", value: "m", title: "M" },
						{ name: "l", value: "l", title: "L" },
						{ name: "xl", value: "xl", title: "XL" },
					],
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "BasicHeroTV",
		title: {
			content: "Lorem ipsum",
			tag: "h1",
		},
		subtitle: null,
		anchorID: null,
		breadcrumbsDesktop: true,
		breadcrumbs: false,
		titleSize: "m",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicHeroTV/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicHeroTV/thumbnail@2x.png",
	},
};

export default schema;
