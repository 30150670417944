import { AdmissionFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	detail,
	themeSelector,
	getThemeOptions,
	loremIpsumParagraph,
} from "@schemas/presets";

const schema: Schema.Component<AdmissionFormProps> = {
	schemaType: "component",
	displayName: "Admission Form",
	component: "AdmissionForm",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, isMockup: true, mandatory: false },
				{ ...detail },
				{
					type: "TextField",
					key: "action",
					title: "Form action",
					mandatory: true,
				},
				{
					type: "UrlField",
					title: "Forget user/password link",
					key: "forgetLink",
					placeholder: "https://",
					advanced: true,
				},
				{
					type: "TextField",
					key: "label",
					title: "Button Label",
					placeholder: "Type a label",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
		{
			title: "Config",
			fields: [
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"accent-alt",
						"inverse",
					]),
				},
				{
					title: "Show decoration",
					key: "decoration",
					type: "RadioGroup",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "AdmissionForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		action:
			"https://web.upcomillas.es/portaldeadmisiones/acceso/missolicitudes.aspx",
		forgetLink: undefined,
		label: "Entrar",
		subtheme: "default",
		decoration: false,
	},
};

export default schema;
