import { Theme } from "@griddo/core";

const primitives: Array<Theme.Primitive> = [
	// -------------------------------------------------------------------------
	// MISC
	// -------------------------------------------------------------------------

	{
		id: "veilColors",
		name: "veilColors",
		values: [
			{ cssVar: "--overlay-0", value: "rgb(24, 24, 24)" },
			{ cssVar: "--overlay-1", value: "rgba(24, 24, 24, 0.2)" },
			{ cssVar: "--overlay-2", value: "rgba(24, 24, 24, 0.4)" },
			{ cssVar: "--overlay-3", value: "rgba(255, 246, 227, 0.2)" },
			{ cssVar: "--overlay-4", value: "rgba(255, 251, 241, 0.4)" },
		],
	},

	{
		id: "shadowColor",
		name: "shadowColor",
		values: [
			{ cssVar: "--shadow-1", value: "14, 47, 132" },
			{ cssVar: "--shadow-2", value: "1, 9, 30" },
		],
	},

	{
		id: "support",
		name: "support",
		values: [
			{ cssVar: "--success", value: "#426001" },
			{ cssVar: "--error", value: "#BE0044" },
			{ cssVar: "--focus-1", value: "#DB50FE" },
			{ cssVar: "--focus-2", value: "#DB50FE" },
		],
	},

	// -------------------------------------------------------------------------
	// TEXT AND ICONS
	// -------------------------------------------------------------------------

	{
		id: "text",
		name: "text",
		values: [
			{ cssVar: "--text-1", value: "#181818" },
			{ cssVar: "--text-2", value: "#707070" },
			{ cssVar: "--text-3", value: "#4D4D4D" },
			{ cssVar: "--text-4", value: "#FFB81C" },
			{ cssVar: "--text-5", value: "#7BA4DB" },
			{ cssVar: "--text-6", value: "#0E3E7A" },
			{ cssVar: "--text-7", value: "#A4123F" },
			{ cssVar: "--text-8", value: "#A4123F" },
			{ cssVar: "--text-inverse", value: "#ffffff" },
			{ cssVar: "--text-disabled", value: "#999999" },
		],
	},

	{
		id: "icon",
		name: "icon",
		values: [
			{ cssVar: "--icon-1", value: "#181818" },
			{ cssVar: "--icon-inverse", value: "#ffffff" },
			{ cssVar: "--icon-disabled", value: "#777777" },
		],
	},

	// -------------------------------------------------------------------------
	// BUTTONS
	// -------------------------------------------------------------------------

	{
		id: "backgroundButton",
		name: "backgroundButton",
		values: [
			// 1
			{ cssVar: "--bg-button-1", value: "#EFB546" },
			{ cssVar: "--bg-button-1-hover", value: "#EFB546" },
			{ cssVar: "--bg-button-1-active", value: "#FFC649" },
			{ cssVar: "--bg-button-1-focus", value: "#FFB81C" },
			{ cssVar: "--bg-button-1-disabled", value: "#CECECE" },
			// 2
			{ cssVar: "--bg-button-2", value: "#54565B" },
			{ cssVar: "--bg-button-2-hover", value: "#383838" },
			{ cssVar: "--bg-button-2-active", value: "#000000" },
			{ cssVar: "--bg-button-2-focus", value: "#181818" },
			{ cssVar: "--bg-button-2-disabled", value: "#CECECE" },
			// 3
			{ cssVar: "--bg-button-3", value: "transparent" },
			{ cssVar: "--bg-button-3-hover", value: "#ffffff" },
			{ cssVar: "--bg-button-3-active", value: "#F6F5EE" },
			{ cssVar: "--bg-button-3-focus", value: "#F6F5EE" },
			{ cssVar: "--bg-button-3-disabled", value: "#CECECE" },
			// 4
			{ cssVar: "--bg-link-button-4", value: "#EFB546" },
			{ cssVar: "--bg-link-button-4-hover", value: "#E0A21A" },
			{ cssVar: "--bg-link-button-4-active", value: "#FFC649" },
			{ cssVar: "--bg-link-button-4-focus", value: "#FFB81C" },
			{ cssVar: "--bg-link-button-4-disabled", value: "#CECECE" },
		],
	},

	{
		id: "borderButton",
		name: "borderButton",
		values: [
			// 1
			{ cssVar: "--border-button-1", value: "transparent" },
			{ cssVar: "--border-button-1-hover", value: "transparent" },
			{ cssVar: "--border-button-1-active", value: "transparent" },
			{ cssVar: "--border-button-1-focus", value: "#DB50FE" },
			{ cssVar: "--border-button-1-disabled", value: "#999999" },
			// 2
			{ cssVar: "--border-button-2", value: "transparent" },
			{ cssVar: "--border-button-2-hover", value: "transparent" },
			{ cssVar: "--border-button-2-active", value: "transparent" },
			{ cssVar: "--border-button-2-focus", value: "#DB50FE" },
			{ cssVar: "--border-button-2-disabled", value: "#999999" },
			// 3
			{ cssVar: "--border-button-3", value: "#54565B" },
			{ cssVar: "--border-button-3-hover", value: "#54565B" },
			{ cssVar: "--border-button-3-active", value: "#54565B" },
			{ cssVar: "--border-button-3-focus", value: "#DB50FE" },
			{ cssVar: "--border-button-3-disabled", value: "#999999" },
		],
	},

	{
		id: "textButton",
		name: "textButton",
		values: [
			// 1
			{ cssVar: "--text-button-1", value: "#181818" },
			{ cssVar: "--text-button-1-hover", value: "#181818" },
			{ cssVar: "--text-button-1-active", value: "#181818" },
			{ cssVar: "--text-button-1-focus", value: "#181818" },
			{ cssVar: "--text-button-1-disabled", value: "#999999" },
			// 2
			{ cssVar: "--text-button-2", value: "#ffffff" },
			{ cssVar: "--text-button-2-hover", value: "#ffffff" },
			{ cssVar: "--text-button-2-active", value: "#ffffff" },
			{ cssVar: "--text-button-2-focus", value: "#ffffff" },
			{ cssVar: "--text-button-2-disabled", value: "#999999" },
			// 3
			{ cssVar: "--text-button-3", value: "#54565B" },
			{ cssVar: "--text-button-3-hover", value: "#54565B" },
			{ cssVar: "--text-button-3-active", value: "#54565B" },
			{ cssVar: "--text-button-3-focus", value: "#54565B" },
			{ cssVar: "--text-button-3-disabled", value: "#999999" },
			// 4
			{ cssVar: "--text-link-button-4", value: "#181818" },
			{ cssVar: "--text-link-button-4-hover", value: "#181818" },
			{ cssVar: "--text-link-button-4-active", value: "#181818" },
			{ cssVar: "--text-link-button-4-focus", value: "#181818" },
			{
				cssVar: "--text-link-button-4-disabled",
				value: "#999999",
			},
		],
	},

	// -------------------------------------------------------------------------
	// LINKS
	// -------------------------------------------------------------------------

	{
		id: "textLink",
		name: "textLink",
		values: [
			// 1
			{ cssVar: "--text-link-1", value: "#273878" },
			{ cssVar: "--text-link-1-hover", value: "#192A6B" },
			{ cssVar: "--text-link-1-active", value: "#192A6B" },
			{ cssVar: "--text-link-1-focus", value: "#273878" },
			{ cssVar: "--text-link-1-disabled", value: "#999999" },
			// Menu
			{ cssVar: "--text-link-menu", value: "#181818" },
			{ cssVar: "--text-link-menu-hover", value: "#464646" },
			{ cssVar: "--text-link-menu-active", value: "#181818" },
			{ cssVar: "--text-link-menu-focus", value: "#181818" },
			{ cssVar: "--text-link-menu-disabled", value: "#999999" },
		],
	},

	// -------------------------------------------------------------------------
	// BORDERS
	// -------------------------------------------------------------------------

	{
		id: "border",
		name: "border",
		values: [
			{ cssVar: "--border-1", value: "#E2E2E2" },
			{ cssVar: "--border-2", value: "#181818" },
		],
	},

	// -------------------------------------------------------------------------
	// BACKGROUNDS
	// -------------------------------------------------------------------------

	{
		id: "background",
		name: "background",
		values: [
			{ cssVar: "--bg-ui", value: "#FFFFFF" },
			{ cssVar: "--bg-1", value: "#FFFFFF" },
			{ cssVar: "--bg-1-card-hover", value: "#F2F2F2" },
			{ cssVar: "--bg-2", value: "#F1F1F1" },
			{ cssVar: "--bg-3", value: "#F2F2F2" },
			{ cssVar: "--bg-4", value: "#54565B" },
			{ cssVar: "--bg-disabled", value: "#E0E0E0" },
		],
	},
];

export default primitives;
