import { PROGRAM_EXTERNALContentTypeProps } from "@autoTypes";
import { Schema } from "@griddo/core";

export const PROGRAM_EXTERNAL: Schema.SimpleContentType<PROGRAM_EXTERNALContentTypeProps> =
	{
		dataPacks: ["PROGRAMS"],
		title: "Program (External)",
		local: false,
		translate: true,
		clone: null,
		fromPage: false,
		schema: {
			fields: [
				{
					key: "title",
					title: "Title",
					type: "TextField",
					placeholder: "Type a title",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					type: "TextField",
					title: "Id API (titIdWeb)",
					key: "idAPI",
					readonly: true,
				},
				{
					type: "Wysiwyg",
					full: false,
					title: "Abstract",
					key: "abstract",
					placeholder: "Type an abstract text",
					humanReadable: true,
				},
				{
					key: "image",
					title: "Image",
					type: "ImageField",
					helptext: "Recommended use a vector or minimum image size: 256x256",
				},
				{
					key: "divider",
					title: "",
					type: "FieldsDivider",
					data: {
						title: "Select Categories",
						text: "",
					},
				},
				{
					key: "centers",
					title: "Centers",
					placeholder: "Centers",
					type: "MultiCheckSelect",
					source: "CENTER",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					key: "schools",
					title: "Schools",
					placeholder: "Schools",
					type: "MultiCheckSelect",
					source: "SCHOOL",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					key: "programTypes",
					title: "Program type",
					placeholder: "Program type",
					type: "MultiCheckSelect",
					source: "PROGRAM_TYPE",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					key: "academicAreas",
					title: "Academic area",
					placeholder: "Academic area",
					type: "MultiCheckSelect",
					source: "ACADEMIC_AREA",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					key: "certifications",
					title: "Certifications",
					placeholder: "Certifications",
					type: "MultiCheckSelect",
					source: "CERTIFICATION",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					key: "languages",
					title: "Languages",
					placeholder: "Languages",
					type: "MultiCheckSelect",
					source: "LANGUAGE",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					key: "modalities",
					title: "Modalities",
					placeholder: "Modalities",
					type: "MultiCheckSelect",
					source: "MODALITY",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					key: "formats",
					title: "Formats",
					placeholder: "Formats",
					type: "MultiCheckSelect",
					source: "FORMAT",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					key: "registrations",
					title: "Registration",
					placeholder: "Registration",
					type: "MultiCheckSelect",
					source: "REGISTRATION",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					key: "campus",
					title: "Campus",
					placeholder: "Campus",
					type: "MultiCheckSelect",
					source: "CAMPUS",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					key: "divider",
					title: "",
					type: "FieldsDivider",
					data: {
						title: "Quick facts",
						text: "",
					},
				},
				{
					type: "DateField",
					title: "Starts On",
					key: "intake",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					type: "TextField",
					title: "Duration",
					key: "duration",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					type: "TextField",
					title: "Seats",
					key: "seats",
					helptext:
						"This value will be updated automatically if Id API field is filled",
				},
				{
					type: "UrlField",
					title: "URL",
					key: "externalUrl",
					advanced: true,
				},
				{
					type: "TextField",
					key: "centerOrder",
					title: "Center order",
					computed: (simpleData) => {
						return simpleData?.centerOrder || "";
					},
					indexable: true,
				},
				{
					type: "TextField",
					key: "certificationOrder",
					title: "Certification order",
					computed: (simpleData) => {
						return simpleData?.certificationOrder || "";
					},
					indexable: true,
				},
			],
		},
	};
