/* eslint-disable import/order */

// ui/objects schemas
import templates from "./src/schemas/templates";
import objects from "./src/schemas/objects";
import components from "./src/schemas/components";
import modules from "./src/schemas/modules";

// config schemas
import {
	languages,
	menuItems,
	menus,
	moduleCategories,
	themes,
} from "./src/schemas/config";

// contentTypes schemas
import {
	categories,
	pageContentType,
	simpleContentType,
} from "./src/schemas/content-types";

// datapacks schemas
import { dataPacksCategories, dataPacks } from "./src/schemas/data-packs";

// bundle for API
const schemas = {
	all: { ...objects, ...components, ...modules },
	modules,
	templates,
	dataPacks,
	dataPacksCategories,
	structuredData: {
		...simpleContentType,
		...pageContentType,
		...categories,
	},
	languages,
	menuItems,
	menus,
};

export { schemas, themes, moduleCategories };
