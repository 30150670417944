import { FlickrProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<FlickrProps> = {
	schemaType: "component",
	component: "Flickr",
	displayName: "Flickr",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Flickr",
					key: "value",
					type: "TextField",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "Flickr",
		value: "",
		icon: "flickr",
	},
};

export default schema;
