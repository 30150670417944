import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

export const LECTURER: Schema.SimpleContentType = {
	dataPacks: ["LECTURERS"],
	title: "Lecturer (Auto)",
	local: false,
	translate: false,
	clone: null,
	fromPage: false,
	editable: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Label",
				placeholder: "Type a label",
				type: "TextField",
				helptext: "Used in the editor.",
			},
			{
				key: "image",
				title: "Image",
				type: "ImageField",
			},
			{
				key: "pdiId",
				title: "pdiId",
				type: "TextField",
				helptext: "Lecturer ID inside CRM System",
			},
			{
				...heading,
				key: "name",
				title: "Name",
				isMockup: true,
				default: { tag: "h4", content: "‘Content type name’ title" },
			},
			{
				key: "lecturerUrl",
				title: "URL",
				type: "TextField",
			},
			{
				key: "linkedin",
				title: "LinkedIn",
				type: "TextField",
			},
			{
				key: "divider",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Select Categories",
					text: "",
				},
			},
			{
				key: "programs",
				title: "Programs",
				placeholder: "Programs",
				type: "MultiCheckSelect",
				source: "PROGRAM",
			},
			{
				key: "centers",
				title: "Centers",
				placeholder: "Centers",
				type: "MultiCheckSelect",
				source: "CENTER",
			},
			{
				key: "schools",
				title: "Schools",
				placeholder: "Schools",
				type: "MultiCheckSelect",
				source: "SCHOOL",
			},
			{
				key: "researchGroup",
				title: "Research group",
				placeholder: "Research group",
				type: "MultiCheckSelect",
				source: "RESEARCH_GROUP",
			},
		],
	},
};
