import { ChartCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import Chart from "@schemas/components/Chart";
import {
	additional,
	anchorID,
	content,
	getThemeOptions,
	heading,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ChartCollectionProps> = {
	schemaType: "module",
	displayName: "Chart Collection",
	component: "ChartCollection",
	category: "collection",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false },
				{ ...subtitle },
				{ ...content },
				{
					title: "Elements",
					key: "elements",
					type: "ComponentArray",
					contentType: "components",
					whiteList: ["Chart", "GenderChart"],
					mandatory: true,
					maxItems: 3,
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
			],
		},
	],

	default: {
		component: "ChartCollection",
		title: { content: "Title", tag: "h2" },
		elements: [{ ...Chart.default }],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ChartCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ChartCollection/thumbnail@2x.png",
	},
};

export default schema;
