import { VideoGalleryProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<VideoGalleryProps> = {
	schemaType: "module",
	component: "VideoGallery",
	displayName: "Video Gallery",
	category: "collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false },
				{ ...subtitle },
				{ ...content },
				{
					title: "Videos",
					type: "ConditionalField",
					key: "type",
					mandatory: true,
					hideable: false,
					options: [
						{
							name: "manual",
							value: "manual",
							title: "Manual",
						},
						{
							name: "playlist",
							value: "playlist",
							title: "Playlist URL",
						},
					],
					fields: [
						{
							condition: "manual",
							title: "Elements",
							type: "ComponentArray",
							key: "elements",
							whiteList: ["VideoGalleryCard"],
							contentType: "components",
							maxItems: 15,
						},
						{
							condition: "playlist",
							type: "TextField",
							key: "playlist",
							title: "Playlist URL",
							helptext: "YouTube playlist URL",
						},
						{
							condition: "playlist",
							type: "UniqueCheck",
							key: "automaticSubtitles",
							title: "Subtitles",
							options: [{ title: "Add automatic subtitles" }],
						},
					],
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "VideoGallery",
		additional: "Lorem ipsum",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		type: "playlist",
		elements: [
			{ component: "VideoGalleryCard" },
			{ component: "VideoGalleryCard" },
			{ component: "VideoGalleryCard" },
		],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/VideoGallery/thumbnail@1x.png",
		"2x": "/thumbnails/modules/VideoGallery/thumbnail@2x.png",
	},
};

export default schema;
