import { Schema } from "@griddo/core";

export const EVENT_AUTO: Schema.SimpleContentType = {
	dataPacks: ["EVENTS"],
	title: "Events (Auto)",
	local: true,
	translate: true,
	clone: null,
	fromPage: false,
	editable: false,
	schema: {
		fields: [
			{
				key: "title", // title from api.symposium.events
				title: "Title",
				type: "TextField",
			},
			{
				key: "symposiumId", // id from api.symposium.events
				title: "SymposiumId",
				type: "TextField",
			},
			{
				type: "DateField", // date_ini from api.symposium.events
				title: "Date",
				key: "eventDate",
				indexable: true,
			},
			{
				type: "TimeField", // date_ini from api.symposium.events
				title: "Time",
				key: "eventTime",
			},
			{
				key: "abstract", // summary from api.symposium.events
				title: "Abstract",
				type: "Wysiwyg",
				full: false,
			},
			{
				key: "urlSymposium", // url from api.symposium.events
				title: "URL",
				type: "TextField",
			},
			{
				key: "centers", // campus_name / organizer_name from api.symposium.events ??
				title: "Center",
				type: "TextField",
			},
			{
				key: "locations", // place from api.symposium.events
				title: "Location",
				type: "TextField",
			},
			{
				key: "type", // tags or categories from api.symposium.events
				title: "Type",
				placeholder: "Type",
				type: "MultiCheckSelect",
				source: "EVENT_TYPE",
			},
			{
				key: "category", // type_name from api.symposium.events
				title: "Category",
				placeholder: "Category",
				type: "MultiCheckSelect",
				source: "EVENT_CATEGORY",
			},
		],
	},
};
