import { Schema } from "@griddo/core";

export const CATEGORY_VIDEO: Schema.SimpleContentType = {
	dataPacks: ["VIDEOS"],
	title: "Category (video)",
	local: true,
	translate: false,
	clone: null,
	defaultValues: null,
	fromPage: false,
	editable: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
			},
			{
				key: "categoryTitle",
				title: "Category title",
				type: "TextField",
			},
			{
				key: "fullName",
				title: "Full name",
				type: "TextField",
			},
			{
				title: "List page",
				type: "UrlField",
				key: "listPage",
			},
			{
				key: "description",
				title: "Description",
				type: "Wysiwyg",
			},
			{
				key: "idAPI",
				title: "API ID",
				type: "TextField",
			},
			{
				key: "tags",
				title: "Tags",
				type: "TextField",
			},
			{
				key: "categoryDate",
				title: "Date",
				type: "DateField",
				indexable: true,
			},
			{
				key: "categoryUpdateDate",
				title: "Date (update)",
				type: "DateField",
				indexable: true,
			},
			{
				key: "mediaFiles",
				title: "Media files",
				type: "NumberField",
				indexable: true,
			},
			{
				key: "membersCount",
				title: "Members count",
				type: "NumberField",
				indexable: true,
			},
			{
				key: "divider",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Select Categories",
					text: "",
				},
			},
			{
				key: "parentCategory",
				title: "Parent category",
				placeholder: "Parent category",
				type: "MultiCheckSelect",
				source: "CATEGORY_VIDEO",
			},
		],
	},
};
