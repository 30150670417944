import { BoxedCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	content,
	defaultLink,
	heading,
	loremIpsumParagraph,
	subtitle,
} from "@schemas/presets";

const schema: Schema.Component<BoxedCardProps> = {
	schemaType: "component",
	component: "BoxedCard",
	displayName: "Boxed Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Media",
					key: "media",
					whiteList: ["Image", "Video"],
					helptext: "Recommended minimum image size: 467x350",
					hideable: true,
				},
				{
					type: "TextField",
					key: "tag",
					title: "Tag",
					placeholder: "Type a tag",
					isMockup: true,
					hideable: true,
					humanReadable: true,
				},
				{
					...heading,
					mandatory: false,
				},
				{
					...subtitle,
				},

				{
					...content,
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Link"],
					hideable: true,
				},
			],
		},
	],
	default: {
		component: "BoxedCard",
		media: {
			image: { component: "Image" },
			video: { component: "Video" },
		},
		tag: null,
		title: { content: "Lorem ipsum", tag: "h4" },
		subtitle: null,
		content: loremIpsumParagraph,
		link: {
			...defaultLink,
			variant: "button4",
			text: "Leer más",
		},
	},
};

export default schema;
