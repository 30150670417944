import { BasicTvTemplateProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import FeaturedAudiovisual from "@schemas/modules/FeaturedAudiovisual";
import FullVideo from "@schemas/modules/FullVideo";
import VideoDistributor from "@schemas/modules/VideoDistributor";

const schema: Schema.Template<BasicTvTemplateProps> = {
	schemaType: "template",
	displayName: "Basic TV Template",
	component: "BasicTvTemplate",
	dataPacks: ["VIDEOS"],
	type: { label: "Videos", value: "videos" },

	dimensions: [],

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["BasicHeroTV", "MainHeroTv"],
			contentType: "modules",
			key: "heroSection",
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			whiteList: [
				"BasicContent",
				"BasicText",
				"CardCollection",
				"FAQCollection",
				"FeaturedAudiovisual",
				"FullVideo",
				"ImageBanner",
				"LogoCollection",
				"Quote",
				"StickyTabs",
				"VideoPlaylist",
				"Wysiwyg",
			],
			contentType: "modules",
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "BasicTvTemplate",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{ ...VideoDistributor.default },
				{ ...VideoDistributor.default },
				{ ...VideoDistributor.default },
				{ ...FeaturedAudiovisual.default, subtheme: "inverse" },
				{ ...VideoDistributor.default },
				{ ...VideoDistributor.default },
				{ ...FullVideo.default },
			],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/BasicTvTemplate/thumbnail@1x.png",
		"2x": "/thumbnails/templates/BasicTvTemplate/thumbnail@2x.png",
	},
};

export default schema;
