import { HeroInnerProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	anchorID,
	loremIpsumParagraph,
	subtitle,
	verticalSpacing,
	animation,
} from "@schemas/presets";

const schema: Schema.Module<HeroInnerProps> = {
	schemaType: "module",
	component: "HeroInner",
	category: "heros",
	displayName: "Basic Hero",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					isMockup: true,
					mandatory: false,
				},
				{
					...subtitle,
					mandatory: false,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "UniqueCheck",
					key: "breadcrumbsDesktop",
					title: "Breadcrumbs Desktop/Tablet",
					options: [{ title: "Display in tablet and desktop" }],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbs",
					title: "Breadcrumbs Mobile",
					options: [{ title: "Display in mobile" }],
				},
				{
					type: "RadioGroup",
					key: "titleSize",
					title: "Title Size",
					mandatory: true,
					options: [
						{ name: "s", value: "s", title: "S" },
						{ name: "m", value: "m", title: "M" },
						{ name: "l", value: "l", title: "L" },
						{ name: "xl", value: "xl", title: "XL" },
					],
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "HeroInner",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		anchorID: null,
		breadcrumbsDesktop: true,
		breadcrumbs: false,
		titleSize: "l",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeroInner/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeroInner/thumbnail@2x.png",
	},
};

export default schema;
