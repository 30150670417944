import { AccordionCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import Accordion from "@schemas/components/Accordion";
import {
	anchorID,
	animation,
	content,
	getThemeOptions,
	heading,
	link,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<AccordionCollectionProps> = {
	schemaType: "module",
	component: "AccordionCollection",
	displayName: "Accordion Collection",
	category: "collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					mandatory: false,
					isMockup: true,
				},
				{ ...subtitle },
				{ ...content },
				{
					type: "ComponentArray",
					title: "Elements",
					key: "elements",
					whiteList: ["Accordion"],
					contentType: "components",
					mandatory: true,
				},
				{
					type: "ComponentContainer",
					title: "Visual Content",
					key: "visualContent",
					whiteList: ["ImageExp", "Video", "Map"],
					hideable: true,
				},
				{
					type: "RadioGroup",
					title: "Visual Content position",
					key: "position",
					options: [
						{ name: "left", value: "left", title: "Left" },
						{ name: "right", value: "right", title: "Right" },
					],
					helptext: "Applies to desktop and big screen resolutions",
					hideable: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"inverse-alt",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "AccordionCollection",
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: loremIpsumParagraph,
		content: null,
		elements: [{ ...Accordion.default }, { ...Accordion.default }],
		visualContent: {
			imageExp: { component: "ImageExp" },
			video: { component: "Video" },
			map: { component: "Map" },
		},
		position: "left",
		link: { component: "Link" },
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/AccordionCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/AccordionCollection/thumbnail@2x.png",
	},
};

export default schema;
