import { Schema } from "@griddo/core";

export const VIDEO_CREATION: Schema.CategoryContentType = {
	dataPacks: ["VIDEOS"],
	title: "Creation date (Kaltura)",
	local: true,
	translate: false,
	taxonomy: true,
	fromPage: false,
	editable: false,
};
