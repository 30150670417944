import { VideoPlaylistCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<VideoPlaylistCardProps> = {
	schemaType: "component",
	component: "VideoPlaylistCard",
	displayName: "VideoPlaylist Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Source",
					type: "ConditionalField",
					key: "source",
					mandatory: true,
					options: [
						{
							name: "url",
							value: "url",
							title: "Video (from URL)",
						},

						{
							name: "kaltura",
							value: "kaltura",
							title: "Video (Kaltura)",
						},
					],
					fields: [
						{
							condition: "kaltura",
							title: "",
							type: "ReferenceField",
							sources: [{ structuredData: "VIDEO" }],
							key: "data",
							selectionType: ["manual"],
							maxItems: 1,
							mandatory: true,
						},
						{
							condition: "url",
							type: "TextField",
							key: "url",
							title: "URL video",
							mandatory: true,
							hideable: false,
						},
						{
							condition: "url",
							type: "TextField",
							key: "title",
							title: "Title",
							helptext: "Title of the video",
							mandatory: false,
							hideable: false,
							isMockup: true,
							humanReadable: true,
						},
						{
							condition: "url",
							type: "MultiCheckSelect",
							title: "Topics",
							placeholder: "Topics",
							key: "categories",
							source: "TOPIC",
							mandatory: false,
							hideable: true,
						},
						{
							condition: "url",
							type: "DateField",
							key: "date",
							title: "Date",
							mandatory: false,
							hideable: true,
						},
						{
							condition: "url",
							title: "Thumbnail",
							type: "ImageField",
							key: "thumbnail",
							helptext:
								"Thumbnail of the video, Recommended minimum image size: 941x529",
							mandatory: false,
							hideable: true,
						},
					],
				},
				{
					type: "UniqueCheck",
					key: "automaticSubtitles",
					title: "Subtitles",
					options: [{ title: "Add automatic subtitles" }],
				},
				{
					title: "Veil percentaje",
					type: "SliderField",
					key: "veil",
					step: 10,
					min: 0,
					max: 100,
				},
			],
		},
	],

	default: {
		component: "VideoPlaylistCard",
		source: "url",
		url: "",
		title: "",
		category: null,
		date: "",
		thumbnail: null,
		automaticSubtitles: true,
		veil: 50,
		data: {
			mode: "manual",
			fixed: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/components/Video/thumbnail@1x.png",
		"2x": "/thumbnails/components/Video/thumbnail@2x.png",
	},
};

export default schema;
