import { Schema } from "@griddo/core";

export const VIDEO_UPDATE: Schema.CategoryContentType = {
	dataPacks: ["VIDEOS"],
	title: "Update date (Kaltura)",
	local: true,
	translate: false,
	taxonomy: true,
	fromPage: false,
	editable: false,
};
