import { Schema } from "@griddo/core";

export const RESEARCH_GROUP: Schema.SimpleContentType = {
	dataPacks: ["PUBLICATIONS", "LECTURERS"],
	title: "Research group (Auto)",
	local: false,
	translate: false,
	editable: false,
	clone: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Name",
				placeholder: "Type a name",
				type: "TextField",
			},
			{
				key: "id",
				title: "ID",
				placeholder: "Type an ID",
				type: "TextField",
			},
		],
	},
};
