import { Schema } from "@griddo/core";

export const VIDEO_CAPTION: Schema.CategoryContentType = {
	dataPacks: ["VIDEOS"],
	title: "Caption (Kaltura)",
	local: true,
	translate: false,
	taxonomy: true,
	fromPage: false,
	editable: false,
};
