import { Schema } from "@griddo/core";

export const ENTRY_DATES: Schema.SimpleContentType = {
	dataPacks: ["PROGRAMS"],
	title: "Entry dates",
	local: false,
	translate: false,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				type: "TextField",
				placeholder: "Type a title",
				mandatory: true,
			},
			{
				type: "TextField",
				key: "value",
				title: "Value (CRM)",
				mandatory: true,
			},
			{
				key: "default",
				type: "UniqueCheck",
				title: "",
				options: [
					{
						title: "Default option",
					},
				],
			},
		],
	},
};
