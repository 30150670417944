import { LandingFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	detail,
	themeSelector,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Component<LandingFormProps> = {
	schemaType: "component",
	displayName: "Landing Form",
	component: "LandingForm",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "NoteField",
					key: "note",
					title: "",
					helptext: "",
					value: {
						title: "Important",
						text: "This form must be used in Program Landing or Landing templates.",
					},
				},
				{ ...heading, mandatory: false },
				{ ...detail, isMockup: false },
				{
					type: "CheckGroup",
					title: "Select extra fields to display",
					key: "fields",
					options: [
						{
							title: "Academic year",
							name: "academicYear",
						},
					],
				},
				{
					type: "TextField",
					key: "legalConditionPreffix",
					title: "Legal condition text preffix",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "TextField",
					key: "legalConditionSuffix",
					title: "Legal condition link text",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "UrlField",
					title: "Legal condition link",
					key: "legalConditionLink",
					placeholder: "https://",
					advanced: true,
				},
				{
					type: "TextField",
					key: "label",
					title: "Button Label",
					placeholder: "Type a label",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
		{
			title: "Config",
			fields: [
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
			],
		},
	],

	default: {
		component: "LandingForm",
		title: { content: "¿Quieres saber más?", tag: "h3" },
		detail: "¡Déjanos tus datos para que podamos contactarte!",
		label: "Solicitar información",
		legalConditionPreffix: "Acepto la",
		legalConditionSuffix: "Política de Privacidad",
		legalConditionLink: undefined,
		subtheme: "default",
	},
};

export default schema;
