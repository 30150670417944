import { ImageStepCollectionCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	link,
	heading,
	content,
	defaultLink,
	themeSelector,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Component<ImageStepCollectionCardProps> = {
	schemaType: "component",
	displayName: "Image Step Collection Card",
	component: "ImageStepCollectionCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: true,
					helptext: "Recommended use a vector or minimum image size: 120x120",
				},
				{
					title: "Step number",
					type: "TextField",
					key: "stepNumber",
					mandatory: true,
				},
				{
					...heading,
					hideable: false,
				},
				{
					...content,
					// validators: { maxChar: 300 }, // TODO add when finished https://app.shortcut.com/secuoyas/story/38413/error-en-validaci%C3%B3n-de-caracteres-m%C3%A1ximos
				},
				{ ...link, hideable: true },
			],
		},
		{
			title: "Config",
			fields: [
				{
					...themeSelector,
					options: getThemeOptions(["inverse", "accent"]),
				},
			],
		},
	],

	default: {
		component: "ImageStepCollectionCard",
		image: undefined,
		stepNumber: "01",
		title: { content: "Lorem ipsum", tag: "h4" },
		content: null,
		subtheme: "inverse",
		link: {
			...defaultLink,
			text: "Ver más",
			variant: "button4",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/components/ImageStepCollectionCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/ImageStepCollectionCard/thumbnail@2x.png",
	},
};

export default schema;
