import { ProjectDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Template<ProjectDetailProps> = {
	schemaType: "template",
	component: "ProjectDetail",
	displayName: "Project",
	dataPacks: ["PROJECTS"],

	type: {
		label: "Project",
		value: "project",
		mode: "detail",
	},

	content: [
		{
			...heading,
			title: "Hero title",
			key: "heroTitle",
			default: { content: "Lorem ipsum", tag: "h1" },
		},
		{
			type: "ImageField",
			title: "Image",
			key: "image",
			hideable: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: [
				"BasicContent",
				"BasicText",
				"ContactInfo",
				"ImageBanner",
				"IntroForm",
				"Quote",
				"Table",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			key: "relatedContent",
		},

		{
			type: "FieldGroup",
			key: "apiData",
			title: "API Data",

			fields: [
				{
					type: "TextField",
					title: "Id API (identificador)",
					key: "idAPI",
					readonly: true,
				},
				{
					type: "TextField",
					title: "Id Project (idProyecto)",
					key: "idProject",
					readonly: true,
				},
				{
					type: "TextField",
					title: "Grant",
					key: "grant",
					readonly: true,
				},
				{
					type: "DateField",
					title: "Start",
					key: "startDate",
					hideable: true,
					disabled: true,
				},
				{
					type: "DateField",
					title: "End",
					key: "endDate",
					hideable: true,
					disabled: true,
				},
				{
					type: "DateField",
					title: "Extension date",
					key: "extensionDate",
					hideable: true,
					disabled: true,
				},
				{
					type: "Wysiwyg",
					title: "Summary",
					key: "summary",
					full: false,
					hideable: true,
					disabled: true,
				},
				{
					type: "TextArea",
					title: "Members (list)",
					key: "membersList",
					hideable: true,
					disabled: true,
				},
				{
					type: "ArrayFieldGroup",
					title: "Members",
					key: "members",
					name: "Option",
					arrayType: "inline",
					fields: [
						{
							type: "TextField",
							key: "name",
							title: "Name",
							readonly: true,
						},
					],
					hideable: true,
					disabled: true,
				},
				{
					type: "TextArea",
					title: "Calls for proposal (list)",
					key: "callsList",
					hideable: true,
					disabled: true,
				},
				{
					type: "ArrayFieldGroup",
					title: "Calls for proposal",
					key: "calls",
					name: "Option",
					arrayType: "inline",
					fields: [
						{
							type: "TextField",
							key: "name",
							title: "Name",
							readonly: true,
						},
						{
							type: "TextField",
							key: "link",
							title: "Link",
							readonly: true,
						},
					],
					hideable: true,
					disabled: true,
				},
				{
					title: "Categories",
					type: "MultiCheckSelectGroup",
					key: "categories",
					filled: true,
					disabled: true,
					elements: [
						{
							placeholder: "Keywords",
							key: "keywords",
							source: "PROJECT_KEYWORD",
						},
						{
							placeholder: "Funding organization",
							key: "fundingOrganization",
							source: "FUNDING_ORGANIZATION",
						},
						{
							placeholder: "Partner organization",
							key: "partnerOrganization",
							source: "PARTNER_ORGANIZATION",
						},
					],
				},
			],
		},
	],

	config: [
		{
			type: "UniqueCheck",
			key: "breadcrumbsDesktop",
			title: "Breadcrumbs Desktop/Tablet",
			options: [{ title: "Display in tablet and desktop" }],
		},
		{
			type: "UniqueCheck",
			key: "breadcrumbs",
			title: "Breadcrumbs Mobile",
			options: [{ title: "Display in mobile" }],
		},
	],

	default: {
		type: "template",
		templateType: "ProjectDetail",
		heroTitle: { content: "", tag: "h1" },
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
		breadcrumbsDesktop: true,
		breadcrumbs: false,
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProjectDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProjectDetail/thumbnail@2x.png",
	},
};

export default schema;
