import { Schema } from "@griddo/core";
import {
	getLinkModal,
	getThemeOptions,
	linkModal,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.Footer = {
	schemaType: "module",
	displayName: "Footer",
	component: "Footer",
	type: "footer",
	defaultNavigation: true,

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Name",
					type: "TextField",
					key: "title",
					mandatory: true,
				},
				{
					title: "Footer description",
					type: "Wysiwyg",
					full: false,
					key: "description",
					placeholder: "Type a detail",
					helptext: "Describe in a few words the concept of company",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					title: "Show SEO Menu",
					type: "RadioGroup",
					key: "showSeoMenu",
					hideable: true,
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
				{ ...linkModal, title: "Link (botón contacto)", hideable: true },
				{
					title: "Show Social Media",
					type: "RadioGroup",
					key: "showSocialMedia",
					mandatory: true,
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
				{
					title: "Copyright",
					type: "TextField",
					key: "copyright",
					placeholder: "Type a copyright",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					title: "Set as default",
					key: "setAsDefault",
					options: [
						{
							title: "Set as Footer default",
						},
					],
				},
				{
					title: "Select Menu SEO",
					key: "seoMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					helptext: "Select the site menu. It must first be created.",
				},
				{
					title: "Legal pages",
					key: "legalPages",
					type: "AsyncSelect",
					entity: "menu_containers",
					helptext: "Select the site menu. It must first be created.",
					mandatory: true,
				},
				{ ...themeSelector, options: getThemeOptions(["default", "inverse"]) },
			],
		},
	],

	default: {
		component: "Footer",
		type: "footer",
		setAsDefault: false,
		seoMenu: undefined,
		legalPages: undefined,
		subtheme: "inverse",
		title: "Footer name",
		description: loremIpsumParagraph,
		showSeoMenu: true,
		link: getLinkModal("¿Te llamamos?"),
		showSocialMedia: true,
		copyright: "© 2023 Universidad Pontificia Comillas",
	},

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/modules/Footer/onexed@1x.png",
			"2x": "/thumbnails/modules/Footer/onexed@2x.png",
		},
		"1x": "/thumbnails/modules/Footer/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Footer/thumbnail@2x.png",
	},
};

export default schema;
