import { Schema } from "@griddo/core";

export const SERVICE: Schema.CategoryContentType = {
	dataPacks: ["NEWS", "EVENTS"],
	title: "Service",
	local: true,
	translate: true,
	taxonomy: true,
	fromPage: false,
};
