import { FAQProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Component<FAQProps> = {
	schemaType: "component",
	component: "FAQ",
	displayName: "FAQ Component",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Question",
					type: "TextArea",
					key: "title",
					placeholder: "Type a question",
					mandatory: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					title: "Answer",
					type: "Wysiwyg",
					key: "answer",
					placeholder: "Type an answer",
					helptext: "This text will also be displayed as structured data",
					mandatory: true,
					isMockup: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "FAQ",
		title: "Lorem Ipsum",
		answer: loremIpsumParagraph,
	},
};

export default schema;
