import { ImageProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<ImageProps> = {
	schemaType: "component",
	component: "Image",
	displayName: "Image",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "imageField",
				},
				{
					title: "Image crop",
					type: "ConditionalField",
					key: "crop",
					helptext: "",
					options: [
						{
							name: "cover",
							value: "cover",
							title: "Cover",
						},
						{
							name: "inside",
							value: "inside",
							title: "Inside",
						},
						{
							name: "fill",
							value: "fill",
							title: "Fill",
						},
					],

					fields: [
						{
							condition: "inside",
							title: "Horizontal Alignment",
							type: "SliderField",
							key: "insidePositionX",
							helptext: "How the image is horizontally aligned.",
							min: 0,
							max: 100,
						},
						{
							condition: "inside",
							title: "Vertical Alignment",
							type: "SliderField",
							key: "insidePositionY",
							helptext: "How the image is vertically aligned.",
							min: 0,
							max: 100,
						},
						{
							condition: "cover",
							title: "Horizontal Alignment",
							type: "SliderField",
							key: "coverPositionX",
							helptext: "How the image is horizontally aligned.",
							min: 0,
							max: 100,
						},
						{
							condition: "cover",
							title: "Vertical Alignment",
							type: "SliderField",
							key: "coverPositionY",
							helptext: "How the image is vertically aligned.",
							min: 0,
							max: 100,
						},
					],
				},
			],
		},

		{
			title: "Config",
			fields: [
				{
					title: "Use veil",
					type: "ConditionalField",
					key: "useVeil",
					helptext: "",
					options: [
						{
							name: "yes",
							value: true,
							title: "Yes",
						},
						{
							name: "no",
							value: false,
							title: "No",
						},
					],

					fields: [
						{
							condition: true,
							title: "Veil percentage",
							type: "SliderField",
							key: "veil",
							step: 10,
							min: 0,
							max: 100,
						},
					],
				},
				{
					title: "Quality",
					type: "SliderField",
					key: "quality",
					step: 10,
					min: 0,
					max: 100,
					helptext:
						"Set the image quality. The higher the quality, the bigger the file.",
				},
				{
					title: "Loading",
					key: "loading",
					type: "RadioGroup",
					helptext:
						"Use this option to control the image loading behavior by the browser. It is recommended to use 'eager' value in hero modules.",
					options: [
						{ name: "lazy", value: "lazy", title: "Lazy" },
						{ name: "eager", value: "eager", title: "Eager" },
					],
				},
				{
					title: "Decoding",
					key: "decoding",
					type: "RadioGroup",
					helptext:
						"Represents a hint given to the browser on how it should decode the image.",
					options: [
						{ name: "auto", value: "auto", title: "Auto" },
						{ name: "async", value: "async", title: "async" },
						{ name: "sync", value: "sync", title: "sync" },
					],
				},
				{
					title: "Fetch priority",
					key: "fetchpriority",
					type: "RadioGroup",
					helptext:
						"Represents a hint given to the browser on how it should prioritize the fetch of the image relative to other images. It is recommended to use 'high' value in hero and top modules.",
					options: [
						{ name: "auto", value: "auto", title: "Auto" },
						{ name: "low", value: "low", title: "low" },
						{ name: "high", value: "high", title: "high" },
					],
				},
				{
					title: "Open image in ligthbox",
					key: "openLightbox",
					type: "RadioGroup",
					options: [
						{ name: "yes", value: "yes", title: "Yes" },
						{ name: "no", value: "no", title: "No" },
					],
				},
			],
		},
	],

	default: {
		component: "Image",
		imageField: undefined,
		useVeil: false,
		veil: 20,
		quality: 70,
		loading: "lazy",
		decoding: "async",
		fetchpriority: "auto",
		openLightbox: "no",
		crop: "cover",
		insidePositionX: 50,
		insidePositionY: 50,
		coverPositionX: 50,
		coverPositionY: 50,
	},

	thumbnails: {
		"1x": "/thumbnails/components/Image/thumbnail@1x.png",
		"2x": "/thumbnails/components/Image/thumbnail@2x.png",
	},
};

export default schema;
