import { YoutubeProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<YoutubeProps> = {
	schemaType: "component",
	component: "Youtube",
	displayName: "Youtube",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Youtube",
					key: "value",
					type: "TextField",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "Youtube",
		value: "",
		icon: "youtube",
	},
};

export default schema;
